import React, { useState, useEffect, Component } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import { useTheme } from '@mui/material/styles';
import { Card } from '@mui/material';
import Chip from '@mui/material/Chip';

import { makeStyles } from "@material-ui/core/styles";
import { Input, OutlinedInput } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { endpoints } from "../../services/apiurl";
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

var names = [];

const TagDish = () => {
    let history = useHistory();
    let { id } = useParams();
    let aa = [1, 2];
    const classes = useStyles();
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        setPersonName(
            typeof value === "string" ? value.split(",") : value
        );

    };

    useEffect(() => {
        getDopdownTags();
        fetchData();
    }, []);

    const fetchData = async () => {
        await axios.post(endpoints.getTagsByDishID, { dish_id: id }).then((response) => {
            setPersonName(response.data.data);
        });
    }
    const getDopdownTags = async () => {
        await axios.post(endpoints.getDopdownTags).then((response) => {
            names = response.data.data;
            // setPersonName(response.data.data);
        });
    }



    const onSubmit = async e => {
        e.preventDefault();
        if (personName.length > 0) {
            const formData = new FormData();
            formData.append("tag_id", JSON.stringify(personName));
            formData.append("dish_id", id);
            await axios.post(endpoints.updateTagBYDishID, formData).then((response) => {
                // history.goBack();
            });
        }
        console.log('personName', personName);

    }

    return (
        <div>
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">

                    <FormControl className={classes.formControl}>
                        {/* <InputLabel htmlFor="age-native-simple">
                            Select Tags
                        </InputLabel> */}
                        <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            value={personName}
                            name="first"
                            onChange={handleChange}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.map(obj => names[obj - 1].value).join(", ")}
                        >
                            {names.map((name) => (
                                <MenuItem key={name.id} value={name.id}>
                                    <Checkbox checked={personName.indexOf(name.id) > -1} />
                                    <ListItemText primary={name.value} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                <Button type="submit" variant="contained" size="medium">
                                    Save
                                </Button>
                            </div></div></div>
                </form>
            </Card>
        </div>
    );
}
export default TagDish;