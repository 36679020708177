/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React , {useRef} from 'react';
import '../../App.css';
import { MDBDataTable } from "mdbreact";
import { display } from '@mui/system';
import DataTable from "react-data-table-component";
import birthGif from '../../assets/gift.gif';
import anniversaryGif from '../../assets/wine.gif';
import Input from "../UI/Input/Input";
import axios from "axios";
import { endpoints } from "../../services/apiurl";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import { Box,Button,Card } from '@mui/material';
import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';
import { MonthPicker } from '@mui/lab';

class AppUser extends React.Component {
  // Constructor
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      DataisLoaded: false,
      card_num:'',
      type:'',
      currentPage: 1,
      totalPages: 1,
      total:'',
      per_page: 10,
    };
  }
 
  // ComponentDidMount is used to
  // execute the code
  // componentDidMount() {
  //   fetch("https://ngapi.firstfiddle.in/api/admin/getCustomerFeedback")
  //     .then((res) => res.json())
  //     .then((json) => {
  //       this.setState({
  //         items: json,
  //         DataisLoaded: true,
  //       });
  //       console.log(json)

  //     });
  // }
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let a  = localStorage.getItem('user')
    let b = JSON.parse(a)
    let user_id = b.id
    let restro_id = b.restro_id
    var page = this.state.currentPage;
    var per_page = this.state.per_page;
    var type = '2'
    axios
      .post(endpoints.getAppUser, {
      
        
      })
      .then((response) => {
        // window.location.reload(true);
        console.log("response", response);
        this.setState({
          items:response.data.data,
          total: response.data.total,
          totalPages:response.data.total_pages,
          DataisLoaded: true,
        });
        console.log(response.data);
      });
  }

  filterfetch(e) {
   
    console.log(e)
    
  }
  
  render() {


    const { DataisLoaded, items,total } = this.state;
    const a = this.state.items
    console.log(a)
    const b = this.state.total
    console.log(b)
    // const b = JSON.stringify(a)
    const dates = new Date();
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const month = months[dates.getMonth()] 
    const date = JSON.stringify(dates.getDate())
    const year = JSON.stringify(dates.getFullYear())
    const recent_date = month + '-' + date



    console.log('date', date);
    console.log('month', month);
    console.log('year', year);
    console.log('recent_date', recent_date);

    console.log(a, "items")
   
    const columns = [
      {
        name: "Name",
        selector: (row) => row.full_name,
        cellExport:(row) =>row.full_name,
        sortable: true 
      },
      {
        name: "Mobile Number",
        selector: row => row.mobile,
        cellExport:(row) =>row.mobile,
        sortable: true 
      },
      {
        name: "Card",
        selector: row => row.card_holder_id,
        cellExport:(row) =>row.card_holder_id,
        sortable: true 
      },
      {
        name: "Loyalty Points",
        selector: (row) => row.card_holder_point,
        cellExport:(row) =>row.card_holder_point,
        sortable: true 
      },
      {
        name: "Card Number",
        selector: (row) => row.card_num,
        cellExport:(row) =>row.card_num,
        sortable: true 
      }
    ];
   
    const onSubmit = async e => {
      this.filterfetch(e)
      // console.log(e)

     
  }
  const bFilter = async ()=>{
    
    var card_num = this.card_num
  

    let a  = localStorage.getItem('user')
    let b = JSON.parse(a)
    let user_id = b.id
    let restro_id = b.restro_id

    axios
      .post(endpoints.appUserFilter, {
        card_num,
        user_id,
        restro_id
        
      })
      .then((response) => {
        // window.location.reload(true);
        console.log("response", response);
        this.setState({
          items:response.data.data,
          // total: response.data.total,
          // totalPages:response.data.total_pages,
          DataisLoaded: true,
        });
        console.log(response.data);
      });

  }
  const onNumChange = e => {
    this.card_num = e.target.value
   
}

const onTypeChange = e => {
  this.type = e.target.value
 
}

    const data = a;
    const tableData = {
      columns,
      data,
    };
   
  
    if (!DataisLoaded)
      return (
        <div>
          <h4> Pleses wait some time.... </h4>{" "}
        </div>
      );

    return (
     <>
      <h2>App Users</h2>
      <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                  <div className='row'>
                  <div className='col-md-3'>

                    {/* <label>Enter Card Number</label>
                  
                    <input id="card_num" className='form' type="text" name="card_num" place="Card Number" onChange={(e) => onNumChange(e)}
                        requiredInput="required" maxLength={5}
                    /> */}
                     <div class="form-group">
    <label for="exampleInputEmail1">Enter Card Number</label>
    <input type="text" class="form-control" id="exampleInputEmail1"  maxLength={5}  name="card_num" onChange={(e) => onNumChange(e)} aria-describedby="emailHelp"  placeholder="Card Number"/>
    <small id="emailHelp" class="form-text text-muted">Enter last five digit of card number</small>
  </div>
                    </div>
               
                    <div className='col-md-3'>
                          <div className="col col-3">
                            <div className="mt-5 text-center">
                             
                                <Button className='w-50' onClick={()=>bFilter()} variant="contained" size="medium">
                                    Filter 
                                </Button>
                            </div>
                          </div>
                      </div>
                      </div>
                </form>
      </Card>
      <div className='row mt-5'>
      

        {/* <MDBDataTable striped bordered hover className='col-md-12' data={basicData} /> */}
        <DataTableExtensions
      {...tableData}
    >
        <DataTable
          defaultSortField="id"
          pagination
          paginationPerPage={7}
          paginationRowsPerPageOptions={[7,14,21,28,35]}
         
        />
        
        </DataTableExtensions>
       
      </div>
      </>
    );
  }
}
export default AppUser;
