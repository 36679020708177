import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';


import { Button, Card } from '@mui/material';
import FileUploader from "../FileUploader";

import { endpoints } from "../../services/apiurl";

import Input from "../UI/Input/Input";
import TextAreaInput from "../UI/Input/TextAreaInput";

const AddDish = () => {
    let history = useHistory();
    const [cat, setCat] = useState({
        name: '',
        price: '',
        status: '',
        description: '',

    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileImage, setSelectedFileImage] = useState(null);


    const { name, price, status, description } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        // console.log(cat);
    }

    const onSubmit = async e => {
        e.preventDefault();
        if (!selectedFile) {
            alert('Please select icon');
            return false;
        }
        if (!selectedFileImage) {
            alert('Please select image');
            return false;
        }
        const formData = new FormData();
        formData.append("name", name);
        formData.append("price", price);
        formData.append("description", description);
        formData.append("icon", selectedFile);
        formData.append("image", selectedFileImage);
        formData.append("status", status);
        await axios.post(endpoints.addDish, formData).then((response) => {
            history.goBack();
        });
    }

    return (
        <div className="ui main">
            <h2>Add Dish</h2>
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <Input id="name" label="Name" type="text" name="name" place="Name" onChange={(e) => onInputChange(e)}
                        requiredInput="required" value={name}
                    />
                    <Input id="price" label="Price" type="number" name="price" place="Price" onChange={(e) => onInputChange(e)}
                        requiredInput="required" value={price}
                    />
                    <TextAreaInput id="description" label="Description" name="description" place="Description" onChange={(e) => onInputChange(e)}
                        requiredInput="required" value={description} class="col col-6" />
                    <div className="row">
                        <div className="col col-6">
                            <label>Icon</label>
                            <FileUploader
                                onFileSelectSuccess={(file) => setSelectedFile(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-6">
                            <label>Image</label>
                            <FileUploader
                                onFileSelectSuccess={(file) => setSelectedFileImage(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                <Button type="submit" variant="contained" size="medium">
                                    Save
                                </Button>
                            </div></div></div>


                </form>
            </Card>
        </div>
    );

}

export default AddDish;