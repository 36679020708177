import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import { Button, Card } from '@mui/material';
import FileUploader from "../../FileUploader";

import { endpoints } from "../../../services/apiurl";
import Input from "../../UI/Input/Input";
import PageTitle from "../../UI/PageTitle";
import DeleteIcon from '@mui/icons-material/Delete';


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const EditHomeSlider = () => {
    let history = useHistory();
    let { id } = useParams();

    const [cat, setCat] = useState({
        name: '',
        status: '',
        image: '',
        iconName: '',
        path_id: '',
        path_url: '',
        button_name: '',
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setloading] = useState(false);

    const { name, status, image, iconName, path_id, path_url, button_name } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    useEffect(() => {
        editcategory();
    }, []);

    const editcategory = async () => {
        await axios.post(endpoints.editHomeSlider, { id: id }).then((response) => {
            setCat({
                id: response.data.data.id,
                name: response.data.data.name,
                status: response.data.data.status,
                image: response.data.data.image,
                path_id: response.data.data.path_id,
                path_url: response.data.data.path_url,
                iconName: response.data.data.iconName,
                button_name: response.data.data.button_name,
            });
        });
    }

    const removeImageHandler = async (id) => {
        await axios.post(endpoints.deleteHomeSliderImage, { id }).then((response) => {
        });
        window.location.reload();
    };

    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", name);
        formData.append("path_id", path_id);
        formData.append("path_url", path_url);
        formData.append("button_name", button_name);
        formData.append("image", selectedFile);
        formData.append("status", status);
        formData.append("id", id);
        await axios.post(endpoints.updateHomeSlider, formData).then((response) => {
            setloading(false);
            history.goBack();
        });
    }

    return (
        <div className="ui main">
            <PageTitle title="Edit Home Slider" linkurl="" linkTitle="" />
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <div className="row">
                        <Input id="name" label="Name" type="text" name="name" place="Name" onChange={(e) => onInputChange(e)}
                            requiredInput="true" value={name} class="col col-6"
                        />

                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" className="form-control" onChange={e => onInputChange(e)} value={status} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <Input id="path_id" label="Path ID" type="text" name="path_id" place="Path ID" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={path_id} class="col col-6"
                        />

                        <Input id="path_url" label="Path Url" type="text" name="path_url" place="Path Url" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={path_url} class="col col-6"
                        />

                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <label>Image</label>
                            <FileUploader
                                onFileSelectSuccess={(file) => setSelectedFile(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />

                            {iconName ? (
                                <div className="mt-3">
                                    <img src={image} height="200px" width="200px" className="px-2" />
                                    <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id)}>
                                        Delete
                                    </Button>
                                </div>
                            ) : ''}
                        </div>

                        <Input id="button_name" label="Button Name" type="text" name="button_name" place="Button Name" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={button_name} class="col col-6"
                        />


                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                {/* <Button type="submit" variant="contained" size="large">
                                    Save
                                </Button> */}
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div>

                    </div>

                </form>


            </Card>

        </div>
    );

}

export default EditHomeSlider;
