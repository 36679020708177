import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import { Button, Card } from '@mui/material';

import { endpoints } from "../../services/apiurl";
import Input from "../UI/Input/Input";
import PageTitle from "../UI/PageTitle";
import DeleteIcon from '@mui/icons-material/Delete';


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import TextAreaInput from "../UI/Input/TextAreaInput";


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

const EditBooking = () => {
    let history = useHistory();
    let { id } = useParams();

    const [cat, setCat] = useState({
        full_name: '',
        user_id:'',
        mobile: '',
        order_id: '',
        amount: '',
        point_earn: '',
        status: '',
        restro_id: '',
        showImage: '',
        showLogo: '',
        reason: '',
    });


    const [loading, setloading] = useState(false);
    const [rest, setRest] = useState([]);

    const [eventDate, seteventDate] = useState(new Date());
    const { full_name,user_id, mobile, order_id, amount, point_earn, restro_id, status, showLogo, showImage, reason } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    useEffect(() => {
        editcategory();
        getRestaurentsDropDown();
    }, []);

    const getRestaurentsDropDown = async () => {
        await axios.post(endpoints.getRestaurentsDropDown).then((response) => {
            setRest(response.data.data);
        });
    };

    const editcategory = async () => {
        await axios.post(endpoints.editBill, { id: id }).then((response) => {
            setCat({
                id: response.data.data.id,
                full_name: response.data.data.full_name,
                user_id: response.data.data.user_id,

                order_id: response.data.data.order_id,
                status: response.data.data.status,
                amount: response.data.data.amount,
                point_earn: response.data.data.point_earn,
                restro_id: response.data.data.restro_id,
                showImage: response.data.data.rimage,
                showLogo: response.data.data.image,
                reason: response.data.data.reason != null ? response.data.data.reason : '',

            });
        });
    }

    const removeImageHandler = async (id) => {
        await axios.post(endpoints.deleteImageEvent, { id }).then((response) => {
        });
        window.location.reload();
    };

    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append("status", status);
        formData.append("reason", reason);
        formData.append("user_id", user_id);
        formData.append("id", id);
        await axios.post(endpoints.updateBill, formData).then((response) => {
            setloading(false);
            history.goBack();
        });
    }

    return (
        <div className="ui main">
            <PageTitle title="Edit Loyalty Point Bill" linkurl="" linkTitle="" />
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <div className="row">
                        <div className="col col-6">
                            <label>Restaurents </label>
                            <select name="restro_id" required className="form-control" onChange={e => onInputChange(e)} value={restro_id}>
                                <option value="">Select Restaurents</option>
                                {rest.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                        </div>
                        
                        <Input id="user_id" label="Name" type="hidden" name="user_id" place="Name" onChange={(e) => onInputChange(e)}
                            value={user_id} class="col col-6" 
                        />

                        <Input id="full_name" label="Name" type="text" name="full_name" place="Name" onChange={(e) => onInputChange(e)}
                            value={full_name} class="col col-6"
                        />
                        {/* <Input id="price" label="Price" type="text" name="price" place="Price" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={price} class="col col-6"
                        /> */}
                    </div>

                    <div className="row">
                        <Input id="order_id" label="Order ID" type="text" name="order_id" place="Order ID" onChange={(e) => onInputChange(e)}
                            value={order_id} class="col col-6"
                        />

                        <Input id="amount" label="Amount" type="text" name="amount" place="Amount" onChange={(e) => onInputChange(e)}
                            value={amount} class="col col-6"
                        />
                    </div>
                    <div className="row">
                        {showLogo &&
                            <div className="mt-3">
                                <a target="_blank" href={showImage}>
                                    <img src={showImage} height="200px" width="200px" className="px-2" />

                                </a>
                            </div>
                        }

                    </div>
                    <div className="row">
                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" required className="form-control" onChange={e => onInputChange(e)} value={status} >
                                <option value="">Select Status</option>
                                <option value="1">Credit</option>
                                <option value="2">Debit</option>
                                <option value="3">In Process</option>
                                <option value="4">Failed</option>

                            </select>
                        </div>
                        {
                            status == 4 &&
                            <TextAreaInput id="reason" label="Reason" name="reason" place="Reason" onChange={(e) => onInputChange(e)}
                                requiredInput="required" value={reason} class="col col-6" />
                        }

                    </div>




                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                {/* <Button type="submit" variant="contained" size="large">
                                    Save
                                </Button> */}
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div>

                    </div>

                </form>


            </Card>

        </div>
    );

}

export default EditBooking;
