import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { Button, Card } from '@mui/material';
import FileUploader from "../FileUploader";

import { endpoints } from "../../services/apiurl";

import Input from "../UI/Input/Input";
import PageTitle from "../UI/PageTitle";

const AddCategory = () => {
    let history = useHistory();
    const [cat, setCat] = useState({
        name: '',
        // icon: '',
        status: ''
    });
    const [selectedFile, setSelectedFile] = useState(null);

    const { name, status } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    const onSubmit = async e => {
        e.preventDefault();
        if (!selectedFile) {
            alert('Please select image');
            return false;
        }
        const formData = new FormData();
        formData.append("name", name);
        formData.append("icon", selectedFile);
        formData.append("status", status);
        // console.log('ca', formData);
        await axios.post(endpoints.addCategory, formData).then((response) => {
            history.goBack();
            // if (response.data.status == 200) {
            //     // toast.success(response.data.message);
            //     history.goBack();
            // } else {
            //     // toast.error(response.data.message);
            // }
        });
    }
 
    return (
        <div className="ui main">
            <PageTitle title="Add Category" linkurl="" linkTitle="" />

            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <Input id="name" label="Name" type="text" name="name" place="Name" onChange={(e) => onInputChange(e)}
                        requiredInput="required" value={name}
                    />
                    <div className="row">
                        <div className="col col-6">
                            <label>Icon</label>
                            <FileUploader
                                onFileSelectSuccess={(file) => setSelectedFile(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                <Button type="submit" variant="contained" size="medium">
                                    Save
                                </Button>
                            </div></div></div>


                </form>
            </Card>
        </div>
    );

}

export default AddCategory;



// import React from "react";

// class AddCategory extends React.Component {
//     state = {
//         name: "",
//         email: "",
//     };

//     add = (e) => {
//         e.preventDefault();
//         if (this.state.name === "" || this.state.email === "") {
//             // alert("ALl the fields are mandatory!");
//             return;
//         }
//         this.props.addContactHandler(this.state);
//         this.setState({ name: "", email: "" });
//         this.props.history.push('/');
//     };
//     render() {
//         return (
//             <div className="ui main">
//                 <h2>Add Contact</h2>
//                 <form className="ui form" onSubmit={this.add}>
//                     <div className="field">
//                         <label>Name</label>
//                         <input
//                             type="text"
//                             required
//                             name="name"
//                             placeholder="Name"
//                             value={this.state.name}
//                             onChange={(e) => this.setState({ name: e.target.value })}
//                         />
//                     </div>
//                     <div className="field">
//                         <label>Email</label>
//                         <input
//                             type="text"
//                             name="email"
//                             placeholder="Email"
//                             value={this.state.email}
//                             onChange={(e) => this.setState({ email: e.target.value })}
//                         />
//                     </div>
//                     <button className="ui button blue">Add</button>
//                 </form>
//             </div>
//         );
//     }
// }

// export default AddCategory;
