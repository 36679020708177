import React, { Component, Fragment } from "react";
import axios from "axios";
import { endpoints } from "../services/apiurl";
import birthGif from '../assets/gift.gif';
import anniversaryGif from '../assets/wine.gif';
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { MonthPicker } from "@mui/lab";
import moment from "moment";

class IndexDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            DataisLoaded: false,
            currentPage: 1,
            totalPages: 1,
            per_page: 10,
        };
    }
    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        let a = localStorage.getItem('user')
        let b = JSON.parse(a)
        let user_id = b.id
        let restro_id = b.restro_id
        var page = this.state.currentPage;
        var per_page = this.state.per_page;
        axios
            .post(endpoints.getCustomerFeedback, {
                page,
                per_page,
                user_id,
                restro_id

            })
            .then((response) => {
                // window.location.reload(true);
                console.log("response", response);
                this.setState({
                    items: response.data.data,

                    DataisLoaded: true,
                });
                console.log(response.data.data);
            });
    }
    render() {
        // const { DataisLoaded, items } = this.state;
        const a = this.state.items
        console.log(a)
        // const b = JSON.stringify(a)
        const dates = new Date();
        const month = JSON.stringify(dates.getMonth() + 1)
        const date = JSON.stringify(dates.getDate())
        const year = JSON.stringify(dates.getFullYear())
        // const recent_date = month + '-' + date



        console.log('date', date);
        console.log('month', month);
        console.log('year', year);
        // console.log('recent_date', recent_date);

        return (
            <Fragment>
                {/* <h2>DashBoard Page</h2> */}
                <h2 style={{'font-family': "'Great Vibes', 'cursive'",'font-size':'55px','letterSpacing':'3px'}} className="text-center">This Month</h2>
                <div className="row mt-5">
                    <div className='col-md-1'></div>
                    <div className="card col-md-4">
                        <div className="card-header bg-white font-link"> Birthday <img src={birthGif} className='bg-primary' alt="react logo" style={{ width: '72px', float: 'right', margin: '-22px -31px 0px 0px' }} /> </div>
                        {/* <h6 className="text-center " style={{'text-decoration-line': 'underline','text-decoration-style': 'wavy'}}>This Month</h6>  */}
                       <br/>
                      
                        <div className="card-body overflow-auto cards-container"  style={{height:'150px'}}>
                            
                            {this.state.items.map((item, index) => (

                                <p className={`card-text ${(moment(new Date(item.dob)).format("YYYY-MM-DD")).substr(5,2) === month ? "d-block" : "d-none"}`}>
                                   
                                
                                    {item.name} 
                                    <span style={{ float:'right'}}> {moment(new Date(item.dob)).format("DD/MM/YYYY")}</span>
                                   

                                     </p>
                            ))}
                        </div>

                        <div className="text-center">
                        <Link to={`/dashboard/feedback`}>  
                        <Button>View More
                </Button> </Link>
                </div>

                    </div>
                    <div className='col-md-2 text-center'>
                      <h1 style={{'font-style': 'oblique' ,'color': 'lightblue','font-size': '70px','margin-top': '80px'}}>&</h1>
                    </div>
                    <div className="card col-md-4 justify-content-end">

                    <div className="card-header bg-white font-link"> Anniversary <img src={anniversaryGif} className='bg-primary' alt="react logo" style={{ width: '72px', float: 'right', margin: '-22px -31px 0px 0px' }} /> 
                    </div>
                        <br/>
                        <div className="card-body overflow-auto cards-container" style={{height:'150px'}}>

                            {this.state.items.map((item, index) => (
                                
                            

                                <p className={`card-text ${(moment(new Date(item.anniversary)).format("YYYY-MM-DD")).substr(5,2) === month ? "d-block" : "d-none"}`}>
                                     {item.name} 
                                   
                                     <span style={{ float:'right'}}> {moment(new Date(item.anniversary)).format("DD/MM/YYYY")}</span>

                                    
                                </p>
                            ))}
                        </div>
                        <div className="text-center">
                        <Link to={`/dashboard/feedback`}>  
                        <Button>View More
                </Button> </Link>
                </div>
                       
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default IndexDashboard;
