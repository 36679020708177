import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import { Button, Card } from '@mui/material';
import FileUploader from "../../../FileUploader";

import { endpoints } from "../../../../services/apiurl";
import Input from "../../../UI/Input/Input";
import PageTitle from "../../../UI/PageTitle";
import DeleteIcon from '@mui/icons-material/Delete';


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const EditSubCategory = () => {
    let history = useHistory();
    let { id } = useParams();

    const [cat, setCat] = useState({
        name: '',
        status: '',
    });
    const [selectedFile, setSelectedFile] = useState(null);

    const { name, status, icon, iconName } = cat;
    const [loading, setloading] = useState(false);

    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    useEffect(() => {
        editsubcategory();
    }, []);

    const editsubcategory = async () => {
        await axios.post(endpoints.editSubCategory, { id: id }).then((response) => {
            setCat({
                id: response.data.data.id,
                name: response.data.data.name,
                status: response.data.data.status
            });
        });
    }

    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", name);
        formData.append("status", status);
        formData.append("id", id);
        await axios.post(endpoints.updateSubCategory, formData).then((response) => {
            setloading(false);
            history.goBack();
            // if (response.data.status == 200) {
            //     // toast.success(response.data.message);
            //     history.goBack();
            // } else {
            //     // toast.error(response.data.message);
            // }
        });
    }

    return (
        <div className="ui main">
            <PageTitle title="Edit Category" linkurl="" linkTitle="" />
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">


                    <div className="row">
                        <Input id="name" label="Name" type="text" name="name" place="Name" onChange={(e) => onInputChange(e)}
                            requiredInput="true" value={name} class="col col-6"
                        />
                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" className="form-control" onChange={e => onInputChange(e)} value={status} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                {/* <Button type="submit" variant="contained" size="small">
                                    Save
                                </Button> */}   <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div></div>


                </form>
            </Card>

        </div>
    );

}

export default EditSubCategory;
