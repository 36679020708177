import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import { Card } from '@mui/material';
import Button from '@mui/material/Button';
import FileUploader from "../FileUploader";

import { endpoints } from "../../services/apiurl";
import Input from "../UI/Input/Input";
import TextAreaInput from "../UI/Input/TextAreaInput";

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const EditTags = () => {
    let history = useHistory();
    let { id } = useParams();

    const [cat, setCat] = useState({
        name: '',
        color_code: '',
        type: '',
        short_name: '',
    });
    const { name, color_code, type, short_name } = cat;
    const [loading, setloading] = useState(false);

    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    useEffect(() => {
        edit();
    }, []);

    const edit = async () => {
        await axios.post(endpoints.editTags, { id: id }).then((response) => {
            setCat({
                id: response.data.data.id,
                color_code: response.data.data.color_code,
                name: response.data.data.name,
                type: response.data.data.type,
                short_name: response.data.data.short_name,
            });
        });
    }

    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", name);
        formData.append("short_name", short_name);
        formData.append("color_code", color_code);
        formData.append("type", type);
        formData.append("id", id);
        await axios.post(endpoints.updateTag, formData).then((response) => {
            setloading(false);
            history.goBack();
            // if (response.data.status == 200) {
            //     // toast.success(response.data.message);
            //     history.goBack();
            // } else {
            //     // toast.error(response.data.message);
            // }
        });
    }

    return (
        <div className="ui main">
            <h2>Edit Tag</h2>
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <div className="row">
                        <Input id="name" label="Name" type="text" name="name" place="Name" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={name} class="col col-6"
                        />
                        <Input id="short_name" label="Short Name" type="text" name="short_name" place="Short Name" onChange={(e) => onInputChange(e)}
                            value={short_name} class="col col-6"
                        />

                    </div>
                    <div className="row">

                        <Input id="color_code" label="Color Code" type="text" name="color_code" place="Color Code" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={color_code} class="col col-6"
                        />

                        <div className="col col-6">
                            <label>Status</label>
                            <select name="type" required className="form-control" onChange={e => onInputChange(e)} value={type}>
                                <option value="">Select Type</option>
                                {/* <option value="1">VEG</option>
                                <option value="2">Non-Veg</option> */}
                                <option value="3">Health</option>
                                <option value="4">Special</option>
                                <option value="5">Allergens</option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                {/* <Button type="submit" variant="contained" size="small">
                                    Save
                                </Button> */}
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div></div>


                </form>
            </Card>

        </div>
    );

}

export default EditTags;
