import React from 'react';
import { Link } from "react-router-dom";
import { Card, Grid, Typography, Button } from '@mui/material';


const LinkButton = props => {
    return <Link to={props.url}>
        <Button variant="contained"
            size="medium"
            className="yellow_color"
        >{props.name}</Button>
    </Link>
}

export default LinkButton;