/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React , {useRef} from 'react';
import '../../App.css';
import { MDBDataTable } from "mdbreact";
import { display } from '@mui/system';
import DataTable from "react-data-table-component";
import birthGif from '../../assets/gift.gif';
import anniversaryGif from '../../assets/wine.gif';
import Input from "../UI/Input/Input";
import axios from "axios";
import { endpoints } from "../../services/apiurl";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import { Box,Button,Card, TextField } from '@mui/material';
import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';
import { MonthPicker } from '@mui/lab';
import { withRouter } from 'react-router-dom';

class Feedback extends React.Component {
  // Constructor
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      DataisLoaded: false,
      rest:'',
      start_date:'',
      end_date:'',
      type:'',
      restro_id:'',
      currentPage: 1,
      totalPages: 1,
      total:'',
      per_page: 10,
    };
  }
 
  // ComponentDidMount is used to
  // execute the code
  // componentDidMount() {
  //   fetch("https://ngapi.firstfiddle.in/api/admin/getCustomerFeedback")
  //     .then((res) => res.json())
  //     .then((json) => {
  //       this.setState({
  //         items: json,
  //         DataisLoaded: true,
  //       });
  //       console.log(json)

  //     });
  // }
  componentDidMount() {
    this.fetchData();
    this.fetchRestData();
  }

  fetchData() {
    let a  = localStorage.getItem('user')
    let b = JSON.parse(a)
    let user_id = b.id
    let restro_id = b.restro_id
    console.log(restro_id)
    var page = this.state.currentPage;
    var per_page = this.state.per_page;
    axios
      .post(endpoints.getCustomerFeedback, {
        page,
        per_page,
        user_id,
        restro_id,
      
      })
      .then((response) => {
        // window.location.reload(true);
        console.log("response", response);
        this.setState({
          items:response.data.data,
          total: response.data.total,
          totalPages:response.data.total_pages,
          DataisLoaded: true,
        });
        console.log(response.data);
      });
  }

  fetchRestData() {
    let a  = localStorage.getItem('user')
    let b = JSON.parse(a)
    let user_id = b.id
    let restro_id = b.restro_id
    var page = this.state.currentPage;
    var per_page = this.state.per_page;
    axios
      .post(endpoints.getRestaurentsDropDown, {
                
        
      })
      .then((response) => {
        // window.location.reload(true);
        console.log("response", response);
        this.setState({
          restaurent:response.data.data,
          total: response.data.total,
          totalPages:response.data.total_pages,
          DataisLoaded: true,
        });
        console.log(response.data);
      });
  }

  filterfetch(e) {
   
    console.log(e)
    
  }
  
  render() {


    // const [hideDirector, setHideDirector] = React.useState(true);
    const { DataisLoaded, items,total } = this.state;
    const a = this.state.items
    console.log(a)
    const b = this.state.total
    console.log(b)
    // const b = JSON.stringify(a)
    const links = (this.state.restaurent);
    console.log(links)
    const user  = localStorage.getItem('user')
    const user_data = JSON.parse(user)
    this.restro_id = user_data.restro_id
    console.log(this.restro_id)
    const dates = new Date();
    const month = JSON.stringify(dates.getMonth() + 1)
    const date = JSON.stringify(dates.getDate())
    const year = JSON.stringify(dates.getFullYear())
    const recent_date = month + '-' + date



    console.log('date', date);
    console.log('month', month);
    console.log('year', year);
    console.log('recent_date', recent_date);

    console.log(a, "items")
    const ExpandedComponent = (suppData) => ({ data }) => {
      // const a = JSON.parse(data, null, 2)
      return (
        <>

          <div className='row px-5'>

            <div className="col-md-6">
              <mat-card >
                <table>
                  <tr>
                    <th className='w-50'>Name</th>
                    <td>{data.name}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{data.email}</td>
                  </tr>
                  <tr>
                    <th>Mobile</th>
                    <td>{data.mobile}</td>
                  </tr>
                  <tr>
                    <th>Type</th>
                    <td>{data.type}</td>
                  </tr>
                  <tr>
                    <th>Profession</th>
                    <td>{data.profession}</td>
                  </tr>
                  <tr>
                    <th>Anniversary</th>
                    <td>{data.anniversary}</td>
                  </tr>
                  <tr>
                    <th>DOB</th>
                    <td>{data.dob}</td>
                  </tr>
                  <tr>
                    <th>Ambiance</th>
                    <td>{data.ambiance}</td>
                  </tr>
                  <tr>
                    <th>Music</th>
                    <td>{data.music}</td>
                  </tr>
                  <tr>
                    <th>Server Name</th>
                    <td>{data.servant_name}</td>
                  </tr>

                </table>
              </mat-card>
            </div>

            <div className="col-md-6">
              <mat-card>
                <table>
                  <tr>

                    <th className='w-75'>Service</th>
                    <td>{data.service}</td>

                  </tr>
                  <tr>
                    <th>Quality of Foodienc</th>
                    <td>{data.qty_of_foodienc}</td>
                  </tr>
                  <tr>
                    <th>Value for Money</th>
                    <td>{data.val_for_money}</td>
                  </tr>
                  <tr>
                    <th>Did Manager Visit the Table</th>
                    <td>{data.did_manage_visit}</td>
                  </tr>
                  <tr>
                    <th>Best about Restaurant</th>
                    <td>{data.what_did_best_like}</td>
                  </tr>
                  <tr>
                    <th>Dining Experience Better</th>
                    <td>{data.dining_exp_better}</td>
                  </tr>
                  <tr>
                    <th>Valuable Feedback </th>
                    <td>{data.valuable_feedback}</td>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <td>{data.address}</td>
                  </tr>
                  <tr>
                    <th>Create on </th>
                    <td>{data.create_on}</td>
                  </tr>

                </table>
              </mat-card>
            </div>
          </div>
          {suppData}{" "}
        </>
      );
    };
    const columns = [
      {
      
        selector: (row) => row.name,
        cellExport:(row) =>row.name,
        sortable: true 
      },
      {
    
        selector: row => row.email,
        cellExport:(row) =>row.email,
        sortable: true 
      },
      {
        selector: row => row.mobile,
        cellExport:(row) =>row.mobile,
        sortable: true 
      },
      {
     
        // selector: row => row.dob,
        cellExport:(row) =>row.dob,
        selector: row =>new Date(row.dob).toLocaleString(),
        // cellExport:(row) =>new Date(row.dob).toLocaleString(),
        omit:true,
        sortable: true 
       
      },
      {
      
        // selector: row => row.anniversary,
        cellExport:(row) =>row.anniversary,
        selector: row =>new Date(row.anniversary).toLocaleString(),
        // cellExport:(row) =>new Date(row.anniversary).toLocaleString(),
        omit:true,
        sortable: true 
      },
      {
      
        selector: row => row.type,
        cellExport:(row) =>row.type,
        sortable: true 
      },
      {
       
      
        selector: (row) => row.ambiance,
        cellExport:(row) =>row.ambiance,
        omit:true,
        sortable: true 
      },
      {
     
        selector: row => row.music,
        cellExport:(row) =>row.music,
        omit:true,
        sortable: true 
      },
      {
      
        selector: row => row.service,
        cellExport:(row) =>row.service,
        omit:true,
        sortable: true 
      },
      {
     
        selector: row => row.qty_of_foodienc,
        cellExport:(row) =>row.qty_of_foodienc,
        omit:true,
        sortable: true 
      },
      {
        selector: row => row.val_for_money,
        cellExport:(row) =>row.val_for_money,
        omit:true,
        sortable: true 
      },
      {
       
        selector: row => row.did_manage_visit,
        cellExport:(row) =>row.did_manage_visit,
        omit:true,
        sortable: true 
      },
      {
        
        selector: row => row.what_did_best_like,
        cellExport:(row) =>row.what_did_best_like,
        omit:true,
        sortable: true 
      },
      {
      
        selector: row => row.dining_exp_better,
        cellExport:(row) =>row.dining_exp_better,
        omit:true,
        sortable: true 
      },
      {
     
        selector: row => row.valuable_feedback,
        cellExport:(row) =>row.valuable_feedback,
        omit:true,
        sortable: true 
      },
      {
      
        selector: row => row.address,
        cellExport:(row) =>row.address,
        omit:true,
        sortable: true 
      },
      {
      
        selector: row => row.create_on,
        cellExport:(row) =>row.create_on,
        sortable: true 
      },
      {
      
        selector: row => row.servant_name,
        cellExport:(row) =>row.servant_name,
        omit:true,
        sortable: true 
      }
    ];
   
    const onSubmit = async e => {
      this.filterfetch(e)
      // console.log(e)

     
  }
  const bFilter = async ()=>{
    // console.log(this.start_date);
    // var end_date = (this.end_date).substr(5,5)
    // var start_date = (this.start_date).substr(5,5)
    var end_date = this.end_date
    var start_date = this.start_date
    var type = this.type
    if(this.rest===undefined){
      var rest = ''
    }
    else{
      var rest = this.rest
    }

    // var end_month = (this.end_date).substr(5,2)
    // var end_date = (this.end_date).substr(8,2)
    // console.log('start_month',start_month)
    // console.log('start_date',start_date)
    // console.log('end_month',end_month)
    // console.log('end_date',end_date)

    let a  = localStorage.getItem('user')
    let b = JSON.parse(a)
    let user_id = b.id
    let restro_id = b.restro_id
console.log(restro_id)
    axios
      .post(endpoints.birthdayFilter, {
        start_date,
        end_date,
        type,
        rest,
        user_id,
        restro_id
        
      })
      .then((response) => {
        // window.location.reload(true);
        console.log("response", response);
        this.setState({
          items:response.data.data,
          // total: response.data.total,
          // totalPages:response.data.total_pages,
          DataisLoaded: true,
        });
        console.log(response.data);
      });

  }
  const onStartChange = e => {
    this.start_date = e.target.value
   
}
const onEndChange = e => {
  this.end_date = e.target.value
 
}
const onTypeChange = e => {
  this.type = e.target.value
 
}
const onRestChange = e => {
  this.rest = e.target.value
 
}
const c = [
  {
    address: "Address ",
    addrress: null,
ambiance: "Ambiance",
anniversary: "Anniversary",
create_on: "Created On",
did_manage_visit: "Manager Visit",
dining_exp_better: "Dinning Exp.",
dob: "DOB",
email: "Email",
id:0,
mobile: "Number",
music: "Music",
name: "Name",
profession: "Profession",
qty_of_foodienc: "Qty. Of Food",
restro_id: 5,
service: "Service",
type: "Restaurant",
val_for_money: "Val. For Money",
valuable_feedback: "Feedback",
what_did_best_like: "Best Like",
servant_name:'Servant Name'
  }
];
// const data = heading.concat(c);
const data = c.concat(a);
console.log(data)
    const tableData = {
      columns,
      data,
    };
   
  
    if (!DataisLoaded)
      return (
        <div>
          <h4> Pleses wait some time.... </h4>{" "}
        </div>
      );

    return (
     <>
      <h2>Feedback Leads</h2>
      <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                  <div className='row'>
                  <div className='col-md-3'>
                    
                    {/* <Input id="start_date" label="Start Date" type="date" name="start_date" place="Start Date" onChange={(e) => onStartChange(e)}
                        requiredInput="required"
                    /> */}
                      <label>Start Date</label>
                <br />
                <TextField
                  fullWidth
                  id="start_date"
                  label="Start Date"
                  type="date"
                  name="start_date"
                  place="Start Date"
                  onChange={(e) => onStartChange(e)}
                  InputLabelProps={{
                    shrink: true,

                  }}
                  onKeyDown={(e) => e.preventDefault()}
                // InputProps={{
                //   readOnly: true,
                //  }}
                />
                    </div>
                  <div className='col-md-3'>

                    {/* <Input id="end_date" label="End Date" type="date" name="end_date" place="Start Date" onChange={(e) => onEndChange(e)}
                        requiredInput="required" className='col-md-6'
                    /> */}

<label>End Date</label>
                <br />
                <TextField
                  fullWidth
                  id="end_date"
                  label="End Date"
                  type="date"
                  name="end_date"
                  place="End Date"
                  onChange={(e) => onEndChange(e)}
                  InputLabelProps={{
                    shrink: true,

                  }}
                  onKeyDown={(e) => e.preventDefault()}
                // InputProps={{
                //   readOnly: true,
                //  }}
                />

                    
                    </div>
                    <div className="col-md-3">
                    <label>Status</label>
                            <select name="status" required className="form-control" onChange={e => onTypeChange(e)} >
                                <option value="">Select Status</option>
                                <option value="1">By Birth Date</option>
                                <option value="2">By Anniversary Date</option>
                                <option value="3">By Created Date</option>

                            </select>
                    </div>
                   
                    {this.restro_id === 0 ? <div className="col-md-3">
                    <label>Restaurant</label>
                            <select name="rest" required className="form-control" onChange={e => onRestChange(e)}>
                            <option value="">Select Restaurant</option>
                            {links.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                           

                            </select>
                    </div> : ''}
                    
                        <div className="col col-3">
                            <div className="mt-5 text-center">
                                <Button className='w-50' onClick={()=>bFilter()} variant="contained" size="medium">
                                    Filter
                                </Button>
                            </div></div></div>


                </form>
      </Card>
      <div className='row mt-5'>
      

        {/* <MDBDataTable striped bordered hover className='col-md-12' data={basicData} /> */}
        <DataTableExtensions
      {...tableData}
    >
        <DataTable
          defaultSortField="id"
          pagination
          paginationPerPage={7}
          paginationRowsPerPageOptions={[7,14,21,28,35]}
          expandableRows
          exportHeaders={true}
          expandableRowsComponent={ExpandedComponent()}
        />
        
        </DataTableExtensions>
       
      </div>
      </>
    );
  }
}
export default withRouter(Feedback);
