import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { Button, Card } from '@mui/material';
import FileUploader from "../../FileUploader";

import { endpoints } from "../../../services/apiurl";

import Input from "../../UI/Input/Input";
import PageTitle from "../../UI/PageTitle";


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';


const AddMedia = () => {
    let history = useHistory();
    const [cat, setCat] = useState({
        media_type: '',
        status: '',
        restro_id: '',
        video_link: '',
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setloading] = useState(false);
    const [rest, setRest] = useState([]);


    const { media_type, video_link, status, restro_id } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    useEffect(() => {
        getRestaurentsDropDown();
    }, []);


    const getRestaurentsDropDown = async () => {
        await axios.post(endpoints.getRestaurentsDropDown).then((response) => {
            setRest(response.data.data);
        });
    };

    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        if (media_type == 1) {
            if (!selectedFile) {
                alert('Please select image');
                return false;
            }
        }

        const formData = new FormData();
        formData.append("video_link", video_link);
        formData.append("media_type", media_type);
        formData.append("restro_id", restro_id);
        formData.append("media", selectedFile);
        formData.append("status", status);
        await axios.post(endpoints.addRestaurentsMedia, formData).then((response) => {
            setloading(false);
            history.goBack();
        });
    }
    return (
        <div className="ui main">
            <PageTitle title="Add Gallery" linkurl="" linkTitle="" />

            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <div className="row">
                        <div className="col col-6">
                            <label>Restaurents </label>
                            <select name="restro_id" required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Restaurents</option>
                                {rest.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                        </div>

                        {/* <Input id="name" label="Name" type="text" name="name" place="Name" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={name} class="col col-6"
                        /> */}

                        <div className="col col-6">
                            <label>Media Type</label>
                            <select name="media_type" required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Media Type</option>
                                <option value="1">Image</option>
                                <option value="2">Video</option>
                            </select>
                        </div>

                    </div>

                    <div className="row">


                        {media_type == 2 ?
                            <>
                                <Input id="video link" label="Video Link" type="text" name="video_link" place="Video Link" onChange={(e) => onInputChange(e)}
                                    requiredInput="required" value={video_link} class="col col-6"
                                /> <div className="col col-6">
                                    <label>Image</label>
                                    <FileUploader
                                        onFileSelectSuccess={(file) => setSelectedFile(file)}
                                        onFileSelectError={({ error }) => alert(error)}
                                    />
                                </div> </> :
                            media_type == 1 ? <div className="col col-6">
                                <label>Image</label>
                                <input type="file" />
                                <FileUploader
                                    onFileSelectSuccess={(file) => setSelectedFile(file)}
                                    onFileSelectError={({ error }) => alert(error)}
                                />
                            </div> : ''
                        }
                    </div>

                    {/* <div className="row">
                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>

                    </div> */}

                    {/* <div className="row">
                        <div className="col col-6">
                            <label>Image</label>
                            <FileUploader
                                onFileSelectSuccess={(file) => setSelectedFile(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />
                        </div>
                    </div> */}

                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div></div>


                </form>
            </Card>
        </div>
    );

}

export default AddMedia;


