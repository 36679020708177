/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import '../../App.css';
import { MDBDataTable } from "mdbreact";
import { display } from '@mui/system';
import DataTable from "react-data-table-component";
import birthGif from '../../assets/gift.gif';
import anniversaryGif from '../../assets/wine.gif';
import Input from "../UI/Input/Input";
import axios from "axios";
import { endpoints } from "../../services/apiurl";

import { withRouter } from "react-router";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import { Box, Button, Card } from '@mui/material';
import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';
import { MonthPicker } from '@mui/lab';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

class BookingOrder extends React.Component {
  // Constructor
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      items: [],
      DataisLoaded: false,
      restaurent: [],
      restro_id: '',
      start_date: '',
      rest: '',
      end_date: '',
      type: '',
      currentPage: 1,
      totalPages: 1,
      total: '',
      per_page: 10,
    };
  }

  // ComponentDidMount is used to
  // execute the code
  // componentDidMount() {
  //   fetch("https://ngapi.firstfiddle.in/api/admin/getCustomerFeedback")
  //     .then((res) => res.json())
  //     .then((json) => {
  //       this.setState({
  //         items: json,
  //         DataisLoaded: true,
  //       });
  //       console.log(json)

  //     });
  // }
  componentDidMount() {
    this.fetchData();
    this.fetchRestData();
  }


  fetchData() {
    let a = localStorage.getItem('user')
    let b = JSON.parse(a)
    let user_id = b.id
    let restro_id = b.restro_id
    var page = this.state.currentPage;
    var per_page = this.state.per_page;

    var end_date = this.end_date;
    var start_date = this.start_date;
    var rest = this.rest;

    axios
      .post(endpoints.getBillScanReport, {
        restro_id,
        start_date,
        end_date,
        rest,

      })
      .then((response) => {
        this.setState({
          items: (response.data.data)?response.data.data:[],
          total: (response.data.total)?response.data.total:0,
          totalPages: (response.data.total_pages)? response.data.total_pages:null,
          DataisLoaded: true,
        });
      });
  }

  fetchRestData() {
    axios
      .post(endpoints.getRestaurentsDropDown, {})
      .then((response) => {
        this.setState({
          restaurent: response.data.data,
          total: response.data.total,
          totalPages: response.data.total_pages,
          DataisLoaded: true,
        });
      });
  }

  filterfetch(e) {

    console.log(e)

  }

  render() {
    const { DataisLoaded, items, total } = this.state;
    const a = this.state.items
    console.log('working', a)
    const b = this.state.total
    console.log(b)
    const links = (this.state.restaurent);
    console.log(links)
    const user = localStorage.getItem('user')
    const user_data = JSON.parse(user)
    this.restro_id = user_data.restro_id
    console.log(this.restro_id)

    // const b = JSON.stringify(a)
    const dates = new Date();
    const month = JSON.stringify(dates.getMonth() + 1)
    const date = JSON.stringify(dates.getDate())
    const year = JSON.stringify(dates.getFullYear())
    const recent_date = month + '-' + date

    const columns = [
      {
        name: "Name",
        selector: (row) => row.full_name,
        cellExport: (row) => row.full_name,
        sortable: true
      },
      // {
      //   name: "Email",
      //   selector: row => row.email,
      //   cellExport: (row) => row.email,
      //   sortable: true
      // },
      {
        name: "Mobile Number",
        selector: (row) => row.mobile,
        cellExport: (row) => row.mobile,
        sortable: true
      },
      {
        name: "Order ID",
        selector: row => row.order_id,
        cellExport: (row) => row.order_id,
        sortable: true
      },
      {
        name: "Amount",
        selector: (row) => row.amount,
        cellExport: (row) => row.amount,
        sortable: true
      },
      {
        name: "Points",
        selector: row => row.point_earn,
        cellExport: (row) => row.point_earn,
        sortable: true
      },
      {
        name: "Status",
        selector: row => row.status == 1 ? 'Credit' : (row.status == 2) ? 'Debit' : (row.status == 3) ? 'In Process' : 'Failed',
        cellExport: (row) => row.point_earn,
        sortable: true
      },
      {
        name: "Restaurant",
        selector: (row) => row.name,
        cellExport: (row) => row.name,
        sortable: true
      },

      {
        name: "Action",
        selector: (row) => <div>  <Link to={`/dashboard/editbooking/${row.id}`}> <EditIcon /> </Link>

        </div>,
        sortable: true
      },
    ];

    const onSubmit = async e => {
      this.filterfetch(e)
      // console.log(e)


    }
    const bFilter = async () => {
      this.fetchData();
      // var end_date = this.end_date
      // var start_date = this.start_date
      // var rest = this.rest
      // console.log('working_rest', rest)
      // var type = this.type


      // let a = localStorage.getItem('user')
      // let b = JSON.parse(a)
      // let user_id = b.id
      // let restro_id = b.restro_id

      // axios
      //   .post(endpoints.reservationFilter, {
      //     start_date,
      //     end_date,
      //     rest,
      //     type,
      //     user_id,
      //     restro_id

      //   })
      //   .then((response) => { 
      //     this.setState({
      //       items: response.data.data, 
      //       DataisLoaded: true,
      //     });
      //     console.log(response.data);
      //   });

    }
    const onStartChange = e => {
      this.start_date = e.target.value

    }
    const onEndChange = e => {
      this.end_date = e.target.value

    }
    const onTypeChange = e => {
      this.type = e.target.value

    }

    const onRestChange = e => {
      this.rest = e.target.value

    }

    const data = a;
    const tableData = {
      columns,
      data,
    };

   
    if (!DataisLoaded)
      return (
        <div>
          <h4> Pleses wait some time.... </h4>{" "}
        </div>
      );

    return (
      <>
        <h2>Loyalty Point List</h2>
        {/* <Card className="px-3 py-3">
          <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
            <div className='row'>
              <div className='col-md-3'>

                <Input id="start_date" label="Start Date" type="date" name="start_date" place="Start Date" onChange={(e) => onStartChange(e)}
                  requiredInput="required"
                />
              </div>
              <div className='col-md-3'>

                <Input id="end_date" label="End Date" type="date" name="end_date" place="Start Date" onChange={(e) => onEndChange(e)}
                  requiredInput="required" className='col-md-6'
                />


              </div>

              {this.restro_id === 0 ? <div className="col-md-3">
                <label>Restaurant</label>
                <select name="rest" required className="form-control" onChange={e => onRestChange(e)}>
                  <option value="">Select Restaurant</option>
                  {links.map((e, key) => {
                    return <option key={key} value={e.id}>{e.name}</option>;
                  })}
                 

                </select>
              </div> : ''}
              <div className="col col-3">
                <div className="mt-5 text-center">
                  <Button className='w-50' onClick={() => bFilter()} variant="contained" size="medium">
                    Filter
                  </Button>
                </div></div></div>


          </form>
        </Card> */}
        <div className='row mt-5'>


          {/* <MDBDataTable striped bordered hover className='col-md-12' data={basicData} /> */}
          <DataTableExtensions
            {...tableData}
          >
            <DataTable
              defaultSortField="id"
              pagination
              paginationPerPage={7}
              paginationRowsPerPageOptions={[7, 14, 21, 28, 35]}

            />

          </DataTableExtensions>

        </div>
      </>
    );
  }
}
export default withRouter(BookingOrder);
