import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Redirect, Switch, Route, Link } from "react-router-dom";
import { endpoints } from "../../../services/apiurl";

import LinkButton from '../../UI/Input/LinkButton';
import { Box, Button, IconButton } from '@mui/material';
import Card from "@mui/material/Card";
import PageTitle from '../../UI/PageTitle';


import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Visibility } from '@mui/icons-material';


const RestaurentsDetail = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        fetchData(1, perPage);
    }, [perPage])

    console.log(localStorage.getItem('user'));


    const columns = [
        {
            name: 'NAME',
            selector: row => row.name,
            // width: '200px'
        },
        // {
        //     name: 'PRICE',
        //     selector: row => row.price,
        //     width: '200px'
        // }, 
        {
            name: 'STATUS',
            selector: row => row.show_web == 1 ? 'Active' : 'Inactive',
            // width: '200px'
        },
        // {
        //     name: 'STATUS(App)',
        //     // selector: row => row.status == 1 ? 'Active' : 'Inactive',
        //     selector: row => <div className="custom-control custom-switch">
        //         <input type="checkbox" className="custom-control-input" id={row.id + 'check1'}
        //             {...(row.show_app === 1 ? { checked: 'checked' } : {})}


        //             onClick={() => sub(row.id, row.show_app, 'show_app')} />
        //         <label className="custom-control-label" style={{ marginBottom: "21px" }} for={row.id + 'check1'} ></label>
        //     </div>

        //     // width: '200px'
        // },
        {
            name: 'STATUS',
            // selector: row => row.status == 1 ? 'Active' : 'Inactive',
            selector: row => <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id={row.id + 'check2'}
                    {...(row.show_web === 1 ? { checked: 'checked' } : {})}


                    onClick={() => sub(row.id, row.show_web, 'show_web')} />
                <label className="custom-control-label" style={{ marginBottom: "21px" }} for={row.id + 'check2'} ></label>
            </div>,

        }, {
            name: 'ACTION',
            cell: row => <div>  <Link to={`/dashboard/eddrestaurents/${row.id}`}> <EditIcon /> </Link>
                <Link variant="contained" to={`/dashboard/sectionrest/${row.id}`}>
                    <Visibility />
                </Link>
                <Link className="error" style={{ marginTop: "0px", marginLeft: "0px" }} onClick={() => {
                    const confirmBox = window.confirm(
                        "Do you really want to delete?"
                    )
                    if (confirmBox === true) {
                        removeContactHandler(row.id);
                    }
                }}> <DeleteIcon /> </Link>

            </div>,
            selector: row => row.id,
            // width: '200px'
        },
    ];

    const sub = async (id, old_status, col_name) => {

        if (old_status === 1) {
            var status = 0
        }
        else {
            var status = 1
        }
        // alert( col_name)
        axios
            .post(endpoints.app_webRestStatus, {
                id,
                status,
                col_name
            })
            .then((response) => {
                window.location.reload(true);
                this.setState({
                    DataisLoaded: true,
                });
            });
    }

    const removeContactHandler = async (id) => {
        await axios.post(endpoints.deleteRestaurents, { id }).then((response) => {
        });
        const newContactList = items.filter((contact) => {
            return contact.id !== id;
        });
        setItems(newContactList);
    };
    // let a  = localStorage.getItem('user')
    // let b = JSON.parse(a)
    // let c = b.restro_id

    const fetchData = async (page, per_page) => {
        let a = localStorage.getItem('user')
        let b = JSON.parse(a)
        let user_id = b.id
        let restro_id = b.restro_id

        await axios
            .post(endpoints.getRestaurents, {
                page,
                per_page,
                user_id,
                restro_id

            })
            .then((response) => {

                // let a  = localStorage.getItem('user')
                // let b = JSON.parse(a)
                // let c = response.data.data
                // console.log(c)


                // for (let i in c){
                //     if(b.restro_id === c[i].id){
                //         setItems([c[i]])
                //     }
                //     // let d = JSON.parse(c[i])
                //    console.log(c[i].id)
                // }
                // console.log(b.restro_id)
                console.log(response.data.data)
                setIsLoaded(true);
                setItems(response.data.data);
                setTotalRows(response.data.total);
            },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }

            );
    }

    const handlePageChange = page => {
        fetchData(page, perPage);
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }


    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <div className="App">
                <PageTitle title="Restaurants List" linkurl="/dashboard/addrestaurents" linkTitle="Add Restaurants" />
                <Card className="px-3 py-3">
                    <DataTable
                        columns={columns}
                        data={items}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                    />
                </Card>
            </div>

        );
    }
}

export default RestaurentsDetail;