import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import { Button, Card } from '@mui/material';
import FileUploader from "../../FileUploader";

import { endpoints } from "../../../services/apiurl";
import Input from "../../UI/Input/Input";
import PageTitle from "../../UI/PageTitle";
import DeleteIcon from '@mui/icons-material/Delete';


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import TextAreaInput from "../../UI/Input/TextAreaInput";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
const EditPoint = () => {
    let history = useHistory();
    let { id } = useParams();

    const [cat, setCat] = useState({
        name: '',
        status: '',
        description: '',
        point: '',
        showImage: '',

    });
    const [loading, setloading] = useState(false);
    const [rest, setRest] = useState([]);

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    const updateTextDescription = async (state) => {
        setEditorState(state);
    };

     const removeImageHandler = async (id, type) => {
        await axios.post(endpoints.deleteImagePoint, { id, type }).then((response) => {
        });
        window.location.reload();
    };
    const [selectedFileImage, setSelectedFileImage] = useState(null);
    const { name, description, status, point,showImage,image } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    useEffect(() => {
        editcategory();
        getPriceRangeDropDown();

    }, []);


    const getPriceRangeDropDown = async () => {
        await axios.post(endpoints.getPriceRangeDropDown).then((response) => {
            setRest(response.data.data);
        });
    };

    const editcategory = async () => {
        await axios.post(endpoints.editPoints, { id: id }).then((response) => {
            setCat({
                id: response.data.data.id,
                name: response.data.data.name,
                // description: response.data.data.description,
                status: response.data.data.status,
                point: response.data.data.point,
                image: response.data.data.image,
                showImage: response.data.data.showImage,

            });

            const html = response.data.data.description;
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                // const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(html)));
                setEditorState(editorState);
            }
        });
    }


    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", name);
        // formData.append("description", description);
        formData.append("description", draftToHtml(convertToRaw(editorState.getCurrentContent())));
        formData.append("point", point);
        formData.append("status", status);
        formData.append("id", id);
        formData.append("image", selectedFileImage);

        await axios.post(endpoints.updatePoint, formData).then((response) => {
            setloading(false);
            history.goBack();
        });
    }

    return (
        <div className="ui main">
            <PageTitle title="Edit Card" linkurl="" linkTitle="" />
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <div className="row">
                        {/* <div className="col col-6">
                            <label>Price Range </label>
                            <select name="price_range_id" required className="form-control" onChange={e => onInputChange(e)} value={price_range_id}>
                                <option value="">Select Price Range</option>
                                {rest.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.start_price} - {e.end_price}</option>;
                                })}
                            </select>
                        </div> */}

                        <Input id="name" label="Name" type="text" name="name" place="Name" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={name} class="col col-6"
                        />

                        <Input id="point" label="Loyality Point" type="text" name="point" place="Loyality Point" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={point} class="col col-6"
                        />
                    </div>


                    <div className="row">
                    <div className="col col-6">
                            <label> Image</label>
                            {showImage ? (
                                <div className="mt-3">
                                    <img src={image} height="200px" width="200px" className="px-2" />
                                    <Button variant="outlined" color="error" size="small" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id, 'image')}>

                                    </Button>
                                </div>
                            ) : <FileUploader
                                onFileSelectSuccess={(file) => setSelectedFileImage(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />
                            }
                        </div>
                        {/* <TextAreaInput id="description" label="Description" name="description" place="Description" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={description} class="col col-6" /> */}
                       
                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" required className="form-control" onChange={e => onInputChange(e)} value={status}>
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>

                    </div>

                    <div className="row">
                    <div className="col col-6">
                            <label>Description</label>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={updateTextDescription}
                            />
                            {/* <textarea
                                disabled
                                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                            /> */}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                {/* <Button type="submit" variant="contained" size="large">
                                    Save
                                </Button> */}
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div>

                    </div>

                </form>


            </Card>

        </div>
    );

}

export default EditPoint;
