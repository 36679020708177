import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";

import Box from '@mui/material/Box';
import { Button, Card } from '@mui/material';

import { endpoints } from "../../services/apiurl";
import Input from "../UI/Input/Input";
import PageTitle from "../UI/PageTitle";
import DeleteIcon from '@mui/icons-material/Delete';


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';


const Edituser = () => {
    let history = useHistory();
    let { id } = useParams();

    const [cat, setCat] = useState({
        full_name: '',
        email: '',
        password: '',
        mobile: '',
        status: '',
        restro_id: '',
        name: '',
    });
    const [loading, setloading] = useState(false);
    const [rest, setRest] = useState([]);

    const { full_name, email, password, mobile, status, restro_id ,name} = cat;
    var userInfo = {};

    const [isBusy, setBusy] = useState(false);

    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    useEffect(() => {
        userInfo = JSON.parse(localStorage.getItem("user"));
        if (Object.keys(userInfo).length > 0 && userInfo.restro_id != 0) {
            setBusy(true);
        }
        editcategory();
        getRestaurentsDropDown();
    }, []);


    const getRestaurentsDropDown = async () => {
        await axios.post(endpoints.getRestaurentsDropDown).then((response) => {
            setRest(response.data.data);
        });
    };
    var userInfo = {};

    const editcategory = async () => {
        userInfo = JSON.parse(localStorage.getItem("user"));
        var restroIds = userInfo && userInfo.restro_id;
        await axios.post(endpoints.editUsers, { id: id }).then((response) => {
            setCat({
                id: response.data.data.id,
                full_name: response.data.data.full_name,
                status: response.data.data.status,
                mobile: response.data.data.mobile,
                restro_id: response.data.data.restro_id,
                email: response.data.data.email,
                name:response.data.data.name
            });
            // console.log('setcat',response)

        });
    }



    const onSubmit = async e => {
        var userInfos = JSON.parse(localStorage.getItem("user"));
        setloading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append("full_name", full_name);
        formData.append("email", email);
        formData.append("password", password ? password : '');
        formData.append("mobile", mobile);
        formData.append("restro_id", userInfos.restro_id != 0 ? userInfos.restro_id : restro_id);
        formData.append("status", status);

        formData.append("id", id);
        await axios.post(endpoints.updateUsers, formData).then((response) => {
            setloading(false);
            history.goBack();
        });
    }

    return (
        <div className="ui main">
            <PageTitle title="Edit User" linkurl="" linkTitle="" />
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <div className="row">
                        {!isBusy && <div className="col col-6">
                            <label>Restaurents </label>
                            <select name="restro_id" required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="{restro_id}">{name}</option>
                                {rest.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                        </div>}
                        <Input id="name" label="Full Name" type="text" name="full_name" place="Full Name" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={full_name} class="col col-6"
                        />

                    </div>

                    <div className="row">

                        <Input id="email" label="Email" type="text" name="email" place="Email" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={email} class="col col-6"
                        />
                        
                        <Input id="password" label="Password" type="text" name="password" place="Password" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={password} class="col col-6"
                        />


                    </div>

                    <div className="row">

                        <Input id="mobile" label="Mobile" type="text" name="mobile" place="Mobile" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={mobile} class="col col-6"
                        />

                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" className="form-control" onChange={e => onInputChange(e)} value={status} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                {/* <Button type="submit" variant="contained" size="large">
                                    Save
                                </Button> */}
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div>

                    </div>

                </form>


            </Card>

        </div>
    );

}

export default Edituser;
