import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { endpoints } from "../../../services/apiurl";
import Input from "../../UI/Input/Input";
import TextAreaInput from "../../UI/Input/TextAreaInput";
import Card from "@mui/material/Card";

const EditDigitalMenu = () => {
    let history = useHistory();
    let { id } = useParams();

    const [cat, setCat] = useState({
        dish_id: '',
        category_id: '',
        description: '',
        restro_id: '',
        status: '',
    });

    const [catgory, setCategory] = useState([]);
    const [dish, setDish] = useState([]);
    const [rest, setRest] = useState([]);

    const { dish_id, category_id, description, restro_id, status } = cat;

    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    useEffect(() => {
        getDopdownCategory();
        getDishDropdown();
        getRestaurentsDropDown();
        edit();
    }, []);

    const getDopdownCategory = async () => {
        await axios.post(endpoints.getDopdownCategory).then((response) => {
            setCategory(response.data.data);
        });
    }

    const getDishDropdown = async () => {
        await axios.post(endpoints.getDishDropdown).then((response) => {
            setDish(response.data.data);
        });
    };


    const getRestaurentsDropDown = async () => {
        await axios.post(endpoints.getRestaurentsDropDown).then((response) => {
            setRest(response.data.data);
        });
    };

    const edit = async () => {
        await axios.post(endpoints.editRestaurentsDigitalMenu, { id: id }).then((response) => {
            setCat({
                id: response.data.data.id,
                category_id: response.data.data.category_id,
                dish_id: response.data.data.dish_id,
                restro_id: response.data.data.restro_id,
                status: response.data.data.status,
                description: response.data.data.description
            });
        });
    }

    const onSubmit = async e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("category_id", category_id);
        formData.append("dish_id", dish_id);
        formData.append("restro_id", restro_id);
        formData.append("description", description);
        formData.append("status", status);
        formData.append("id", id);
        await axios.post(endpoints.updateRestaurentsDigitalMenu, formData).then((response) => {
            history.goBack();
            // if (response.data.status == 200) {
            //     // toast.success(response.data.message);
            //     history.goBack();
            // } else {
            //     // toast.error(response.data.message);
            // }
        });
    }

    return (
        <div className="ui main">
            <h2>Edit Digital Menu</h2>
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <div className="row">
                        <div className="col col-6">
                            <label>Category </label>
                            <select name="category_id" value={category_id} required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Category</option>
                                {catgory.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                        </div>
                        <div className="col col-6">
                            <label>Dish</label>
                            <select name="dish_id" value={dish_id} required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Dish</option>
                                {dish.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <label>Restaurents </label>
                            <select name="restro_id" value={restro_id} required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Restaurents</option>
                                {rest.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                        </div>
                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" value={status} required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>
                    </div>

                    <TextAreaInput id="description" label="Description" name="description" place="Description" onChange={(e) => onInputChange(e)}
                        requiredInput="required" value={description} class="col col-12" />


                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                <Button type="submit" variant="contained" size="small">
                                    Save
                                </Button>
                            </div></div></div>


                </form>
            </Card>

        </div>
    );

}

export default EditDigitalMenu;
