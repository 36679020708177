import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { Button, Card } from '@mui/material';
import FileUploader from "../../FileUploader";

import { endpoints } from "../../../services/apiurl";

import Input from "../../UI/Input/Input";
import PageTitle from "../../UI/PageTitle";


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';


const AddSlider = () => {
    let history = useHistory();
    const [cat, setCat] = useState({
        status: '',
        heading: '',
        subheading: ''
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setloading] = useState(false);

    const { status, heading, subheading } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        if (!selectedFile) {
            alert('Please select image');
            return false;
        }
        const formData = new FormData();
        formData.append("heading", heading);
        formData.append("subheading", subheading);
        formData.append("image", selectedFile);
        formData.append("status", status);
        await axios.post(endpoints.addSlider, formData).then((response) => {
            setloading(false);
            history.goBack();
        });
    }
    return (
        <div className="ui main">
            <PageTitle title="Add Slider" linkurl="" linkTitle="" />

            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <div className="row">
                        <Input id="heading" label="Heading" type="text" name="heading" place="Heading" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={heading} class="col col-6"
                        />

                        <Input id="subheading" label="Sub Heading" type="text" name="subheading" place="Sub Heading" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={subheading} class="col-6"
                        />

                    </div>
                    <div className="row">
                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col col-6">
                            <label>Image</label>
                            <FileUploader
                                onFileSelectSuccess={(file) => setSelectedFile(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div></div>


                </form>
            </Card>
        </div>
    );

}

export default AddSlider;


