import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { Button, Card } from '@mui/material';
import FileUploader from "../../FileUploader";

import { endpoints } from "../../../services/apiurl";

import Input from "../../UI/Input/Input";
import PageTitle from "../../UI/PageTitle";


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';


const AddRange = () => {
    let history = useHistory();
    const [cat, setCat] = useState({
        start_price: '',
        end_price: '',
        discount: '',
    });
    const [loading, setloading] = useState(false);
    const { start_price, end_price, discount } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append("start_price", start_price);
        formData.append("end_price", end_price);
        formData.append("discount", discount);
        await axios.post(endpoints.addPrice, formData).then((response) => {
            setloading(false);
            history.goBack();
        });
    }
    return (
        <div className="ui main">
            <PageTitle title="Add Range" linkurl="" linkTitle="" />

            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">

                    <div className="row">
                        <Input id="Start_Price" label="Start Price" type="text" name="start_price" place="Start Price" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={start_price} class="col col-6"
                        />

                        <Input id="end_price" label="Start Price" type="text" name="end_price" place="Start Price" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={end_price} class="col col-6"
                        />
                    </div>
                    <div className="row">
                        <Input id="Discount" label="Discount" type="text" name="discount" place="Discount" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={discount} class="col col-6"
                        />
                    </div>
                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div></div>


                </form>
            </Card>
        </div>
    );

}

export default AddRange;


