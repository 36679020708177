import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { Button, Card } from '@mui/material';
import FileUploader from "../../../FileUploader";

import { endpoints } from "../../../../services/apiurl";

import Input from "../../../UI/Input/Input";
import PageTitle from "../../../UI/PageTitle";


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const AddSubCategory = () => {
    let history = useHistory();
    let { cat_id } = useParams();

    const [cat, setCat] = useState({
        name: '',
        // icon: '',
        status: ''
    });
    const { name, status } = cat;
    const [loading, setloading] = useState(false);

    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", name);
        formData.append("status", status);
        formData.append("cat_id", cat_id);
        // console.log('ca', formData);
        await axios.post(endpoints.addSubCategory, formData).then((response) => {
            setloading(false);
            history.goBack();
            // if (response.data.status == 200) {
            //     // toast.success(response.data.message);
            //     history.goBack();
            // } else {
            //     // toast.error(response.data.message);
            // }
        });
    }
    return (
        <div className="ui main">
            <PageTitle title="Add Sub Category" linkurl="" linkTitle="" />

            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">

                    <div className="row">
                        <Input id="name" label="Name" type="text" name="name" place="Name" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={name} class="col col-6"
                        />
                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                {/* <Button type="submit" variant="contained" size="medium">
                                    Save
                                </Button> */}
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div></div>


                </form>
            </Card>
        </div>
    );

}

export default AddSubCategory;