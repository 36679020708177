/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React , {useRef} from 'react';
import '../../App.css';
import { MDBDataTable } from "mdbreact";
import { display } from '@mui/system';
import DataTable from "react-data-table-component";
import birthGif from '../../assets/gift.gif';
import anniversaryGif from '../../assets/wine.gif';
import Input from "../UI/Input/Input";
import axios from "axios";
import { endpoints } from "../../services/apiurl";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import { Box,Button,Card } from '@mui/material';
import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';
import { MonthPicker } from '@mui/lab';

class OrderLeads extends React.Component {
  // Constructor
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      DataisLoaded: false,
      start_date:'',
      end_date:'',
      type:'',
      currentPage: 1,
      totalPages: 1,
      total:'',
      per_page: 10,
    };
  }
 
  // ComponentDidMount is used to
  // execute the code
  // componentDidMount() {
  //   fetch("https://ngapi.firstfiddle.in/api/admin/getCustomerFeedback")
  //     .then((res) => res.json())
  //     .then((json) => {
  //       this.setState({
  //         items: json,
  //         DataisLoaded: true,
  //       });
  //       console.log(json)

  //     });
  // }
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let a  = localStorage.getItem('user')
    let b = JSON.parse(a)
    let user_id = b.id
    let restro_id = b.restro_id
    var page = this.state.currentPage;
    var per_page = this.state.per_page;
    var type = '2'
    axios
      .post(endpoints.getRegistrationData, {
        page,
        per_page,
        user_id,
        restro_id,
        type
        
      })
      .then((response) => {
        // window.location.reload(true);
        console.log("response", response);
        this.setState({
          items:response.data.data,
          total: response.data.total,
          totalPages:response.data.total_pages,
          DataisLoaded: true,
        });
        console.log(response.data);
      });
  }

  filterfetch(e) {
   
    console.log(e)
    
  }
  
  render() {


    const { DataisLoaded, items,total } = this.state;
    const a = this.state.items
    console.log(a)
    const b = this.state.total
    console.log(b)
    // const b = JSON.stringify(a)
    const dates = new Date();
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const month = months[dates.getMonth()] 
    const date = JSON.stringify(dates.getDate())
    const year = JSON.stringify(dates.getFullYear())
    const recent_date = month + '-' + date



    console.log('date', date);
    console.log('month', month);
    console.log('year', year);
    console.log('recent_date', recent_date);

    console.log(a, "items")
   
    const columns = [
      {
        name: "Name",
        selector: (row) => row.name,
        cellExport:(row) =>row.name,
        sortable: true 
      },
      {
        name: "Email",
        selector: row => row.email,
        cellExport:(row) =>row.email,
        sortable: true 
      },
      {
        name: "Mobile Number",
        selector: row => row.mobilenumber,
        cellExport:(row) =>row.mobilenumber,
        sortable: true 
      },
      {
        name: "Product Like",
        selector: row => row.product_like,
        cellExport:(row) =>row.product_like,
        sortable: true 
      },
      {
        name: "Restaurent",
        selector: (row) => row.rest_name,
        cellExport:(row) =>row.rest_name,
        sortable: true 
      }
    ];
   
    const onSubmit = async e => {
      this.filterfetch(e)
      // console.log(e)

     
  }
  const bFilter = async ()=>{
    // console.log(this.start_date);
    // var end_date = (this.end_date).substr(5,5)
    // var start_date = (this.start_date).substr(5,5)
    var end_date = this.end_date
    var start_date = this.start_date
    var type = this.type

    // var end_month = (this.end_date).substr(5,2)
    // var end_date = (this.end_date).substr(8,2)
    // console.log('start_month',start_month)
    // console.log('start_date',start_date)
    // console.log('end_month',end_month)
    // console.log('end_date',end_date)

    let a  = localStorage.getItem('user')
    let b = JSON.parse(a)
    let user_id = b.id
    let restro_id = b.restro_id

    axios
      .post(endpoints.menuFilter, {
        start_date,
        end_date,
        type,
        user_id,
        restro_id
        
      })
      .then((response) => {
        // window.location.reload(true);
        console.log("response", response);
        this.setState({
          items:response.data.data,
          // total: response.data.total,
          // totalPages:response.data.total_pages,
          DataisLoaded: true,
        });
        console.log(response.data);
      });

  }
  const onStartChange = e => {
    this.start_date = e.target.value
   
}
const onEndChange = e => {
  this.end_date = e.target.value
 
}
const onTypeChange = e => {
  this.type = e.target.value
 
}

    const data = a;
    const tableData = {
      columns,
      data,
    };
   
  
    if (!DataisLoaded)
      return (
        <div>
          <h4> Pleses wait some time.... </h4>{" "}
        </div>
      );

    return (
     <>
      <h2>Bentchair Order Leads</h2>
      {/* <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                  <div className='row'>
                  <div className='col-md-3'>
                    
                    <Input id="start_date" label="Start Date" type="date" name="start_date" place="Start Date" onChange={(e) => onStartChange(e)}
                        requiredInput="required"
                    />
                    </div>
                  <div className='col-md-3'>

                    <Input id="end_date" label="End Date" type="date" name="end_date" place="Start Date" onChange={(e) => onEndChange(e)}
                        requiredInput="required" className='col-md-6'
                    />

                    
                    </div>
                    <div className='col-md-3'>
                    <label>Filter By</label>
                            <select name="status" required className="form-control" onChange={e => onTypeChange(e)} >
                                <option value="">Select</option>
                                <option value="1">By Event Date</option>
                                <option value="2">By Created Date</option>
                            </select>
                    </div>

                    {this.restro_id === 0 ? <div className="col-md-3">
                    <label>Restaurant</label>
                            <select name="rest" required className="form-control" onChange={e => onRestChange(e)}>
                            <option value="">Select Restaurant</option>
                            {links.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                    </div> : ''}
                        <div className="col col-3">
                            <div className="mt-5 text-center">
                                <Button className='w-50' onClick={()=>bFilter()} variant="contained" size="medium">
                                    Filter 
                                </Button>
                            </div></div></div>


                </form>
      </Card> */}
      <div className='row mt-5'>
      

        {/* <MDBDataTable striped bordered hover className='col-md-12' data={basicData} /> */}
        <DataTableExtensions
      {...tableData}
    >
        <DataTable
          defaultSortField="id"
          pagination
          paginationPerPage={7}
          paginationRowsPerPageOptions={[7,14,21,28,35]}
         
        />
        
        </DataTableExtensions>
       
      </div>
      </>
    );
  }
}
export default OrderLeads;
