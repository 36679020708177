import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";

// import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import Form from "react-validation/build/form";

import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { login } from "../actions/auth";
import BgImage from "../assets/img/signin.svg";
import { Grid, Box } from "@mui/material";


const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};
const Login = (props) => {
    const form = useRef();
    const checkBtn = useRef();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const { isLoggedIn } = useSelector(state => state.auth);
    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };
    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };
    const handleLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            dispatch(login(username, password))
                .then(() => {
                    console.log('dd', localStorage.getItem("user"));
                    // if (localStorage.getItem("user")) {
                    //     return <Redirect to="/" />;
                    // }
                    // props.history.push("/profile");
                    // window.location.reload();
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    };

    // if (localStorage.getItem("user")) {
    //     return <Redirect to="/" />;
    // }
    if (isLoggedIn) {
        // console.log('isLoggedIn', localStorage.getItem("user"));
        return <Redirect to="dashboard/restaurentsdetail" />;
    } else {
        // console.log('isLoggedInOUT', localStorage.getItem("user"));
    }

    // return (
    //     <main>
    //         <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
    //             <Container>
    //                 <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
    //                     <Col xs={12} className="d-flex align-items-center justify-content-center">
    //                         <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
    //                             <div className="text-center text-md-center mb-4 mt-md-0">
    //                                 <h3 className="mb-0">Sign in to our platform</h3>
    //                             </div>
    //                             <Form className="mt-4">
    //                                 <Form.Group id="email" className="mb-4">
    //                                     <Form.Label>Your Email</Form.Label>
    //                                     <InputGroup>
    //                                         <InputGroup.Text>
    //                                             <FontAwesomeIcon icon={faEnvelope} />
    //                                         </InputGroup.Text>
    //                                         <Form.Control autoFocus required type="email" placeholder="example@company.com" />
    //                                     </InputGroup>
    //                                 </Form.Group>
    //                                 <Form.Group>
    //                                     <Form.Group id="password" className="mb-4">
    //                                         <Form.Label>Your Password</Form.Label>
    //                                         <InputGroup>
    //                                             <InputGroup.Text>
    //                                                 <FontAwesomeIcon icon={faUnlockAlt} />
    //                                             </InputGroup.Text>
    //                                             <Form.Control required type="password" placeholder="Password" />
    //                                         </InputGroup>
    //                                     </Form.Group>
    //                                     <div className="d-flex justify-content-between align-items-center mb-4">
    //                                         <Form.Check type="checkbox">
    //                                             <FormCheck.Input id="defaultCheck5" className="me-2" />
    //                                             <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
    //                                         </Form.Check>
    //                                         <Card.Link className="small text-end">Lost password?</Card.Link>
    //                                     </div>
    //                                 </Form.Group>
    //                                 <Button variant="primary" type="submit" className="w-100">
    //                                     Sign in
    //                                 </Button>
    //                             </Form>

    //                         </div>
    //                     </Col>
    //                 </Row>
    //             </Container>
    //         </section>
    //     </main>
    // );
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <div className="col-md-4 mx-auto">
                <div className="card card-container w-100">
                    <Form onSubmit={handleLogin} ref={form} className="w-100">
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="username"
                                value={username}
                                onChange={onChangeUsername}
                                validations={[required]}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <Input
                                type="password"
                                className="form-control"
                                name="password"
                                value={password}
                                onChange={onChangePassword}
                                validations={[required]}
                            />
                        </div>
                        <div className="form-group">
                            <button className="btn btn-primary btn-block" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Login</span>
                            </button>
                        </div>
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </div>
            </div>
        </Box>
    );
};
export default Login;