import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import { Button, Card } from '@mui/material';
import FileUploader from "../../FileUploader";

import { endpoints } from "../../../services/apiurl";
import Input from "../../UI/Input/Input";
import PageTitle from "../../UI/PageTitle";
import DeleteIcon from '@mui/icons-material/Delete';


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const Editamenities = () => {
    let history = useHistory();
    let { id } = useParams();

    const [cat, setCat] = useState({
        name: '',
        status: '',
        image: '',
        iconName: '',
        restro_id: '',
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setloading] = useState(false);
    const [rest, setRest] = useState([]);

    const { name, status, image, iconName, restro_id } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    useEffect(() => {
        editcategory();
        getRestaurentsDropDown();

    }, []);


    const getRestaurentsDropDown = async () => {
        await axios.post(endpoints.getRestaurentsDropDown).then((response) => {
            setRest(response.data.data);
        });
    };
  console.log(id)
    const editcategory = async () => {
        await axios.post(endpoints.editRestaurentsAmenities, { id: id }).then((response) => {
            setCat({
                id: response.data.data.id,
                name: response.data.data.name,
                status: response.data.data.status,
                image: response.data.data.image,
                restro_id: response.data.data.restro_id,
                iconName: response.data.data.iconName
            });
        });
    }

    const removeImageHandler = async (id) => {
        await axios.post(endpoints.deleteImageAmenitiesRest, { id }).then((response) => {
        });
        window.location.reload();
    };

    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", name);
        formData.append("restro_id", restro_id);
        formData.append("image", selectedFile);
        formData.append("status", status);
        formData.append("id", id);
        await axios.post(endpoints.updateRestaurentsAmenities, formData).then((response) => {
            setloading(false);
            history.goBack();
        });
    }

    return (
        <div className="ui main">
            <PageTitle title="Edit Amenities" linkurl="" linkTitle="" />
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <div className="row">
                        <div className="col col-6">
                            <label>Restaurents </label>
                            <select name="restro_id" required className="form-control" onChange={e => onInputChange(e)} value={restro_id}>
                                <option value="">Select Restaurents</option>
                                {rest.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                        </div>
                        <Input id="name" label="Name" type="text" name="name" place="Name" onChange={(e) => onInputChange(e)}
                            requiredInput="true" value={name} class="col col-6"
                        />


                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" className="form-control" onChange={e => onInputChange(e)} value={status} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <label>Image</label>
                            <FileUploader
                                onFileSelectSuccess={(file) => setSelectedFile(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />

                            {iconName ? (
                                <div className="mt-3">
                                    <img src={image} height="200px" width="200px" className="px-2" />
                                    <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id)}>
                                        Delete
                                    </Button>
                                </div>
                            ) : ''}
                        </div>

                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                {/* <Button type="submit" variant="contained" size="large">
                                    Save
                                </Button> */}
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div>

                    </div>

                </form>


            </Card>

        </div>
    );

}

export default Editamenities;
