import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Redirect, Switch, Route, Link } from "react-router-dom";
import { endpoints } from "../../services/apiurl";
import { List, arrayMove } from "react-movable";
import LinkButton from '../UI/Input/LinkButton';
import { Card } from '@mui/material';
import PageTitle from '../UI/PageTitle';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const OutletDetail = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        fetchData(1, perPage);
    }, [perPage])



    const columns = [
        {
            name: 'OUTLET NAME',
            selector: row => row.outlet_name,
            width: '500px'
        }, 
        // {
        //     name: 'STATUS',
        //     selector: row => row.status == 1 ? 'Active' : 'Inactive',
        //     width: '200px'
        // }, 
        {
            name: 'ACTION',
            cell: row => <div>  <Link to={`/dashboard/editoutlet/${row.id}`}> <EditIcon /> </Link> <Link className="trash alternate outline icon" style={{ color: "red", marginTop: "7px", marginLeft: "10px" }} onClick={() => removeContactHandler(row.id)}> <DeleteIcon /> </Link></div>,
            selector: row => row.id,
            width: '200px'
        },
    ];



    const removeContactHandler = async (id) => {
        await axios.post(endpoints.deleteOutlet, { id }).then((response) => {
        });
        const newContactList = items.filter((contact) => {
            return contact.id !== id;
        });
        setItems(newContactList);
    };


    const fetchData = async (page, per_page,c) => {
        let a  = localStorage.getItem('user')
        let b = JSON.parse(a)
        let user_id = b.id
        let restro_id = b.restro_id
        console.log('main', restro_id)
        await axios
            .post(endpoints.getOutlet, {
                page,
                per_page,
                restro_id,
                user_id
            })
            .then((response) => {
                setIsLoaded(true);
                setItems(response.data.data);
                setTotalRows(response.data.total);
            },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }

            );
    }

    const handlePageChange = page => {
        fetchData(page, perPage);
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }


    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <div className="App">
                <PageTitle title="Outlet List" linkurl="/dashboard/addoutlet" linkTitle="Add Outlet" />
                <Card className="px-3 py-3">
                    <DataTable
                        columns={columns}
                        data={items}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onchange={({ oldIndex, newIndex }) =>
                        setItems(arrayMove(items, oldIndex, newIndex))}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                    />
                    
                
                </Card>
            </div>

        );
    }
}

export default OutletDetail;