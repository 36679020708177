import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Redirect, Switch, Route, Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";

import { endpoints } from "../../../services/apiurl";

import LinkButton from '../../UI/Input/LinkButton';
import { Card, Button } from '@mui/material';
import PageTitle from '../../UI/PageTitle';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const MediaDetail = () => {
    let history = useHistory();
    let { restro_id } = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        fetchData(1, perPage);
    }, [perPage])



    const columns = [
        // {
        //     name: 'NAME',
        //     selector: row => row.name,
        // }, 
        {
            name: 'RESTAURANTS NAME',
            selector: row => row.restName,
        }, {
            name: 'MEDIA TYPE',
            selector: row => row.media_type == 1 ? <img src={`${row.media}`} style={{ height: "150px", width: "150px" , margin:'10px' }}></img> : 'Video',
            // width: '200px'
        }, {
            name: 'ACTION',
            cell: row => <div>  <Link to={`/dashboard/editmedia/${row.id}`}> <EditIcon /> </Link>
                <Link className="trash alternate outline icon" style={{ marginTop: "7px", marginLeft: "10px" }} onClick={() => removeContactHandler(row.id)}> <DeleteIcon /> </Link>
            </div>,
            selector: row => row.id,
            // width: '200px'
        },
    ];


    const removeContactHandler = async (id) => {
        await axios.post(endpoints.deleteRestaurentsMedia, { id }).then((response) => {
        });
        const newContactList = items.filter((contact) => {
            return contact.id !== id;
        });
        setItems(newContactList);
    };
 
   const fetchRestData= async ()=> {
        let a = localStorage.getItem('user')
        let b = JSON.parse(a)
        let user_id = b.id
        let restro_id = b.restro_id
        var page = this.state.currentPage;
        var per_page = this.state.per_page;
        axios
          .post(endpoints.getRestaurentsDropDown, {
    
    
          })
          .then((response) => {
            // window.location.reload(true);
            console.log("response", response);
            this.setState({
              restaurent: response.data.data,
              total: response.data.total,
              totalPages: response.data.total_pages,
              DataisLoaded: true,
            });
            // console.log(response.data);
    
    
          });
      }

    const fetchData = async (page, per_page)  => {
        let a  = localStorage.getItem('user')
        let b = JSON.parse(a)
        let user_id = b.id
        let restro_id = b.restro_id
        await axios
            .post(endpoints.getRestaurentsMedia, {
                page,
                per_page,
                user_id,
                restro_id
            })
            .then((response) => {
                setIsLoaded(true);
                setItems(response.data.data);
                console.log(response.data.data)
                setTotalRows(response.data.total);
            },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }

            );
    }

    const handlePageChange = page => {
        fetchData(page, perPage);
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }


    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (

            <div className="App">
                <PageTitle title="Gallery List" linkid={restro_id} linkurl="/dashboard/addmedia" linkTitle="Add Gallery" />
              
                <Card className="px-3 py-3">
                    <DataTable
                        columns={columns}
                        data={items}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                    />
                </Card>
            </div>


        );
    }
}

export default MediaDetail;