import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route, Link, Redirect } from "react-router-dom";
import "./App.css";
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import BoardUser from "./components/BoardUser";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { history } from "./helpers/history";

import SideBar from "./components/SideBar";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./components/Dashboard";

const App = () => {
  // const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  // const [showAdminBoard, setShowAdminBoard] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);
  // useEffect(() => {
  //   if (currentUser) {
  //     // setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
  //     // setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
  //   }
  // }, [currentUser]);
  const logOut = () => {
    dispatch(logout());
  };
  // return (
  //   <Router history={history}>
  //     <div>
  //       {currentUser && (
  //         <SideBar />)
  //       }
  //       {/* <nav className="navbar navbar-expand navbar-dark bg-dark">
  //         <Link to={"/"} className="navbar-brand">
  //           First Fiddle
  //         </Link>
  //         <div className="navbar-nav mr-auto">
  //           <li className="nav-item">
  //             <Link to={"/home"} className="nav-link">
  //               Home
  //             </Link>
  //           </li>

  //           {currentUser && (
  //             <li className="nav-item">
  //               <Link to={"/user"} className="nav-link">
  //                 User
  //               </Link>
  //             </li>
  //           )}
  //         </div>
  //         {currentUser ? (
  //           <div className="navbar-nav ml-auto">
  //             <li className="nav-item">
  //               <Link to={"/profile"} className="nav-link">
  //                 {currentUser.username}
  //               </Link>
  //             </li>
  //             <li className="nav-item">
  //               <a href="/login" className="nav-link" onClick={logOut}>
  //                 LogOut
  //               </a>
  //             </li>
  //           </div>
  //         ) : (
  //           <div className="navbar-nav ml-auto">
  //             <li className="nav-item">
  //               <Link to={"/login"} className="nav-link">
  //                 Login
  //               </Link>
  //             </li>
  //             <li className="nav-item">
  //               <Link to={"/register"} className="nav-link">
  //                 Sign Up
  //               </Link>
  //             </li>
  //           </div>
  //         )}
  //       </nav> */}






  //       <div className="container mt-3">
  //         <Switch>
  //           {/* <Route exact path={["/", "/home"]} component={Home} />  */}
  //           <Route exact path="/login" component={Login} />
  //           <Route exact path="/home" component={Home} />
  //           {/* <Route exact path={["/", "/login"]} component={Login} /> */}
  //           <Route exact path="/register" component={Register} />
  //           <Route exact path="/profile" component={Profile} />
  //           <Route path={["/", "/user"]} component={BoardUser} />
  //           <Route path="/mod" component={BoardModerator} />
  //           <Route path="/admin" component={BoardAdmin} />
  //         </Switch>
  //       </div>
  //     </div>
  //   </Router>
  // );
  return (
    <Router history={history}>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <ProtectedRoute path="/dashboard">
          <Dashboard />
        </ProtectedRoute>
        <Route exact path="/">
          <Redirect exact from="/" to="dashboard" />
        </Route>
        <Route path="*">
          <Redirect from="/" to="dashboard" />
        </Route>
      </Switch>
    </Router>
  );

};
export default App;