import React, { useRef } from 'react';
import { Button } from '@mui/material';
const FileUploader = (props) => {
    const fileInput = useRef(null)

    const handleFileInput = (e) => {
        // handle validations
        const file = e.target.files[0];
        // if (file.size > 1024)
        //     props.onFileSelectError({ error: "File size cannot exceed more than 1MB" });
        // else 
        props.onFileSelectSuccess(file);
    };

    return (
        <div className="file-uploader">
            <Button variant="contained" component="label" size="small">
                Upload
                <input hidden accept="image/*" multiple type="file" onChange={handleFileInput} />
            </Button>
            {/* <input type="file" multiple onChange={handleFileInput} /> */}
            {/* <button onClick={e => fileInput.current && fileInput.current.click()} className="" /> */}
        </div>
    );
}

export default FileUploader;
