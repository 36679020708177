// const API_URL = "http://localhost:8080/api/admin";
const API_URL = "https://api.foodbusofindia.com/api/admin"
// const API_URL = "https://ngapi.firstfiddle.in/api/admin";

const endpoints = {
    API_URL: API_URL,
    apitoken: "",

    login: API_URL + "/login",

    appUserFilter: API_URL + "/appUserFilter",
    dishFilter: API_URL + "/dishFilter",
    categoryFilter: API_URL + "/categoryFilter",
    getAppUser: API_URL + "/getAppUser",
    menuFilter: API_URL + "/menuFilter",
    getOutlet: API_URL + "/getOutlet",
    editOutlet: API_URL + "/editOutlet",
    deleteOutlet: API_URL + "/deleteOutlet",
    addOutlet: API_URL + "/addOutlet",
    updateOutlet: API_URL + "/updateOutlet",

    birthdayFilter: API_URL + "/birthdayFilter",
    app_webRestStatus: API_URL + "/app_webRestStatus",
    app_webSecRestStatus: API_URL + "/app_webSecRestStatus",
    reservationFilter: API_URL + "/reservationFilter",
    eventFilter: API_URL + "/eventFilter",


    getBooking: API_URL + "/getBooking",
    getRegistrationData: API_URL + "/getRegistrationData",
    getCustomerFeedback: API_URL + "/getCustomerFeedback",


    getBillScanReport: API_URL + "/getBillScanReport",
    editBill: API_URL + "/editBill",
    updateBill: API_URL + "/updateBill",

    getCategory: API_URL + "/getCategory",
    addCategory: API_URL + "/addCategory/",
    updateCategory: API_URL + "/updateCategory",
    deleteCategory: API_URL + "/deleteCategory/",
    editCategory: API_URL + "/editCategory/",
    deleteImageCat: API_URL + "/deleteImageCat/",

    getSlider: API_URL + "/getSlider",
    addSlider: API_URL + "/addSlider/",
    updateSlider: API_URL + "/updateSlider",
    deleteSlider: API_URL + "/deleteSlider/",
    editSlider: API_URL + "/editSlider/",
    deleteSliderImage: API_URL + "/deleteSliderImage/",


    getHomeSlider: API_URL + "/getHomeSlider",
    addHomeSlider: API_URL + "/addHomeSlider/",
    updateHomeSlider: API_URL + "/updateHomeSlider",
    deleteHomeSlider: API_URL + "/deleteHomeSlider/",
    editHomeSlider: API_URL + "/editHomeSlider/",
    deleteHomeSliderImage: API_URL + "/deleteHomeSliderImage/",


    getDish: API_URL + "/getDish",
    addDish: API_URL + "/addDish/",
    updateDish: API_URL + "/updateDish",
    deleteDish: API_URL + "/deleteDish/",
    editDish: API_URL + "/editDish/",
    deleteImageDish: API_URL + "/deleteImageDish/",



    getRestaurentsAmenities: API_URL + "/getRestaurentsAmenities",
    addRestaurentsAmenities: API_URL + "/addRestaurentsAmenities/",
    updateRestaurentsAmenities: API_URL + "/updateRestaurentsAmenities",
    deleteRestaurentsAmenities: API_URL + "/deleteRestaurentsAmenities/",
    editRestaurentsAmenities: API_URL + "/editRestaurentsAmenities/",
    //   deleteImageDish: API_URL + "/deleteImageDish/",


    getRestaurentsDigitalMenu: API_URL + "/getRestaurentsDigitalMenu",
    addRestaurentsDigitalMenu: API_URL + "/addRestaurentsDigitalMenu/",
    updateRestaurentsDigitalMenu: API_URL + "/updateRestaurentsDigitalMenu",
    deleteRestaurentsDigitalMenu: API_URL + "/deleteRestaurentsDigitalMenu/",
    editRestaurentsDigitalMenu: API_URL + "/editRestaurentsDigitalMenu/",

    getTags: API_URL + "/getTags",
    addTag: API_URL + "/addTag/",
    updateTag: API_URL + "/updateTag",
    deleteTag: API_URL + "/deleteTag/",
    editTags: API_URL + "/editTags/",
    getDopdownTags: API_URL + "/getDopdownTags/",

    getTagsByDishID: API_URL + "/getTagsByDishID/",
    updateTagBYDishID: API_URL + "/updateTagBYDishID/",

    getDopdownCategory: API_URL + "/getDopdownCategory/",
    getDishDropdown: API_URL + "/getDishDropdown/",
    getRestaurentsDropDown: API_URL + "/getRestaurentsDropDown",


    getRestaurents: API_URL + "/getRestaurents/",
    addRestaurents: API_URL + "/addRestaurents/",
    updateRestaurents: API_URL + "/updateRestaurents/",
    deleteRestaurents: API_URL + "/deleteRestaurents/",
    editRestaurents: API_URL + "/editRestaurents/",
    deleteImageRest: API_URL + "/deleteImageRest/",
    deleteImagePoint: API_URL + "/deleteImagePoint/",


    getRestaurentsAmenities: API_URL + "/getRestaurentsAmenities/",
    addRestaurentsAmenities: API_URL + "/addRestaurentsAmenities/",
    updateRestaurentsAmenities: API_URL + "/updateRestaurentsAmenities/",
    deleteRestaurentsAmenities: API_URL + "/deleteRestaurentsAmenities/",
    editRestaurentsAmenities: API_URL + "/editRestaurentsAmenities/",
    deleteImageAmenitiesRest: API_URL + "/deleteImageAmenitiesRest/",


    getRestaurentsDeals: API_URL + "/getRestaurentsDeals/",
    addRestaurentsDeals: API_URL + "/addRestaurentsDeals/",
    updateRestaurentsDeals: API_URL + "/updateRestaurentsDeals/",
    deleteRestaurentsDeals: API_URL + "/deleteRestaurentsDeals/",
    editRestaurentsDeals: API_URL + "/editRestaurentsDeals/",
    deleteImageDealRest: API_URL + "/deleteImageDealRest/",

    getRestaurentsMedia: API_URL + "/getRestaurentsMedia/",
    addRestaurentsMedia: API_URL + "/addRestaurentsMedia/",
    updateRestaurentsMedia: API_URL + "/updateRestaurentsMedia/",
    deleteRestaurentsMedia: API_URL + "/deleteRestaurentsMedia/",
    editRestaurentsMedia: API_URL + "/editRestaurentsMedia/",
    deleteImageMediaRest: API_URL + "/deleteImageMediaRest/",

    getRestaurentsMenu: API_URL + "/getRestaurentsMenu/",
    addRestaurentsMenu: API_URL + "/addRestaurentsMenu/",
    updateRestaurentsMenu: API_URL + "/updateRestaurentsMenu/",
    deleteRestaurentsMenu: API_URL + "/deleteRestaurentsMenu/",
    editRestaurentsMenu: API_URL + "/editRestaurentsMenu/",
    deleteImageMenuRest: API_URL + "/deleteImageMenuRest/",

    getSubCategory: API_URL + "/getSubCategory",
    addSubCategory: API_URL + "/addSubCategory/",
    updateSubCategory: API_URL + "/updateSubCategory",
    deleteSubCategory: API_URL + "/deleteSubCategory/",
    editSubCategory: API_URL + "/editSubCategory/",
    getDopdownSubCategory: API_URL + "/getDopdownSubCategory/",

    getTagsByTagType: API_URL + "/getTagsByTagType/",
    getMenuType: API_URL + "/getMenuType/",

    getSection: API_URL + "/getSection/",
    addSection: API_URL + "/addSection/",
    updateSection: API_URL + "/updateSection/",
    deleteSection: API_URL + "/deleteSection/",
    editSections: API_URL + "/editSections/",
    deleteSectionImage: API_URL + "/deleteSectionImage/",

    getEvents: API_URL + "/getEvents/",
    addEvent: API_URL + "/addEvent/",
    updateEvents: API_URL + "/updateEvents/",
    deleteEvents: API_URL + "/deleteEvents/",
    editEvents: API_URL + "/editEvents/",
    deleteImageEvent: API_URL + "/deleteImageEvent/",


    getPoints: API_URL + "/getPoints/",
    addPoint: API_URL + "/addPoint/",
    updatePoint: API_URL + "/updatePoint/",
    deletePoints: API_URL + "/deletePoints/",
    editPoints: API_URL + "/editPoints/",

    getPriceRange: API_URL + "/getPriceRange/",
    addPrice: API_URL + "/addPrice/",
    updatePrice: API_URL + "/updatePrice/",
    deletePrice: API_URL + "/deletePrice/",
    editPrice: API_URL + "/editPrice/",
    getPriceRangeDropDown: API_URL + "/getPriceRangeDropDown/",


    addUser: API_URL + "/addUser/",
    getUsers: API_URL + "/getUsers/",
    editUsers: API_URL + "/editUsers/",
    updateUsers: API_URL + "/updateUsers/",
    deleteUsers: API_URL + "/deleteUsers/",


    catupdateSorting: API_URL + "/catupdateSorting/",
    subcatupdateSorting: API_URL + "/subcatupdateSorting/",
    dishcatupdateSorting: API_URL + "/dishcatupdateSorting/",
    updateSectionSorting: API_URL + "/updateSectionSorting/",
    updateDishStatus: API_URL + "/updateDishStatus/",
    updateCatStatus: API_URL + "/updateCatStatus/",
    updateSubCatStatus: API_URL + "/updateSubCatStatus/",


};
export { endpoints };