import React from "react";


const Input = props => {

    return <>
        <div className={props.class}>
            <label>{props.label}</label>
            <input
                type={props.type}
                name={props.name}
                className="form-control"
                placeholder={props.place}
                onChange={props.onChange}
                value={props.value}
                required={props.requiredInput}
            />
        </div>
    </>


}

export default Input;