import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { Button, Card } from '@mui/material';
import FileUploader from "../FileUploader";

import { endpoints } from "../../services/apiurl";

import Input from "../UI/Input/Input";
import PageTitle from "../UI/PageTitle";


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const Adduser = () => {
    let history = useHistory();
    const [cat, setCat] = useState({
        full_name: '',
        email: '',
        password: '',
        mobile: '',
        status: '',
        restro_id: '',
    });
    const [loading, setloading] = useState(false);
    const [rest, setRest] = useState([]);
    var userInfo = {};
    const { full_name, email, password, mobile, status, restro_id } = cat;
    const [isBusy, setBusy] = useState(false);
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        // console.log('startDate', moment(startDate).format("YY/MM/DDHH:mm:ss"));
    }
    useEffect(() => {
        userInfo = JSON.parse(localStorage.getItem("user"));
        if (Object.keys(userInfo).length > 0 && userInfo.restro_id != 0) {
            setBusy(true);
        }
        getRestaurentsDropDown();
    }, []);
    const getRestaurentsDropDown = async () => {
        await axios.post(endpoints.getRestaurentsDropDown).then((response) => {
            setRest(response.data.data);
            // console.log(response.data.data);
        });
    };
    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        var userInfos = JSON.parse(localStorage.getItem("user"));
        const formData = new FormData();
        formData.append("full_name", full_name);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("mobile", mobile);
        formData.append("restro_id", userInfos.restro_id != 0 ? userInfos.restro_id : restro_id);
        formData.append("status", status);
        await axios.post(endpoints.addUser, formData).then((response) => {
            setloading(false);
            history.goBack();
        });
    }
    return (

        <div className="ui main">
            <PageTitle title="Add User" linkurl="" linkTitle="" />

            <Card className="px-3 pb-10">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <div className="row">
                        {!isBusy && <div className="col col-6">
                            <label>Restaurents </label>
                            <select name="restro_id" required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Restaurents</option>
                                {rest.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                        </div>} 


                        <Input id="name" label="Full Name" type="text" name="full_name" place="Full Name" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={full_name} class="col col-6"
                        />


                    </div>

                    <div className="row">

                        <Input id="email" label="Email" type="text" name="email" place="Email" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={email} class="col col-6"
                        />

                        <Input id="password" label="Password" type="text" name="password" place="Password" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={password} class="col col-6"
                        />


                    </div>

                    <div className="row">

                        <Input id="mobile" label="Mobile" type="text" name="mobile" place="Mobile" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={mobile} class="col col-6"
                        />
                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>


                    </div>




                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div></div>


                </form>
            </Card>
        </div>

    );

}

export default Adduser;


