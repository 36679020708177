/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React , {useRef} from 'react';
import '../../App.css';
import { MDBDataTable } from "mdbreact";
import { display } from '@mui/system';
import DataTable from "react-data-table-component";
import birthGif from '../../assets/gift.gif';
import anniversaryGif from '../../assets/wine.gif';
import Input from "../UI/Input/Input";
import axios from "axios";
import { endpoints } from "../../services/apiurl";

import { withRouter } from "react-router";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import { Box,Button,Card } from '@mui/material';
import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';
import { MonthPicker } from '@mui/lab';

class ReservationLeads extends React.Component {
  // Constructor
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      items: [],
      DataisLoaded: false,
      restaurent:[],
      restaurent_outlet:[],
      restro_id : '',
      start_date:'',
      rest:'',
      end_date:'',
      type:'',
      currentPage: 1,
      totalPages: 1,
      total:'',
      per_page: 10,
    };
  }
 
  // ComponentDidMount is used to
  // execute the code
  // componentDidMount() {
  //   fetch("https://ngapi.firstfiddle.in/api/admin/getCustomerFeedback")
  //     .then((res) => res.json())
  //     .then((json) => {
  //       this.setState({
  //         items: json,
  //         DataisLoaded: true,
  //       });
  //       console.log(json)

  //     });
  // }
  componentDidMount() {
    this.fetchData();
    this.fetchRestData();
    this.fetchOutletData();
  }


  fetchData() {
    let a  = localStorage.getItem('user')
    let b = JSON.parse(a)
    let user_id = b.id
    let type = '2'
    let restro_id = b.restro_id
    var page = this.state.currentPage;
    var per_page = this.state.per_page;
    axios
      .post(endpoints.getBooking, {
       restro_id,type
        
      })
      .then((response) => {
        // window.location.reload(true);
        console.log("response", response);
        this.setState({
          items:response.data.data,
          total: response.data.total,
          totalPages:response.data.total_pages,
          DataisLoaded: true,
        });
        console.log(response.data.data);
      });
  }
  fetchRestData() {
    let a  = localStorage.getItem('user')
    let b = JSON.parse(a)
    let user_id = b.id
    let restro_id = b.restro_id
    var page = this.state.currentPage;
    var per_page = this.state.per_page;
    axios
      .post(endpoints.getRestaurentsDropDown, {
                
        
      })
      .then((response) => {
        // window.location.reload(true);
        console.log("response", response);
        this.setState({
          restaurent:response.data.data,
          total: response.data.total,
          totalPages:response.data.total_pages,
          DataisLoaded: true,
        });
        // console.log(response.data);
        

      });
  }

  fetchOutletData() {
    let a  = localStorage.getItem('user')
    let b = JSON.parse(a)
    let user_id = b.id
    let restro_id = b.restro_id
    console.log(restro_id)
    var page = this.state.currentPage;
    var per_page = this.state.per_page;
    axios
      .post(endpoints.getOutlet, {
                
        restro_id,user_id
      })
      .then((response) => {
        // window.location.reload(true);
        console.log("response", response);
        this.setState({
          restaurent_outlet:response.data.data,
          total: response.data.total,
          totalPages:response.data.total_pages,
          DataisLoaded: true,
        });
        console.log('outlet ',response.data);
        

      });
  }

  filterfetch(e) {
   
    console.log(e)
    
  }
  
  render() {


    const { DataisLoaded, items,total } = this.state;
    const a = this.state.items
   
    const b = this.state.total
   
    const links = (this.state.restaurent);
    const outlets = (this.state.restaurent_outlet);
    const user  = localStorage.getItem('user')
    const user_data = JSON.parse(user)
    this.restro_id = user_data.restro_id
// console.log(outlets)

    // const b = JSON.stringify(a)
    const dates = new Date();
    const month = JSON.stringify(dates.getMonth() + 1)
    const date = JSON.stringify(dates.getDate())
    const year = JSON.stringify(dates.getFullYear())
    const recent_date = month + '-' + date


    console.log(a, "items")
    
  
   
    const columns = [
      {
    
        selector: (row) => row.full_name,
        cellExport:(row) =>row.full_name,
        sortable: true 
      },
      {
        
        selector: row => row.email,
        cellExport:(row) =>row.email,
        omit:true,
        sortable: true 
      },
      {
     
        selector: (row) => row.phone_no,
        cellExport:(row) =>row.phone_no,
        sortable: true 
      },
      {
       
        selector: row => row.no_persons,
        cellExport:(row) =>row.no_persons,
        sortable: true ,
       
      },
      {
        
        selector: (row) => row.booking_date,
        cellExport:(row) =>row.booking_date,
        sortable: true 
      },
      {
       
        selector: row => row.booking_time,
        cellExport:(row) =>row.booking_time,
        sortable: true 
      },
      
      {
       
        selector: (row) => row.restro_name,
        cellExport:(row) =>row.restro_name,
        sortable: true 
      },
      {
        selector: (row) => row.outlet_name,
        cellExport:(row) => row.outlet_name,
        sortable: true 
      },
      {
        selector: (row) => row.source,
        cellExport:(row) => row.source,
        sortable: true,
        omit:true
      }
      
    ];
   
    const onSubmit = async e => {
      this.filterfetch(e)
      // console.log(e)

     
  }
  const bFilter = async ()=>{
    // console.log(this.start_date);
    var end_date = this.end_date
    var start_date = this.start_date
    if(this.rest===undefined){
      var rest = ''
    }
    else{
      var rest = this.rest
    }
    
    console.log('working_rest',rest)
    var type = this.type


    let a  = localStorage.getItem('user')
    let b = JSON.parse(a)
    let user_id = b.id
    let restro_id = b.restro_id

    axios
      .post(endpoints.reservationFilter, {
        start_date,
        end_date,
        rest,
        type,
        user_id,
        restro_id
        
      })
      .then((response) => {
        // window.location.reload(true);
        console.log("response", response);
        this.setState({
          items:response.data.data,
          // total: response.data.total,
          // totalPages:response.data.total_pages,
          DataisLoaded: true,
        });
        console.log(response.data);
      });

  }
  const onStartChange = e => {
    this.start_date = e.target.value
   
}
const onEndChange = e => {
  this.end_date = e.target.value
 
}
const onTypeChange = e => {
  this.type = e.target.value
 
}

const onRestChange = e => {
  this.rest = e.target.value
 
}
  const c = [
    {
      booking_date: "BOOKING DATE",
      booking_time: "BOOKING TIME",
      created_at:"CREATED AT",
      email:"EMAIL",
      full_name:"NAME",
      no_persons: "PERSON",
      phone_no: "NUMBER",
      outlet_name:"OUTLET NAME",
      restro_name: 'RESTAURANT',
      source:"SOURCE"
    }
  ];
  // const data = heading.concat(c);
  const data = c.concat(a);
    const tableData = {

      columns,
      data,
    };
   
   
    if (!DataisLoaded)
      return (
        <div>
          <h4> Pleses wait some time.... </h4>{" "}
        </div>
      );

    return (
     <>
      <h2>Reservation Leads</h2>
      <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                  <div className='row'>
                  <div className='col-md-3'>
                    
                    <Input id="start_date" label="Start Date" type="date" name="start_date" place="Start Date" onChange={(e) => onStartChange(e)}
                        requiredInput="required"
                    />
                    </div>
                  <div className='col-md-3'>

                    <Input id="end_date" label="End Date" type="date" name="end_date" place="Start Date" onChange={(e) => onEndChange(e)}
                        requiredInput="required" className='col-md-6'
                    />

                    
                    </div>
                    <div className='col-md-3'>
                    <label>Filter By</label>
                            <select name="status" required className="form-control" onChange={e => onTypeChange(e)} >
                                <option value="">Select</option>
                                <option value="1">By Booking Date</option>
                                <option value="2">By Created Date</option>
                            </select>
                    </div>

                    {this.restro_id === 0 ? <div className="col-md-3">
                    <label>Restaurant</label>
                            <select name="rest" required className="form-control" onChange={e => onRestChange(e)}>
                            <option value="">Select Restaurant</option>
                            {links.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            {/* {links.map((item, i) => (
                            <option value={item.id}>{item.name}</option>))}
                               */}

                            </select>
                    </div> : ''}
                        <div className="col col-3">
                            <div className="mt-5 text-center">
                                <Button className='w-50' onClick={()=>bFilter()} variant="contained" size="medium">
                                    Filter
                                </Button>
                            </div></div></div>


                </form>
      </Card>
      <div className='row mt-5'>
      

        {/* <MDBDataTable striped bordered hover className='col-md-12' data={basicData} /> */}
        <DataTableExtensions
      {...tableData}
    >
        <DataTable
          defaultSortField="id"
          pagination
          paginationPerPage={7}
          paginationRowsPerPageOptions={[7,14,21,28,35]}
        />
        
        </DataTableExtensions>
       
      </div>
      </>
    );
  }
}
export default withRouter(ReservationLeads);
