import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import { Button, Card } from '@mui/material';
import FileUploader from "../../FileUploader";

import { endpoints } from "../../../services/apiurl";
import Input from "../../UI/Input/Input";
import PageTitle from "../../UI/PageTitle";
import DeleteIcon from '@mui/icons-material/Delete';


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const EditMedia = () => {
    let history = useHistory();
    let { id } = useParams();

    const [cat, setCat] = useState({
        media_type: '',
        restro_id: '',
        video_link: '',
        image: '',
        iconName: '',

    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setloading] = useState(false);
    const [rest, setRest] = useState([]);

    const { media_type, image, iconName, video_link, restro_id } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    useEffect(() => {
        editcategory();
        getRestaurentsDropDown();

    }, []);


    const getRestaurentsDropDown = async () => {
        await axios.post(endpoints.getRestaurentsDropDown).then((response) => {
            setRest(response.data.data);
        });
    };

    const editcategory = async () => {
        await axios.post(endpoints.editRestaurentsMedia, { id: id }).then((response) => {
            setCat({
                id: response.data.data.id,
                media_type: response.data.data.media_type,
                image: response.data.data.media,
                video_link: response.data.data.iconName,
                restro_id: response.data.data.restro_id,
                iconName: response.data.data.iconName
            });
        });
    }

    const removeImageHandler = async (id) => {
        await axios.post(endpoints.deleteImageMediaRest, { id }).then((response) => {
        });
        window.location.reload();
    };

    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        // if (media_type == 1) {
        //     if (!selectedFile) {
        //         alert('Please select image');
        //         return false;
        //     }
        // }

        const formData = new FormData();
        formData.append("video_link", video_link);
        formData.append("media_type", media_type);
        formData.append("restro_id", restro_id);
        formData.append("media", selectedFile);
        formData.append("id", id);
        await axios.post(endpoints.updateRestaurentsMedia, formData).then((response) => {
            setloading(false);
            history.goBack();
        });
    }

    return (
        <div className="ui main">
            <PageTitle title="Edit Gallery" linkurl="" linkTitle="" />
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <div className="row">
                        <div className="col col-6">
                            <label>Restaurents </label>
                            <select name="restro_id" required className="form-control" onChange={e => onInputChange(e)} value={restro_id}>
                                <option value="">Select Restaurents</option>
                                {rest.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                        </div>
                        <div className="col col-6">
                            <label>Media Type</label>
                            <select name="media_type" required className="form-control" onChange={e => onInputChange(e)} value={media_type}>
                                <option value="">Select Media Type</option>
                                <option value="1">Image</option>
                                <option value="2">Video</option>
                            </select>
                        </div>

                    </div>




                    <div className="row">
                        {media_type == 2 ?
                            <>
                                <Input id="video link" label="Video Link" type="text" name="video_link" place="Video Link" onChange={(e) => onInputChange(e)}
                                    requiredInput="required" value={video_link} class="col col-6"
                                />
                                <div className="col col-6">
                                    <label>Image</label>
                                    <FileUploader
                                        onFileSelectSuccess={(file) => setSelectedFile(file)}
                                        onFileSelectError={({ error }) => alert(error)}
                                    />
                                </div></>
                            : media_type == 1 ? <div className="col col-6">
                                <label>Image</label>
                                <FileUploader
                                    onFileSelectSuccess={(file) => setSelectedFile(file)}
                                    onFileSelectError={({ error }) => alert(error)}
                                />
                            </div> : ''
                        }
                        <div className="col col-6">
                            {iconName ? (
                                <div className="mt-3">
                                    <img src={image} height="200px" width="200px" className="px-2" />
                                    <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id)}>
                                        Delete
                                    </Button>
                                </div>
                            ) : ''}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                {/* <Button type="submit" variant="contained" size="large">
                                    Save
                                </Button> */}
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div>

                    </div>

                </form>


            </Card>

        </div>
    );

}

export default EditMedia;
