import React from 'react';

import LinkButton from '../UI/Input/LinkButton';
import { Card, Grid, Typography, Button, Box } from '@mui/material';

const PageTitle = (props) => {
    return <div >
        <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >
            <Typography variant="h4" size="sm">
                {props.title}
            </Typography>
            {props.linkurl ?
                <LinkButton url={props.linkurl + '/' + (props.linkid ? props.linkid : '')} name={props.linkTitle} /> : ''}
        </Box>

    </div>
}

export default PageTitle;