import React, { Component } from "react";
import { Redirect, Switch, Route, Link } from "react-router-dom";
import { withRouter } from "react-router";
import Master from "./Master";
import Pos from "./Pos";
import IndexDashboard from "./IndexDashboard";
import CategoryDetail from "./category/CategoryDetail";
// import CategoryDetail from "./pages/category/categorydetail.js";
import Dropdown from "react-bootstrap/Dropdown";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";

import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Home from "./Home";
import AddCategory from "./category/AddCategory";
import EditCategory from "./category/EditCategory";
import DishDetail from "./dish/DishDetail";
import AddDish from "./dish/AddDish";
import EditDish from "./dish/EditDish";
import TagsDetail from "./tags/TagsDetail";
import AddTags from "./tags/AddTags";
import EditTags from "./tags/EditTags";
import TagDish from "./dish/TagDish";
import EditDigitalMenu from "./restaurents/digitalmenu/EditDigitalMenu";
import AddDigitalMenu from "./restaurents/digitalmenu/AddDigitalMenu";
import DigitalMenuDetail from "./restaurents/digitalmenu/DigitalMenuDetail";
import { padding } from "@mui/system";

import { makeStyles } from "@material-ui/core/styles";

import "./Sidebar.css";
import RestaurentsDetail from "./restaurents/restaurents/RestaurentsDetail";
// import Lists from "./restaurents/restaurents/Lists";
import EditRestaurents from "./restaurents/restaurents/EditRestaurents";
import AddRestaurents from "./restaurents/restaurents/AddRestaurents";
import RestCategoryDetail from "./restaurents/restaurents/restcategory/RestCategoryDetail";
import AddRestCategory from "./restaurents/restaurents/restcategory/AddRestCategory";
import EditRestCategory from "./restaurents/restaurents/restcategory/EditRestCategory";
import SubCategoryDetail from "./restaurents/restaurents/restsubcategory/SubCategoryDetail";
import AddSubCategory from "./restaurents/restaurents/restsubcategory/AddSubCategory";
import EditSubCategory from "./restaurents/restaurents/restsubcategory/EditSubCategory";
import DishRestDetail from "./restaurents/restaurents/restdish/DishRestDetail";
import EditRestDish from "./restaurents/restaurents/restdish/EditRestDish";
import AddRestDish from "./restaurents/restaurents/restdish/AddRestDish";
import RestSectionDetail from "./restaurents/restaurents/restsection/RestSectionDetail";
import AddRestSection from "./restaurents/restaurents/restsection/AddRestSection";
import EditRestSection from "./restaurents/restaurents/restsection/EditRestSection";
import HomeSliderDetail from "./restaurents/homeslider/HomeSliderDetail";
import AddHomeSlider from "./restaurents/homeslider/AddHomeSlider";
import EditHomeSlider from "./restaurents/homeslider/EditHomeSlider";
import SliderDetail from "./restaurents/slider/SliderDetail";
import AddSlider from "./restaurents/slider/AddSlider";
import EditSlider from "./restaurents/slider/EditSlider";
import AmenitiesDetail from "./restaurents/restaurents_amenities/AmenitiesDetail";
import Addamenities from "./restaurents/restaurents_amenities/Addamenities";
import Editamenities from "./restaurents/restaurents_amenities/Editamenities";
import DealDetail from "./restaurents/restaurents_deal/DealDetail";
import Adddeal from "./restaurents/restaurents_deal/Adddeal";
import Editdeal from "./restaurents/restaurents_deal/Editdeal";
import MediaDetail from "./restaurents/restaurents_media/MediaDetail";
import AddMedia from "./restaurents/restaurents_media/AddMedia";
import EditMedia from "./restaurents/restaurents_media/EditMedia";
import ReservationLeads from "./leads/ReservationLeads";
import DigitalMenuLeads from "./leads/DigitalMenuLeads";
import Contact from "./leads/Contact";
import Feedback from "./leads/Feedback";

import DashboardIcon from '@mui/icons-material/Dashboard';
import TuneIcon from "@mui/icons-material/Tune";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import TagIcon from "@mui/icons-material/Tag";
import RamenDiningIcon from "@mui/icons-material/RamenDining";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import MmsIcon from "@mui/icons-material/Mms";
import EventsDetail from "./restaurents/appevents/EventsDetail";
import AddEvents from "./restaurents/appevents/AddEvents";
import EditEvents from "./restaurents/appevents/EditEvents";
import PointDetail from "./restaurents/point_card/PointDetail";
import AddPoint from "./restaurents/point_card/AddPoint";
import RangeDetail from "./restaurents/price_range/RangeDetail";
import AddRange from "./restaurents/price_range/AddRange";
import EditRange from "./restaurents/price_range/EditRange";
import EventIcon from "@mui/icons-material/Event";
import ListIcon from '@mui/icons-material/List';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import AddCardIcon from "@mui/icons-material/AddCard";
import EditPoint from "./restaurents/point_card/EditPoint";
import UploadFiles from "./restaurents/restaurents/gallery/upload-files.component";
import RestImageDetail from "./restaurents/restaurents/restmenuimage/RestImageDetail";
import AddRestImage from "./restaurents/restaurents/restmenuimage/AddRestImage";
import EditRestImage from "./restaurents/restaurents/restmenuimage/EditRestImage";
import UserDetail from "./user/UserDetail";
import Adduser from "./user/Adduser";
import Edituser from "./user/EditUser";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import BookingOrder from "./leads/BookingOrder";
import EditBooking from "./leads/EditBooking";
import OutletDetail from "./outlets/OutletDetail";
import EditOutlet from "./outlets/EditOutlet";
import AddOutlet from "./outlets/AddOutlet";
import EventLeads from "./leads/EventLeads";
import OrderLeads from "./leads/OrderLeads";
import { Category, EventSeat, Loyalty, MenuBook, People } from "@mui/icons-material";
import AppUser from "./leads/AppUser";
import fbi_logo from '../assets/logofbi.png';

const drawerWidth = 240;


// const location = useLocation();
// import NotFound from "./404";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      islogout: false,
    };
    console.log(this.props)
  }



  signOut = () => {
    // localStorage.removeItem("token");
    localStorage.removeItem("user");
    this.setState({
      islogout: true,
    });
    window.location.reload(false);
    // <Redirect to="/login" />;
  };
  render() {
    const { match, location } = this.props;
    const user = localStorage.getItem('user')
    const user_data = JSON.parse(user)
    var restro_id = user_data.restro_id
    if (this.state.islogout) {
      return <Redirect to="/login" />;
    }

    console.log("this.props", location.pathname);
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
          }}
        >
          <Toolbar></Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar>
            <img
              className="img-fluid w-75"
              src={fbi_logo}
            />
            {/* <Typography variant="h6" noWrap component="div" className="pl-2">
              Food Bus
            </Typography> */}
          </Toolbar>
          <Divider />

          <nav id="sidebar">
            {/* <div class="sidebar-header">
                            <h3>Bootstrap Sidebar</h3>
                        </div> */}

            <ul className="list-unstyled components px-3 py-2">
              {/* <li>
                <Link to={`${match.path}`}>
                  <span>
                    {" "}
                    <DashboardIcon />{" "}
                  </span>
                  Dashboard
                </Link>
              </li> */}


              {(!restro_id) ? (
                <li
                  className={
                    location.pathname == "/dashboard/restaurentsdetail" ||
                      location.pathname == "/dashboard/addrestaurents/"
                      ? "menuactive my-3 primary"
                      : "my-3 "
                  }
                >
                  <Link to={`${match.path}/restaurentsdetail`}>
                    {" "}
                    <span>
                      {" "}
                      <RestaurantMenuIcon />{" "}
                    </span>
                    Restaurants

                  </Link>
                </li>) : (
                <li
                  className={
                    location.pathname == "/dashboard/sectionrest/" + restro_id
                      ? "menuactive my-3 "
                      : "my-3 "
                  }
                >
                  <Link to={`${match.path}/sectionrest/` + restro_id}>
                    {" "}
                    <span>
                      {" "}
                      <RestaurantMenuIcon />{" "}
                    </span>
                    Restaurants </Link>
                </li>)}



              <li
                className={
                  location.pathname == "/dashboard/tagdetail" ||
                    location.pathname == "/dashboard/addtags/" ||
                    location.pathname == `/dashboard/edittags/`
                    ? "menuactive my-3 "
                    : "my-3 "
                }
              >
                <Link to={`${match.path}/tagdetail`}>
                  <span>
                    {" "}
                    <TagIcon />{" "}
                  </span>
                  Menu Tag
                </Link>
              </li>

              {/* <li
                className={
                  location.pathname == "/dashboard/amenitiesdetail" ||
                    location.pathname == "/dashboard/addamenities"
                    ? "menuactive my-3 "
                    : "my-3 "
                }
              >
                <Link to={`${match.path}/amenitiesdetail`}>
                  <span>
                    {" "}
                    <RamenDiningIcon />{" "}
                  </span>
                  Amenities
                </Link>
              </li>

              <li
                className={
                  location.pathname == "/dashboard/dealdetail" ||
                    location.pathname == "/dashboard/adddeal"
                    ? "menuactive my-3 "
                    : "my-3 "
                }
              >
                <Link to={`${match.path}/dealdetail`}>
                  <span>
                    {" "}
                    <LocalOfferIcon />{" "}
                  </span>
                  Trending
                </Link>
              </li>
              <li
                className={
                  location.pathname == "/dashboard/mediadetail" ||
                    location.pathname == "/dashboard/addmedia"
                    ? "menuactive my-3 "
                    : "my-3 "
                }
              >
                <Link to={`${match.path}/mediadetail`}>
                  <span>
                    {" "}
                    <MmsIcon />{" "}
                  </span>
                  Gallery
                </Link>
              </li>
              <li
                className={
                  location.pathname == "/dashboard/eventsdetail" ||
                    location.pathname == "/dashboard/addevents"
                    ? "menuactive my-3 "
                    : "my-3 "
                }
              >
                <Link to={`${match.path}/eventsdetail`}>
                  <span>
                    {" "}
                    <EventIcon />{" "}
                  </span>
                  Events
                </Link>
              </li> */}

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Reports</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>

                    {/* <li
                      className={
                        location.pathname == "/dashboard/bookingorder"
                          ? "menuactive my-3 "
                          : "my-3 "
                      }
                    >
                      <Link to={`${match.path}/bookingorder`}>
                        <span> {" "}  <Loyalty />{" "}  </span> Loyalty Point List
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname == "/dashboard/eventleads"
                          ? "menuactive my-3 "
                          : "my-3 "
                      }
                    >
                      <Link to={`${match.path}/eventleads`}>
                        <span> {" "}  <AssignmentIcon />{" "}  </span> Event Leads
                      </Link>
                    </li> */}
                    <li
                      className={
                        location.pathname == "/dashboard/reservationleads"
                          ? "menuactive my-3 "
                          : "my-3 "
                      }
                    >
                      <Link to={`${match.path}/reservationleads`}>
                        <span> {" "}  <EventSeat />{" "}  </span> Reservation Leads
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname == "/dashboard/digitalmenuleads"
                          ? "menuactive my-3 "
                          : "my-3 "
                      }
                    >
                      <Link to={`${match.path}/digitalmenuleads`}>
                        <span> {" "}  <MenuBook />{" "} </span>  Guest Leads
                      </Link>
                    </li>
                    {/* <li
                      className={
                        location.pathname == "/dashboard/orderleads"
                          ? "menuactive my-3 "
                          : "my-3 "
                      }
                    >
                      <Link to={`${match.path}/orderleads`}>
                        <span> {" "}  <RestaurantIcon />{" "} </span>  Bentchair Order Leads
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname == "/dashboard/feedback" ||
                          location.pathname == "/dashboard/addpoint"
                          ? "menuactive my-3 "
                          : "my-3 "
                      }
                    >
                      <Link to={`${match.path}/feedback`}>
                        <span>
                          {" "}
                          <FeedbackIcon />{" "}
                        </span>
                        Feedback Leads
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname == "/dashboard/appuser"
                          ? "menuactive my-3 "
                          : "my-3 "
                      }
                    >
                      <Link to={`${match.path}/appuser`}>
                        <span>
                          {" "}
                          <People />{" "}
                        </span>
                        App Users
                      </Link>
                    </li> */}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <br />

              {/* {restro_id === 0 ? <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>App Setting</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ul>
                      <li
                        className={
                          location.pathname == "/dashboard/slider" ||
                            location.pathname == "/dashboard/addslider"
                            ? "menuactive my-3 "
                            : "my-3 "
                        }
                      >
                        <Link to={`${match.path}/slider`}>
                          <span>
                            {" "}
                            <TuneIcon />{" "}
                          </span>
                          Slider
                        </Link>
                      </li>

                      <li
                        className={
                          location.pathname == "/dashboard/homeslider"
                            ? "menuactive my-3 "
                            : "my-3 "
                        }
                      >
                        <Link to={`${match.path}/homeslider`}>
                          <span>
                            {" "}
                            <TuneIcon />{" "}
                          </span>
                          Home Slider
                        </Link>
                      </li>



                      <li
                        className={
                          location.pathname == "/dashboard/rangedetail" ||
                            location.pathname == "/dashboard/addrange"
                            ? "menuactive my-3 "
                            : "my-3 "
                        }
                      >
                        <Link to={`${match.path}/rangedetail`}>
                          <span>
                            {" "}
                            <PriceChangeIcon />{" "}
                          </span>
                          Price Range
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname == "/dashboard/pointdetail" ||
                            location.pathname == "/dashboard/addpoint"
                            ? "menuactive my-3 "
                            : "my-3 "
                        }
                      >
                        <Link to={`${match.path}/pointdetail`}>
                          <span>
                            {" "}
                            <AddCardIcon />{" "}
                          </span>
                          Point Card
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname == "/dashboard/outlet" ||
                            location.pathname == "/dashboard/addoutlet"
                            ? "menuactive my-3 "
                            : "my-3 "
                        }
                      >
                        <Link to={`${match.path}/outlet`}>
                          <span>
                            {" "}
                            <Category />{" "}
                          </span>
                          Outlets
                        </Link>
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion> : ''}


              <li
                className={
                  location.pathname == "/dashboard/userdetail" ||
                    location.pathname == "/dashboard/adduser"
                    ? "menuactive my-3 "
                    : "my-3 "
                }
              >
                <Link to={`${match.path}/userdetail`}>
                  <span>
                    {" "}
                    <AddCardIcon />{" "}
                  </span>
                  Restaurant User
                </Link>
              </li>

              {(restro_id) ? (
                <li
                  className={
                    location.pathname == "/dashboard/restaurentsdetail"
                      ? "menuactive my-3 "
                      : "my-3 "
                  }
                >
                  <Link to={`${match.path}/restaurentsdetail`}>
                    <span>
                      {" "}
                      <AddCardIcon />{" "}
                    </span>
                    Profile
                  </Link>
                </li>
              ) : null} */}

              <br />
              <br />
              <br />



            </ul>

            <button
              className="upgrade-to-pro  text-uppercase bottom-0 bg-febb25 food_bus mb-2 btn "
              onClick={this.signOut}
            >
              SignOut
            </button>
          </nav>
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />
          {/* <main role="main">
                        <div className="main"> */}
          <Switch>
            <Route path={`${match.path}/master`}>
              <Master />
            </Route>

            <Route path={`${match.path}/home`}>
              <Home />
            </Route>
            <Route path={`${match.path}/pos`}>
              <Pos />
            </Route>
            <Route exact path={`${match.path}`}>
              <IndexDashboard />
            </Route>
            {/* <Route path={`${match.path}/drag`}>
              <Lists />
            </Route> */}

            <Route path={`${match.path}/restaurentsdetail`}>
              <RestaurentsDetail />
            </Route>
            <Route path={`${match.path}/addrestaurents`}>
              <AddRestaurents />
            </Route>
            <Route path={`${match.path}/eddrestaurents/:id`}>
              <EditRestaurents />
            </Route>

            <Route path={`${match.path}/sectionrest/:restro_id`}>
              <RestSectionDetail />
            </Route>
            <Route path={`${match.path}/addsection/:restro_id`}>
              <AddRestSection />
            </Route>
            <Route path={`${match.path}/editsection/:id`}>
              <EditRestSection />
            </Route>

            <Route path={`${match.path}/categoryrest/:restro_id`}>
              <RestCategoryDetail />
            </Route>
            <Route path={`${match.path}/addrestcategory/:restro_id`}>
              <AddRestCategory />
            </Route>
            <Route path={`${match.path}/editrestcategory/:id`}>
              <EditRestCategory />
            </Route>

            <Route path={`${match.path}/subcategoryrest/:cat_id`}>
              <SubCategoryDetail />
            </Route>
            <Route path={`${match.path}/addsubcategory/:cat_id`}>
              <AddSubCategory />
            </Route>
            <Route path={`${match.path}/editsubcategory/:id`}>
              <EditSubCategory />
            </Route>

            <Route path={`${match.path}/homeslider`}>
              <HomeSliderDetail />
            </Route>
            <Route path={`${match.path}/addhomeslider`}>
              <AddHomeSlider />
            </Route>
            <Route path={`${match.path}/edithomeslider/:id`}>
              <EditHomeSlider />
            </Route>

            <Route path={`${match.path}/slider`}>
              <SliderDetail />
            </Route>
            <Route path={`${match.path}/addslider`}>
              <AddSlider />
            </Route>
            <Route path={`${match.path}/editslider/:id`}>
              <EditSlider />
            </Route>

            <Route path={`${match.path}/dishrestdetail/:cat_id`}>
              <DishRestDetail />
            </Route>
            <Route path={`${match.path}/addrestdish/:cat_id`}>
              <AddRestDish />
            </Route>
            <Route path={`${match.path}/editrestdish/:id`}>
              <EditRestDish />
            </Route>
            {/* <Route path={`${match.path}/tagdish/:id`}>
                            <TagDish />
                        </Route> */}

            <Route path={`${match.path}/categorydetail`}>
              <CategoryDetail />
            </Route>
            <Route path={`${match.path}/addcategory`}>
              <AddCategory />
            </Route>
            <Route path={`${match.path}/editcategory/:id`}>
              <EditCategory />
            </Route>

            <Route path={`${match.path}/dishdetail`}>
              <DishDetail />
            </Route>
            <Route path={`${match.path}/adddish`}>
              <AddDish />
            </Route>
            <Route path={`${match.path}/addoutlet`}>
              <AddOutlet />
            </Route>
            <Route path={`${match.path}/editdish/:id`}>
              <EditDish />
            </Route>
            <Route path={`${match.path}/editoutlet/:id`}>
              <EditOutlet />
            </Route>
            <Route path={`${match.path}/tagdish/:id`}>
              <TagDish />
            </Route>

            <Route path={`${match.path}/tagdetail`}>
              <TagsDetail />
            </Route>
            <Route path={`${match.path}/addtags`}>
              <AddTags />
            </Route>
            <Route path={`${match.path}/edittags/:id`}>
              <EditTags />
            </Route>

            <Route path={`${match.path}/digitalmenudetail`}>
              <DigitalMenuDetail />
            </Route>
            <Route path={`${match.path}/adddigitalmenu`}>
              <AddDigitalMenu />
            </Route>
            <Route path={`${match.path}/editdigitalmenu/:id`}>
              <EditDigitalMenu />
            </Route>

            <Route path={`${match.path}/amenitiesdetail`}>
              <AmenitiesDetail />
            </Route>
            <Route path={`${match.path}/addamenities`}>
              <Addamenities />
            </Route>
            <Route path={`${match.path}/editamenities/:id`}>
              <Editamenities />
            </Route>

            <Route path={`${match.path}/dealdetail`}>
              <DealDetail />
            </Route>
            <Route path={`${match.path}/adddeal`}>
              <Adddeal />
            </Route>
            <Route path={`${match.path}/editdeal/:id`}>
              <Editdeal />
            </Route>

            <Route path={`${match.path}/mediadetail`}>
              <MediaDetail />
            </Route>
            <Route path={`${match.path}/addmedia`}>
              <AddMedia />
            </Route>
            <Route path={`${match.path}/editmedia/:id`}>
              <EditMedia />
            </Route>

            <Route path={`${match.path}/eventsdetail`}>
              <EventsDetail />
            </Route>
            <Route path={`${match.path}/addevents`}>
              <AddEvents />
            </Route>
            <Route path={`${match.path}/eventleads`}>
              <EventLeads />
            </Route>
            <Route path={`${match.path}/reservationleads`}>
              <ReservationLeads />
            </Route>

            <Route path={`${match.path}/bookingorder`}>
              <BookingOrder />
            </Route>

            <Route path={`${match.path}/digitalmenuleads`}>
              <DigitalMenuLeads />
            </Route>
            <Route path={`${match.path}/orderleads`}>
              <OrderLeads />
            </Route>
            <Route path={`${match.path}/contactform`}>
              <Contact />
            </Route>
            <Route path={`${match.path}/feedback`}>
              <Feedback />
            </Route>
            <Route path={`${match.path}/appuser`}>
              <AppUser />
            </Route>
            <Route path={`${match.path}/outlet`}>
              <OutletDetail />
            </Route>

            <Route path={`${match.path}/editevents/:id`}>
              <EditEvents />
            </Route>
            <Route path={`${match.path}/editbooking/:id`}>
              <EditBooking />
            </Route>



            <Route path={`${match.path}/pointdetail`}>
              <PointDetail />
            </Route>
            <Route path={`${match.path}/addpoint`}>
              <AddPoint />
            </Route>
            <Route path={`${match.path}/editpoint/:id`}>
              <EditPoint />
            </Route>

            <Route path={`${match.path}/rangedetail`}>
              <RangeDetail />
            </Route>
            <Route path={`${match.path}/addrange`}>
              <AddRange />
            </Route>
            <Route path={`${match.path}/editrange/:id`}>
              <EditRange />
            </Route>

            <Route path={`${match.path}/restmenuimage/:restro_id`}>
              <RestImageDetail />
            </Route>
            <Route path={`${match.path}/addmenuimage/:restro_id`}>
              <AddRestImage />
            </Route>
            <Route path={`${match.path}/editmenuimage/:id`}>
              <EditRestImage />
            </Route>

            <Route path={`${match.path}/uploadfile/:id`}>
              <UploadFiles />
            </Route>

            <Route path={`${match.path}/userdetail`}>
              <UserDetail />
            </Route>
            <Route path={`${match.path}/adduser`}>
              <Adduser />
            </Route>
            <Route path={`${match.path}/edituser/:id`}>
              <Edituser />
            </Route>

            {/* <Route path="*">
                                 <NotFound />
                             </Route> */}
          </Switch>
          {/* </div>
                    </main> */}
        </Box>
      </Box>
    );
  }
}

export default withRouter(Dashboard);
