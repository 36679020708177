import React, { Component, Fragment } from "react";

class Pos extends Component {
    render() {
        return (
            <Fragment>
                <h2>Point Of Sale</h2>
            </Fragment>
        );
    }
}
export default Pos;
