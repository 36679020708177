import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import { Button, Card } from '@mui/material';
import FileUploader from "../FileUploader";

import { endpoints } from "../../services/apiurl";
import Input from "../UI/Input/Input";
import PageTitle from "../UI/PageTitle";
import DeleteIcon from '@mui/icons-material/Delete';


const EditCategory = () => {
    let history = useHistory();
    let { id } = useParams();

    const [cat, setCat] = useState({
        name: '',
        status: '',
        icon: '',
        iconName: '',
    });
    const [selectedFile, setSelectedFile] = useState(null);

    const { name, status, icon, iconName } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    useEffect(() => {
        editcategory();
    }, []);

    const editcategory = async () => {
        await axios.post(endpoints.editCategory, { id: id }).then((response) => {
            setCat({
                id: response.data.data.id,
                name: response.data.data.name,
                status: response.data.data.status,
                icon: response.data.data.icon,
                iconName: response.data.data.iconName
            });
        });
    }

    const removeImageHandler = async (id) => {
        await axios.post(endpoints.deleteImageCat, { id }).then((response) => {
        });
        window.location.reload();
    };

    const onSubmit = async e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", name);
        formData.append("icon", selectedFile);
        formData.append("status", status);
        formData.append("id", id);

        console.log('ca', formData);
        await axios.post(endpoints.updateCategory, formData).then((response) => {
            history.goBack();
            // if (response.data.status == 200) {
            //     // toast.success(response.data.message);
            //     history.goBack();
            // } else {
            //     // toast.error(response.data.message);
            // }
        });
    }

    return (
        <div className="ui main">
            <PageTitle title="Edit Category" linkurl="" linkTitle="" />
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <Input id="name" label="Name" type="text" name="name" place="Name" onChange={(e) => onInputChange(e)}
                        requiredInput="true" value={name}
                    />
                    <div className="row">
                        <div className="col col-6">
                            <label>Icon</label>
                            <FileUploader
                                onFileSelectSuccess={(file) => setSelectedFile(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" className="form-control" onChange={e => onInputChange(e)} value={status} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                <Button type="submit" variant="contained" size="small">
                                    Save
                                </Button>
                            </div></div></div>


                </form>
                {iconName ? (
                    <div className="mt-3">
                        <img src={icon} height="200px" width="200px" className="px-2" />
                        <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id)}>
                            Delete
                        </Button>
                    </div>
                ) : ''}

            </Card>

        </div>
    );

}

export default EditCategory;
