import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Button from '@mui/material/Button';

import { endpoints } from "../../../services/apiurl";

import Input from "../../UI/Input/Input";
import TextAreaInput from "../../UI/Input/TextAreaInput";
import Card from "@mui/material/Card";


import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

const AddDigitalMenu = () => {
    let history = useHistory();
    const [cat, setCat] = useState({
        dish_id: '',
        category_id: '',
        description: '',
        restro_id: '',
        status: '',
    });

    const [catgory, setCategory] = useState([]);
    const [dish, setDish] = useState([]);
    const [rest, setRest] = useState([]);

    const { dish_id, category_id, description, restro_id, status } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        getDopdownCategory();
        getDishDropdown();
        getRestaurentsDropDown();
    }, []);

    const getDopdownCategory = async () => {
        await axios.post(endpoints.getDopdownCategory).then((response) => {
            setCategory(response.data.data);
        });
    }

    const getDishDropdown = async () => {
        await axios.post(endpoints.getDishDropdown).then((response) => {
            setDish(response.data.data);
        });
    };


    const getRestaurentsDropDown = async () => {
        await axios.post(endpoints.getRestaurentsDropDown).then((response) => {
            setRest(response.data.data);
        });
    };

    const onSubmit = async e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("category_id", category_id);
        formData.append("dish_id", dish_id);
        formData.append("restro_id", restro_id);
        formData.append("description", description);
        formData.append("status", status);
        await axios.post(endpoints.addRestaurentsDigitalMenu, formData).then((response) => {
            history.goBack();
        });
    }


    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    // Checkbox Group
    const [flavors, setFlavors] = React.useState({
        chocolate: true,
        vanilla: false,
        strawberry: false
    });

    const { chocolate, vanilla, strawberry } = flavors;

    const handleFlavorChange = (event) => {
        setFlavors({ ...flavors, [event.target.name]: event.target.checked });
    };

    return (
        <div className="ui main">
            <h2>Add Digital Menu</h2>
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <div className="row">

                        <div className="col col-6">
                            <label>Restaurents </label>
                            <select name="restro_id" required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Restaurents</option>
                                {rest.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                        </div>

                        <div className="col col-6">
                            <label>Category </label>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={chocolate}
                                                onChange={handleFlavorChange}
                                                name="chocolate"
                                            />
                                        }
                                        label="Chocolate"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={vanilla}
                                                onChange={handleFlavorChange}
                                                name="vanilla"
                                            />
                                        }
                                        label="Vanilla"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={strawberry}
                                                onChange={handleFlavorChange}
                                                name="strawberry"
                                            />
                                        }
                                        label="Strawberry"
                                    />
                                </FormGroup>
                            </FormControl>
                            {/* <select name="category_id" required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Category</option>
                                {catgory.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select> */}
                        </div>

                    </div>

                    <div className="row">

                        <div className="col col-6">
                            <label>Dish</label>

                            <select name="dish_id" required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Dish</option>
                                {dish.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                        </div>


                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>
                    </div>

                    <TextAreaInput id="description" label="Description" name="description" place="Description" onChange={(e) => onInputChange(e)}
                        requiredInput="required" value={description} class="col col-12" />


                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                <Button type="submit" variant="contained" size="medium">
                                    Save
                                </Button>
                            </div></div></div>


                </form>
            </Card>

        </div>
    );

}

export default AddDigitalMenu;