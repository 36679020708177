import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import { Button, Card, Fab } from '@mui/material';
import FileUploader from "../../../FileUploader";
import AddIcon from "@mui/icons-material/Add";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";

import { endpoints } from "../../../../services/apiurl";
import Input from "../../../UI/Input/Input";
import TextAreaInput from "../../../UI/Input/TextAreaInput";
import Multiselect from 'multiselect-react-dropdown';
import DeleteIcon from '@mui/icons-material/Delete';


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

var sun = false;
var mon = false;
var tue = false;
var wed = false;
var thu = false;
var fri = false;
var sat = false;

const EditRestDish = () => {
    let history = useHistory();
    let { id } = useParams();


    //   const [inputList, setInputList] = useState([
    //     { sun: 0, mon: 0, tue: 0, wed: 0, thu: 0, fri: 0, sat: 0, price: "" },
    //   ]);
    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;

        const list = [...inputList];
        console.log(e.target)
        console.log(e.target.value)
        if (e.target.value === 0) {
            e.target.unchecked = true
        }
        if (e.target.value === 1) {
            e.target.checked = true
        }

        if (name == "price") {
            list[index][name] = value;
        } else {
            if (list[index][name] == 1) {
                list[index][name] = 0;
            }
            else {
                list[index][name] = 1;
            }

            if (name == 'sun') {
                sun = true;
            }
            if (name == 'mon') {
                mon = true;
            }
            if (name == 'tue') {
                tue = true;
            }
            if (name == 'wed') {
                wed = true;
            }
            if (name == 'thu') {
                thu = true;
            }
            if (name == 'fri') {
                fri = true;
            }
            if (name == 'sat') {
                sat = true;
            }
            // e.target == DisabledByDefault;
        }
        setInputList(list);
    };

    const checkch = (v) => {
        // var checkbox = document.getElementById('mon');
        // checkbox.checked = true


    }

    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        console.log('1', list)
        list.splice(index, 1);
        console.log('2', list)

        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = (x) => {
        let index;
        const list = [...inputList];
        console.log(inputList);
        // list.splice()
        console.log(x);
        for (i in x) {
            if (x[i] === 1) {
                let lii = document.getElementsByClassName(x[i]);
                for (index = 0; index < x.length; ++index) {
                    lii[index].setAttribute("disabled", "disabled");


                }
            }
        }
        let ob = [...inputList.values()];
        // console.log(ob)
        let li = [];
        let cc = [];
        for (var i = 0; i < ob.length; i++) {
            // the plainest of array loops
            var obj = ob[i];

            for (var key in obj) {
                if (obj[key] === 1) {
                    li.push(key);
                    // document.getElementById(key).disabled = true;
                }
                cc[key] = obj[key];
                //   console.log(key, obj[key])
            }
        }

        //   console.log(li);
        for (i in li) {
            // document.getElementsByClassName(li[i]).disabled = true;
            //   let lii = document.getElementsByClassName(li[i]);
            //   for (index = 0; index < lii.length; ++index) {
            //     lii[index].setAttribute("disabled", "disabled");
            //   }
            // let x = document.getElementById(li[i]).setAttribute('className', 'dis');
            // x.disabled=true;
        }

        setInputList([...inputList,

        { sun: 0, mon: 0, tue: 0, wed: 0, thu: 0, fri: 0, sat: 0, price: "", }
        ]);
        // {sun: 0,mon: 0,tue: 0,wed: 0,thu: 0,fri: 0, sat: 0,price: "",}
        // {sun: cc['sun'],mon: cc['mon'],tue: cc['tue'],wed: cc['wed'],thu: cc['thu'],fri: cc['fri'], sat: cc['sat'],price: "",}
        // console.log(inputList);
        // console.log('cc',cc);

        return cc;
    };

    // let history = useHistory();
    // let { id } = useParams();

    const [cat, setCat] = useState({
        name: '',
        price: '',
        status: '',
        special: '',
        sub_cat_id: '',
        tax: '',
        vat: '',
        description: '',
        icon: '',
        image: '',
        start_time: '',
        preparation_time: '', type: '',
        end_time: '',
        showLogo: '',
        showImage: '',
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileImage, setSelectedFileImage] = useState(null);
    const [subcatgory, setSubCategory] = useState([]);
    const [loading, setloading] = useState(false);

    const { name, price, status, special, sub_cat_id, tax, vat, description, icon, image, showImage, showLogo, start_time, end_time, preparation_time, type } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    useEffect(() => {
        // getDopdownSubCategory();
        getTagsByTagType(1);
        getTagsByTagType(2);
        getTagsByTagType(3);
        getTagsByTagType(4);
        getTagsByTagType(5);
        getTagsByTagType(6);
        getMenuType();
        edit();
    }, []);

    const getDopdownSubCategory = async (cat_id) => {
        await axios.post(endpoints.getDopdownSubCategory, { cat_id }).then((response) => {
            setSubCategory(response.data.data);
        });
    }
    const getMenuType = async () => {
        await axios.post(endpoints.getMenuType).then((response) => {
        });
    }

    //Veg Option
    // let [vegOptions, setvegOptions] = useState([]);
    // let [nogvegOptions, setnonvegOptions] = useState([]);
    let [healthOption, setHealthOption] = useState([]);
    let [specialOption, setspecialOption] = useState([]);
    let [allergenOptions, setallergenOptions] = useState([]);

    const getTagsByTagType = async (type) => {
        await axios.post(endpoints.getTagsByTagType, { tag_type: type }).then((response) => {
            // if (type == 1) {
            //     setvegOptions(response.data.data);
            // }
            // else if (type == 2) {
            //     setnonvegOptions(response.data.data);
            // } 
            if (type == 3) {
                setHealthOption(response.data.data);
            } else if (type == 4) {
                setspecialOption(response.data.data);
            } else if (type == 5) {
                setallergenOptions(response.data.data);
            } else if (type == 6) {
                setInputList(response.data.price);
            }
        });
    }

    // let [vegOptionsSelectedData, setvegOptionsSelectedData] = useState([]);
    // let [nogvegOptionsSelectedData, setnogvegOptionsSelectedData] = useState([]);
    let [healthOptionsSelectedData, sethealthOptionsSelectedData] = useState([]);
    let [allergenOptionsSelectedData, setallergenOptionsSelectedData] = useState([]);

    // let [vegOptionsselectedValues, setvegOptionsselectedValues] = useState([]);
    // let [nogvegOptionselectedValues, setnogvegOptionselectedValues] = useState([]);
    let [healthOptionsselectedValues, sethealthOptionsselectedValues] = useState([]);
    let [allergenOptionsselectedValues, setallergenOptionsselectedValues] = useState([]);


    // const onVegSelectd = async (e) => {
    //     setvegOptionsSelectedData(e);
    // }
    // const onVegRemoved = async (e) => {
    //     setvegOptionsSelectedData(e);
    // }

    // const onNonVegSelectd = async (e) => {
    //     setnogvegOptionsSelectedData(e);
    // }
    // const onNonVegRemoved = async (e) => {
    //     setnogvegOptionsSelectedData(e);
    // }

    const onHealthSelectd = async (e) => {
        sethealthOptionsSelectedData(e);
    }
    const onHealthRemoved = async (e) => {
        sethealthOptionsSelectedData(e);
    }

    const onAllergiSelectd = async (e) => {
        setallergenOptionsSelectedData(e);
    }
    const onAllergiRemoved = async (e) => {
        setallergenOptionsSelectedData(e);
    }




    const edit = async () => {
        await axios.post(endpoints.editDish, { id: id }).then((response) => {
            console.log(response.data.price);
            getDopdownSubCategory(response.data.data.cat_id);
            // setvegOptionsselectedValues(response.data.vegD);
            // setnogvegOptionselectedValues(response.data.nonvegD);
            sethealthOptionsselectedValues(response.data.health);
            setallergenOptionsselectedValues(response.data.aller);

            // setInputList(response.data.price);
            if (response.data.price == '') {
                console.log(response.data.data.price)
                setInputList([{ sun: 0, mon: 0, tue: 0, wed: 0, thu: 0, fri: 0, sat: 0, price: response.data.data.price, }
                ]);
            }
            else {
                setInputList(response.data.price);
            }


            console.log("we");

            console.log(response.data.price)
            //    console.log(JSON.stringify(response.data.price));
            console.log(response.data.price);

            setCat({
                id: response.data.data.id,
                price: response.data.price,
                name: response.data.data.name,
                image: response.data.data.image,
                status: response.data.data.status,
                icon: response.data.data.icon,
                description: response.data.data.description,
                special: response.data.data.special,
                sub_cat_id: response.data.data.sub_cat_id,
                tax: response.data.data.tax,
                vat: response.data.data.vat,
                start_time: response.data.data.start_time,
                end_time: response.data.data.end_time,
                preparation_time: response.data.data.preparation_time,
                type: response.data.data.type,
                showLogo: response.data.data.showLogo,
                showImage: response.data.data.showImage,
            });
        });
    }

    // console.log(price)
    // let pricee = JSON.stringify(price)
    console.log("mm")
    console.log(JSON.stringify(price))
    console.log((price))

    const [inputList, setInputList] = useState([JSON.stringify(price)]);



    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        // console.log('vegOptionsSelectedData', vegOptionsSelectedData);
        // console.log('nogvegOptionsSelectedData', nogvegOptionsSelectedData);
        console.log('healthOptionsSelectedData', healthOptionsSelectedData);


        // if (!selectedFile) {
        //     alert('Please select icon');
        //     return false;
        // }
        // if (!selectedFileImage) {
        //     alert('Please select image');
        //     return false;
        // }
        const formData = new FormData();
        formData.append("name", name);
        formData.append("price", JSON.stringify(inputList));
        console.log('sun', inputList)
        formData.append("tax", tax);
        formData.append("vat", vat);
        formData.append("start_time", start_time);
        formData.append("end_time", end_time);

        formData.append("description", description);
        formData.append("special", special);
        formData.append("sub_cat_id", sub_cat_id);
        formData.append("icon", selectedFile);
        formData.append("image", selectedFileImage);

        formData.append("preparation_time", preparation_time);
        formData.append("type", type);
        formData.append("status", status);
        // formData.append("vegOptionsSelectedData", JSON.stringify(vegOptionsSelectedData));
        // formData.append("nogvegOptionsSelectedData", JSON.stringify(nogvegOptionsSelectedData));
        formData.append("healthOptionsSelectedData", JSON.stringify(healthOptionsSelectedData));
        formData.append("allergenOptionsSelectedData", JSON.stringify(allergenOptionsSelectedData));
        formData.append("id", id);
        await axios.post(endpoints.updateDish, formData).then((response) => {
            setloading(false);
            history.goBack();

        });
    }

    const removeImageHandler = async (id, type) => {
        await axios.post(endpoints.deleteImageDish, { id, type }).then((response) => {
        });
        window.location.reload();
    };

    return (
        <div className="ui main">
            <h2>Edit Dish</h2>
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">

                    <div className="row">
                        <Input id="name" label="Name" type="text" name="name" place="Name" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={name} class="col col-12"
                        />
                        {/* <Input id="price" label="Price" type="number" name="price" place="Price" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={price} class="col col-6"
                        /> */}
                        <div className="col col-12">

                            {inputList?.map((x, i) => {

                                //    console.log(price.length)

                                console.log(x)


                                return (
                                    <Box sx={{ border: '1px solid grey', mt: 2 }}>


                                        <div className="row">
                                            {/* <Box sx={{border: '1px solid grey' }}> */}
                                            <Input
                                                id="price"
                                                label="Price"
                                                type="number"
                                                name="price"
                                                place="Price"
                                                onChange={(e) => handleInputChange(e, i)}
                                                requiredInput="required"
                                                value={(x.price)}
                                                class="col col-4"
                                            />

                                            <div className="col col-8">
                                                <br />
                                                <div className="row">
                                                    <div className="col col-md-1">

                                                    </div>
                                                    <div className="col-md-1">
                                                        <p>Sun</p>
                                                        <input
                                                            type="checkbox"
                                                            className="sun"
                                                            name="sun"
                                                            value={(x.sun)}
                                                            // {...(inputList.length === i ? {} : {...(x.sun === 1 ? { disabled: "disabled" } : {}) } )}
                                                            // {...(x.sun === 1 ? { checked: "checked" } : {})}

                                                            {...(x.sun === 1 ? { checked: "checked" } : {})}
                                                            onChange={(e) => handleInputChange(e, i)}
                                                        />
                                                    </div>
                                                    <div className="col-md-1">
                                                        <p>Mon</p>

                                                        <input
                                                            type="checkbox"
                                                            className="mon"
                                                            id="mon"
                                                            name="mon"
                                                            value={(x.mon)}
                                                            {...(x.mon === 1 ? { checked: "checked" } : {})}
                                                            onChange={(e) => handleInputChange(e, i)}
                                                        // {...(x.mon === 1 ? (e) => handleInputChange(e, i)  : {})}


                                                        />
                                                    </div>

                                                    <div className="col-md-1">
                                                        <p>Tue</p>
                                                        <input
                                                            type="checkbox"
                                                            className="tue"
                                                            id="tue"
                                                            name="tue"
                                                            value={(x.tue)}
                                                            // {...(inputList.length === i ? {} : {...(x.tue === 1 ? { disabled: "disabled" } : {}) } )}
                                                            onChange={(e) => handleInputChange(e, i)}

                                                            // onClick={(checkch())}
                                                            {...(x.tue === 1 ? { checked: "checked" } : {})}

                                                        />
                                                    </div>
                                                    <div className="col-md-1">
                                                        <p>Wed</p>

                                                        <input
                                                            type="checkbox"
                                                            className="wed"
                                                            id="wed"
                                                            name="wed"
                                                            value={(x.wed)}
                                                            // {...(inputList.length === i ? {} : {...(x.wed === 1 ? { disabled: "disabled" } : {}) } )}
                                                            onChange={(e) => handleInputChange(e, i)}
                                                            {...(x.wed === 1 ? { checked: "checked" } : {})}

                                                        />
                                                    </div>
                                                    <div className="col-md-1">
                                                        <p>Thur</p>

                                                        <input
                                                            type="checkbox"
                                                            className="thu"
                                                            id="thu"
                                                            name="thu"
                                                            value={(x.thu)}
                                                            // {...(inputList.length === i ? {} : {...(x.thu === 1 ? { disabled: "disabled" } : {}) } )}
                                                            onChange={(e) => handleInputChange(e, i)}
                                                            {...(x.thu === 1 ? { checked: "checked" } : {})}

                                                        />
                                                    </div>
                                                    <div className="col-md-1">
                                                        <p>Fri</p>

                                                        <input
                                                            type="checkbox"
                                                            className="fri"
                                                            id="fri"
                                                            name="fri"
                                                            value={(x.fri)}
                                                            // {...(inputList.length === i ? {} : {...(x.fri === 1 ? { disabled: "disabled" } : {}) } )}
                                                            onChange={(e) => handleInputChange(e, i)}
                                                            {...(x.fri === 1 ? { checked: "checked" } : {})}

                                                        />
                                                    </div>
                                                    <div className="col-md-1">
                                                        <p>Sat</p>

                                                        <input
                                                            type="checkbox"
                                                            className="sat"
                                                            id="sat"
                                                            name="sat"
                                                            value={(x.sat)}
                                                            // {...(inputList.length === i ? {} : {...(x.sat === 1 ? { disabled: "disabled" } : {}) } )}
                                                            onChange={(e) => handleInputChange(e, i)}
                                                            {...(x.sat === 1 ? { checked: "checked" } : {})}

                                                        />
                                                    </div>




                                                    {inputList.length - 1 === i && (
                                                        <div className="col-md-2">

                                                            <div className="mt-3">
                                                                <Fab
                                                                    onClick={() => handleAddClick()}
                                                                    color="primary"
                                                                    size="small"
                                                                    aria-label="add"
                                                                >
                                                                    <AddIcon />
                                                                </Fab>
                                                            </div>
                                                            {/* <Fab
                              onClick={() => handleAddClick()}
                              color="primary"
                              size="medium"
                              aria-label="add"
                            >
                              <AddIcon />
                            </Fab> */}
                                                        </div>
                                                    )}

                                                    {inputList.length - 1 !== i && (
                                                        <div className="col-md-2">

                                                            <div className="mt-3">
                                                                <Fab
                                                                    onClick={() => handleRemoveClick(i)}
                                                                    color="secondary"
                                                                    size="small"
                                                                    aria-label="add"
                                                                >
                                                                    <RemoveSharpIcon />
                                                                </Fab>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {/* {inputList.length !== 1 && (
                                                <button
                                                    className="mr10"
                                                    onClick={() => handleRemoveClick(i)}
                                                >
                                                    Remove
                                                </button>
                                            )} */}
                                            </div>

                                            <br />
                                            {/* </Box> */}
                                        </div>
                                    </Box>




                                );

                            })}


                            {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
                        </div>
                    </div>
                    <div className="row">
                        <Input id="tax" label="Tax" type="number" name="tax" place="Tax" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={tax} class="col col-4"
                        />
                        <Input id="vat" label="Vat" type="number" name="vat" place="Vat" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={vat} class="col col-4"
                        />
                        <Input id="preparation_time" label="Preparation Time" type="text" name="preparation_time" place="Preparation Time" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={preparation_time} class="col col-4"
                        />
                    </div>



                    <div className="row">
                        <div className="col col-4">
                            <label>Dish Type </label>
                            <select name="type" className="form-control" onChange={e => onInputChange(e)} value={type} >
                                <option value="0">Select Type</option>
                                <option value="1">Veg</option>
                                <option value="2">Non-veg</option>
                                <option value="3">EggLess</option>
                                <option value="4">SeeFood</option>
                            </select>
                        </div>
                        <div className="col col-4">
                            <label>Start Time </label>
                            <select name="start_time" className="form-control" onChange={e => onInputChange(e)} value={start_time}>
                                <option value="">Select Start Time</option>
                                <option value="10:00">10:00 AM</option>
                                <option value="10:30">10:30 AM</option>
                                <option value="11:00">11:00 AM</option>
                                <option value="11:30">11:30 AM</option>
                                <option value="12:00">12:00 PM</option>
                                <option value="12:30">12:30 PM</option>
                                <option value="13:00">01:00 PM</option>
                                <option value="13:30">01:30 PM</option>
                                <option value="14:00">02:00 PM</option>
                                <option value="14:30">02:30 PM</option>
                                <option value="15:00">03:00 PM</option>
                                <option value="15:30">03:30 PM</option>
                                <option value="16:00">04:00 PM</option>
                                <option value="16:30">04:30 PM</option>
                                <option value="17:00">05:00 PM</option>
                                <option value="17:30">05:30 PM</option>
                                <option value="18:00">06:00 PM</option>
                                <option value="18:30">06:30 PM</option>
                                <option value="19:00">07:00 PM</option>
                                <option value="19:30">07:30 PM</option>
                                <option value="20:00">08:00 PM</option>
                                <option value="20:30">08:30 PM</option>
                                <option value="21:00">09:00 PM</option>
                                <option value="21:30">09:30 PM</option>
                                <option value="22:00">10:00 PM</option>
                                <option value="22:30">10:30 PM</option>
                            </select>
                        </div>
                        <div className="col col-4">
                            <label>End Time</label>
                            <select name="end_time" className="form-control" onChange={e => onInputChange(e)} value={end_time}>
                                <option value="">Select End Time</option>
                                <option value="10:00">10:00 AM</option>
                                <option value="10:30">10:30 AM</option>
                                <option value="11:00">11:00 AM</option>
                                <option value="11:30">11:30 AM</option>
                                <option value="12:00">12:00 PM</option>
                                <option value="12:30">12:30 PM</option>
                                <option value="13:00">01:00 PM</option>
                                <option value="13:30">01:30 PM</option>
                                <option value="14:00">02:00 PM</option>
                                <option value="14:30">02:30 PM</option>
                                <option value="15:00">03:00 PM</option>
                                <option value="15:30">03:30 PM</option>
                                <option value="16:00">04:00 PM</option>
                                <option value="16:30">04:30 PM</option>
                                <option value="17:00">05:00 PM</option>
                                <option value="17:30">05:30 PM</option>
                                <option value="18:00">06:00 PM</option>
                                <option value="18:30">06:30 PM</option>
                                <option value="19:00">07:00 PM</option>
                                <option value="19:30">07:30 PM</option>
                                <option value="20:00">08:00 PM</option>
                                <option value="20:30">08:30 PM</option>
                                <option value="21:00">09:00 PM</option>
                                <option value="21:30">09:30 PM</option>
                                <option value="22:00">10:00 PM</option>
                                <option value="22:30">10:30 PM</option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-4">
                            <label>Sub Category </label>

                            <select name="sub_cat_id" className="form-control" onChange={e => onInputChange(e)} value={sub_cat_id}>
                                <option value="">Select Sub Category</option>
                                {subcatgory.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                        </div>
                        <div className="col col-4">
                            <label>Status</label>
                            <select name="status" className="form-control" onChange={e => onInputChange(e)} value={status} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>
                        <div className="col col-4">
                            <label>Special </label>
                            <select name="special" className="form-control" onChange={e => onInputChange(e)} value={special}>
                                <option value="">Select Special</option>
                                {specialOption.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col col-6">
                            <label>Veg </label>
                            <Multiselect
                                displayValue="name"
                                onKeyPressFn={function noRefCheck() { }}
                                onRemove={e => onVegRemoved(e)}
                                onSearch={function noRefCheck() { }}
                                onSelect={e => onVegSelectd(e)}
                                options={vegOptions}
                                selectedValues={vegOptionsselectedValues}
                            />
                        </div>
                        <div className="col col-6">
                            <label>Non Veg </label>
                            <Multiselect
                                displayValue="name"
                                onKeyPressFn={function noRefCheck() { }}
                                onRemove={e => onNonVegRemoved(e)}
                                onSearch={function noRefCheck() { }}
                                onSelect={e => onNonVegSelectd(e)}
                                options={nogvegOptions}
                                selectedValues={nogvegOptionselectedValues}
                            />
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col col-4">
                            <label>Health </label>
                            <Multiselect
                                displayValue="name"
                                onKeyPressFn={function noRefCheck() { }}
                                onRemove={e => onHealthRemoved(e)}
                                onSearch={function noRefCheck() { }}
                                onSelect={e => onHealthSelectd(e)}
                                options={healthOption}
                                selectedValues={healthOptionsselectedValues}
                            />
                        </div>
                        <div className="col col-4">
                            <label>Allergens </label>
                            <Multiselect
                                displayValue="name"
                                onKeyPressFn={function noRefCheck() { }}
                                onRemove={e => onAllergiRemoved(e)}
                                onSearch={function noRefCheck() { }}
                                onSelect={e => onAllergiSelectd(e)}
                                options={allergenOptions}
                                selectedValues={allergenOptionsselectedValues}
                            />
                        </div>
                        <TextAreaInput id="description" label="Description" name="description" place="Description" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={description} class="col col-4" />
                    </div>


                    <div className="row">
                        <div className="col col-6">
                            <label>Icon</label>
              <p>Note: Image Size Should be <b>1280px x 1280px </b></p>


                            {showLogo ? (
                                <div className="mt-3">
                                    <img src={icon} height="200px" width="200px" className="px-2" />
                                    <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id, 'logo')}>
                                        Delete
                                    </Button>
                                </div>
                            ) : <FileUploader
                                onFileSelectSuccess={(file) => setSelectedFile(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />}
                        </div>

                        <div className="col col-6">
                            <label>Image</label>

                            {showImage ? (
                                <div className="mt-3">
                                    <img src={icon} height="200px" width="200px" className="px-2" />
                                    <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id, 'image')}>
                                        Delete
                                    </Button>
                                </div>
                            ) : <FileUploader
                                onFileSelectSuccess={(file) => setSelectedFileImage(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />}
                        </div>

                    </div>


                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                {/* <Button type="submit" variant="contained" size="small">
                                    Save
                                </Button> */}
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div></div>


                </form>
            </Card>

        </div>
    );

}

export default EditRestDish;
