import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import { Button } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import { endpoints } from "../../../services/apiurl";
import Input from "../../UI/Input/Input";
import TextAreaInput from "../../UI/Input/TextAreaInput";
import Card from "@mui/material/Card";
import FileUploader from "../../FileUploader";
import DeleteIcon from '@mui/icons-material/Delete';


const EditRestaurents = () => {
    let history = useHistory();
    let { id } = useParams();

    const [cat, setCat] = useState({
        name: '',
        latitude: '',
        longitude: '',
        address: '',
        city: '',
        // location: '',
        open_time: '',
        description: '',
        instagram_url: '',
        facebook_url: '',
        youtube_url: '',
        razorpay_key: '',
        razorpay_secret: '',
        logo: '',
        image: '',
        web_image: '',
        app_image: '',
        app_logo: '',
        status: '',
        showImage: '',
        showLogo: '',
        showWebImage: '',
        showAppLogo: '',
        showAppImage: '',
        pos_id: '',
        text_color: '',
        color_code: '',
        calories: '',
        feedback_link: ''
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileImage, setSelectedFileImage] = useState(null);
    const [selectedwebImage, setSelectedwebImage] = useState(null);
    const [selectedappImage, setSelectedappImage] = useState(null);
    const [selectedappLogo, setSelectedappLogo] = useState(null);

    const [showapp, setShowapp] = useState(0);
    const [showweb, setShowweb] = useState(0);

    const [loading, setloading] = useState(false);

    const { name, latitude, logo, image, web_image, app_image, app_logo, pos_id, calories, longitude, address, city, open_time, description, instagram_url, facebook_url, youtube_url, website_url, android_url, ios_url, razorpay_key, razorpay_secret, status, showImage, showLogo, showWebImage, showAppLogo, showAppImage, color_code, text_color, feedback_link } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    useEffect(() => {
        edit();
    }, []);
    console.log(id)
    const edit = async () => {
        await axios.post(endpoints.editRestaurents, { id: id }).then((response) => {

            setCat({
                id: response.data.data.id,
                name: response.data.data.name,
                latitude: response.data.data.latitude,
                longitude: response.data.data.longitude,
                address: response.data.data.address,
                city: response.data.data.city,
                open_time: response.data.data.open_time,
                description: response.data.data.description,
                instagram_url: response.data.data.instagram_url != 'null' ? response.data.data.instagram_url : '',
                facebook_url: response.data.data.facebook_url != 'null' ? response.data.data.facebook_url : '',
                youtube_url: response.data.data.youtube_url != 'null' ? response.data.data.youtube_url : '',
                website_url: response.data.data.website_url != 'null' ? response.data.data.website_url : '',
                android_url: response.data.data.android_url != 'null' ? response.data.data.android_url : '',
                ios_url: response.data.data.ios_url != 'null' ? response.data.data.ios_url : '',
                razorpay_key: response.data.data.razorpay_key != 'null' ? response.data.data.razorpay_key : '',
                razorpay_secret: response.data.data.razorpay_secret != 'null' ? response.data.data.razorpay_secret : '',
                image: response.data.data.image,
                web_image: response.data.data.web_image,
                status: response.data.data.status,
                pos_id: response.data.data.pos_id,
                calories: response.data.data.calories,
                feedback_link: response.data.data.feedback_link,
                logo: response.data.data.logo,
                app_logo: response.data.data.app_logo,
                app_image: response.data.data.app_image,
                showImage: response.data.data.showImage,
                showLogo: response.data.data.showLogo,
                showWebImage: response.data.data.showWebImage,
                showAppLogo: response.data.data.showAppLogo,
                showAppImage: response.data.data.showAppImage,
                color_code: response.data.data.color_code,
                text_color: response.data.data.text_color,
            });
            console.log(response.data.data.id,)
            setShowapp(response.data.data.show_app);
            setShowweb(response.data.data.show_web);
        });
    }

    const setStatus = async (e, type) => {
        if (e.target.checked) {
            var status = 1
        }
        else {
            var status = 0
        }
        if (type == 'app') {
            setShowapp(status);
        } else {
            setShowweb(status);
        }

    }

    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        // if (!selectedFile) {
        //     alert('Please select icon');
        //     return false;
        // }
        // if (!selectedFileImage) {
        //     alert('Please select image');
        //     return false;
        // }
        const formData = new FormData();
        formData.append("name", name);
        formData.append("latitude", latitude);
        formData.append("longitude", longitude);
        formData.append("address", address);
        formData.append("city", city);
        formData.append("color_code", color_code);
        formData.append("text_color", text_color);
        formData.append("open_time", open_time);
        formData.append("description", description);
        formData.append("instagram_url", instagram_url);
        formData.append("facebook_url", facebook_url);
        formData.append("youtube_url", youtube_url);
        formData.append("website_url", website_url);
        formData.append("android_url", android_url);
        formData.append("ios_url", ios_url);
        formData.append("razorpay_key", razorpay_key);
        formData.append("razorpay_secret", razorpay_secret);
        formData.append("pos_id", pos_id);
        formData.append("logo", selectedFile);
        formData.append("image", selectedFileImage);
        formData.append("web_image", selectedwebImage);
        formData.append("app_logo", selectedappLogo);
        formData.append("app_image", selectedappImage);
        formData.append("status", status);
        formData.append("id", id);
        formData.append("calories", calories);
        formData.append("feedback_link", feedback_link);
        formData.append("show_web", showweb);
        formData.append("show_app", showapp);

        await axios.post(endpoints.updateRestaurents, formData).then((response) => {
            setloading(false);
            history.goBack();
            // if (response.data.status == 200) {
            //     // toast.success(response.data.message);
            //     history.goBack();
            // } else {
            //     // toast.error(response.data.message);
            // }
        });
    }

    const removeImageHandler = async (id, type) => {
        await axios.post(endpoints.deleteImageRest, { id, type }).then((response) => {
        });
        window.location.reload();
    };

    return (
        <div className="ui main">
            <h2>Edit Restaurant</h2>
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <div className="row">
                        <Input id="name" label="Name" type="text" name="name" place="Name" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={name} class="col col-6"
                        />

                        <Input id="pos_id" label="Pos ID" type="text" name="pos_id" place="Pos ID" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={pos_id} class="col col-6"
                        />
                    </div>

                    <div className="row">
                        <TextAreaInput id="address" label="Address" name="address" place="Address" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={address} class="col col-6" />
                        <TextAreaInput id="description" label="Description" name="description" place="Description" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={description} class="col col-6" />

                    </div>

                    <div className="row">

                        <Input id="latitude" label="Latitude" type="text" name="latitude" place="Latitude" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={latitude} class="col col-4"
                        />

                        <Input id="longitude" label="Longitude" type="text" name="longitude" place="Longitude" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={longitude} class="col col-4"
                        />
                        <Input id="calories" label="Calories" type="text" name="calories" place="Calories" onChange={(e) => onInputChange(e)}
                            value={calories} class="col col-4"
                        />
                    </div>
                    <div className="row">

                        <Input id="city" label="City" type="text" name="city" place="City" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={city} class="col col-4"
                        />
                        <Input id="open_time" label="Open Time" type="text" name="open_time" place="Open Time" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={open_time} class="col col-4"
                        />

                        <Input id="instagram_url" label="Instagram Url" type="text" name="instagram_url" place="Instagram Url" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={instagram_url} class="col col-4"
                        />

                    </div>





                    <div className="row">
                        <Input id="facebook_url" label="Facebook Url" type="text" name="facebook_url" place="Facebook Url" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={facebook_url} class="col col-4"
                        />

                        <Input id="youtube_url" label="Youtube Url" type="text" name="youtube_url" place="Youtube Url" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={youtube_url} class="col col-4"
                        />
                        <Input id="feedback_link" label="Feedback Link" type="text" name="feedback_link" place="Feedback Link" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={feedback_link} class="col col-4"
                        />
                    </div>
                    <div className="row">
                        <Input id="website_url" label="Website Url" type="text" name="website_url" place="Website Url" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={website_url} class="col col-4"
                        />

                        <Input id="android_url" label="Android App Url" type="text" name="android_url" place="Android App Url" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={android_url} class="col col-4"
                        />
                        <Input id="ios_url" label="IOS App Url" type="text" name="ios_url" place="IOS App Url" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={ios_url} class="col col-4"
                        />
                    </div>
                    <div className="row">
                        <Input id="razorpay_key" label="Razorpay Key" type="text" name="razorpay_key" place="Razorpay Key" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={razorpay_key} class="col col-4"
                        />

                        <Input id="razorpay_secret" label="Razorpay Secret" type="text" name="razorpay_secret" place="Razorpay Secret" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={razorpay_secret} class="col col-4"
                        />
                        <div className="col col-4">
                            <label>Status</label>
                            <select name="status" required className="form-control" onChange={e => onInputChange(e)} value={status}>
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-4">
                            <label>Logo</label>
                            <p>Note: Image Size Should be <b>268 x 70px </b></p>

                            {showLogo ? (
                                <div className="mt-3">
                                    <img src={logo} height="200px" width="200px" className="px-2" />
                                    <Button variant="outlined" color="error" size="small" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id, 'logo')}>
                                        {/* Delete */}
                                    </Button>
                                </div>
                            ) :
                                <FileUploader
                                    onFileSelectSuccess={(file) => setSelectedFile(file)}
                                    onFileSelectError={({ error }) => alert(error)}
                                />}

                        </div>

                        <div className="col col-4">
                            <label>Home Image</label>
                            <p>Note: Image Size Should be <b>513 x 418px </b></p>
                            {showImage ? (
                                <div className="mt-3">
                                    <img src={image} height="200px" width="200px" className="px-2" />
                                    <Button variant="outlined" color="error" size="small" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id, 'image')}>

                                    </Button>
                                </div>
                            ) : <FileUploader
                                onFileSelectSuccess={(file) => setSelectedFileImage(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />
                            }
                        </div>
                        <div className="col col-4">
                            <label>Inner Image</label>
                            <p>Note: Image Size Should be <b>513 x 418px </b></p>

                            {showWebImage ? (
                                <div className="mt-3">
                                    <img src={web_image} height="200px" width="200px" className="px-2" />
                                    <Button variant="outlined" color="error" size="small" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id, 'web_image')}>

                                    </Button>
                                </div>
                            ) : <FileUploader
                                onFileSelectSuccess={(file) => setSelectedwebImage(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />
                            }
                        </div>
                    </div>



                    <div className="row">
                        {/* <div className="col col-4">
                            <label>Logo ( For App )</label>
                            {showAppLogo ? (
                                <div className="mt-3">
                                    <img src={app_logo} height="200px" width="200px" className="px-2" />
                                    <Button variant="outlined" color="error" size="small" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id, 'app_logo')}>

                                    </Button>
                                </div>
                            ) : <FileUploader
                                onFileSelectSuccess={(file) => setSelectedappLogo(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />
                            }

                        </div>

                        <div className="col col-4">
                            <label> Image ( For App )</label>
                            {showAppImage ? (
                                <div className="mt-3">
                                    <img src={app_image} height="200px" width="200px" className="px-2" />
                                    <Button variant="outlined" color="error" size="small" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id, 'app_image')}>

                                    </Button>
                                </div>
                            ) : <FileUploader
                                onFileSelectSuccess={(file) => setSelectedappImage(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />
                            }

                        </div>
                        <div className="col col-4">
                            <label>
                                <input type="checkbox" style={{marginTop:'40px'}}
                                    checked={showapp === 1 ? true : false}
                                    onChange={(e) => setStatus(e, 'app')}
                                />
                                Status Show (App)
                            </label>

                        </div> */}

                    </div>

                    <div className="row">
                        <Input id="color_code" label="Color Code" type="text" name="color_code" place="Color Code" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={color_code} class="col col-4"
                        />
                        <Input id="text_color" label="Text Color" type="text" name="text_color" place="Text Color" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={text_color} class="col col-4"
                        />
                        <div className="col col-4">
                            <label>
                                <input type="checkbox" style={{ marginTop: '40px' }}
                                    checked={showweb === 1 ? true : false}
                                    onChange={(e) => setStatus(e, 'web')}
                                />
                                Status Show (Web)
                            </label>

                        </div>

                    </div>






                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                {/* <Button type="submit" variant="contained" size="large">
                                    Save
                                </Button> */}
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div></div>


                </form>

                {/* <div className="row">
                    <div className="col col-6">
                        <label>Logo</label>
                        {showLogo ? (
                            <div className="mt-3">
                                <img src={logo} height="200px" width="200px" className="px-2" />
                                <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id)}>
                                    Delete
                                </Button>
                            </div>
                        ) : ''}

                    </div>
                    <div className="col col-6">
                        {showImage ? (
                            <div className="mt-3">
                                <img src={image} height="200px" width="200px" className="px-2" />
                                <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id)}>
                                    Delete
                                </Button>
                            </div>
                        ) : ''}
                    </div>
                </div> */}
            </Card>

        </div>
    );
}

export default EditRestaurents;
