/* eslint-disable no-undef */
import React, { } from "react";
import axios from "axios";
import { endpoints } from "../../../../services/apiurl";
import Form from 'react-bootstrap/Form';
import {
  Link,
} from "react-router-dom";
import { withRouter } from "react-router";

import { Card,Button } from "@mui/material";
import PageTitle from "../../../UI/PageTitle";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "../../../../../node_modules/react-confirm-alert/src/react-confirm-alert.css";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';


import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';
import Input from "../../../UI/Input/Input";


// const DishRestDetail = () => {
//     let history = useHistory();
//     let { cat_id } = useParams();
//     const [error, setError] = useState(null);
//     const [isLoaded, setIsLoaded] = useState(false);
//     const [items, setItems] = useState([]);
//     const [totalRows, setTotalRows] = useState(0);
//     const [perPage, setPerPage] = useState(10);

//     useEffect(() => {
//         fetchData(1, perPage);
//     }, [perPage])

//     const columns = [
//         {
//             name: 'NAME',
//             selector: row => row.name,
//             // width: '200px'
//         },
//         {
//             name: 'PRICE',
//             selector: row => row.price,
//             // width: '200px'
//         }, {
//             name: 'STATUS',
//             selector: row => row.status == 1 ? 'Active' : 'Inactive',
//             // width: '200px'
//         },
//         {
//             name: 'ACTION',
//             // cell: row => <div>  <Link to={`/dashboard/editrestdish/${row.id}`}> <EditIcon /> </Link>
//             //  <Link className="trash alternate outline icon" style={{ color: "red", marginTop: "7px", marginLeft: "10px" }} onClick={() => removeContactHandler(row.id)}> <DeleteIcon /></Link> </div>,
//             cell: row => <div>  <Link to={`/dashboard/editrestdish/${row.id}`}> <EditIcon /> </Link>
//             <Link className="trash alternate outline icon" style={{ color: "red", marginTop: "7px", marginLeft: "10px" }} onClick={()=>submit(row.id)}> <DeleteIcon /></Link> </div>,

//             selector: row => row.id,
//             // width: '200px'

//             //
//         },
//     ];

// const submit = async (id) => {
//     confirmAlert({
//       title: 'DELETE',
//       message: 'Are you sure you want to Delete it ?',
//       buttons: [
//         {
//           label: 'Yes',
//           onClick: () => removeContactHandler(id)
//         },
//         {
//           label: 'No',
//         }
//       ]
//     });

//   };

// const removeContactHandler = async (id) => {
//     await axios.post(endpoints.deleteDish, { id }).then((response) => {
//     });
//     const newContactList = items.filter((contact) => {
//         return contact.id !== id;
//     });
//     setItems(newContactList);
// };

//     const fetchData = async (page, per_page) => {
//         await axios
//             .post(endpoints.getDish, {
//                 cat_id,
//                 page,
//                 per_page,
//             })
//             .then((response) => {
//                 setIsLoaded(true);
//                 setItems(response.data.data);
//                 setTotalRows(response.data.total);
//             },
//                 (error) => {
//                     setIsLoaded(true);
//                     setError(error);
//                 }

//             );
//     }

//     const handlePageChange = page => {
//         fetchData(page, perPage);
//     }

//     const handlePerRowsChange = async (newPerPage, page) => {
//         setPerPage(newPerPage);
//     }

//     if (error) {
//         return <div>Error: {error.message}</div>;
//     } else if (!isLoaded) {
//         return <div>Loading...</div>;
//     } else {
//         return (
//             <div className="App">
//                 <PageTitle title="Dish List" linkid={cat_id} linkurl="/dashboard/addrestdish" linkTitle="Add Dish" />
//                 <Card className="px-3 py-3">
//                     <DataTable
//                         columns={columns}
//                         data={items}
//                         pagination
//                         paginationServer
//                         dragableCell
//                         // paginationTotalRows={totalRows}
//                         onChange={({ oldIndex, newIndex }) =>
//                             setItems(arrayMove( items , oldIndex, newIndex))
//                         }
//                         renderList={({ children, props }) => <ul {...props}>{children}</ul>}
//                         renderItem={({ value, props }) => <li {...props}>{value}</li>}
//                         onChangePage={handlePageChange}
//                         onChangeRowsPerPage={handlePerRowsChange}
//                     />
//                 </Card>
//             </div>

//         );
//     }
// }

const getItems = (ii) =>
  Array.from({ length: ii.length }, (v, k) => k).map((k) => ({
    id: `${ii[k].id}`,
    name: ii[k].name,
    price: ii[k].price,
    second_price: ii[k].second_price,

    status: ii[k].status,

    // price: ii[k].price,

    // last: ii[k].id,
    content: `item ${k}`,
  }));



// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


class DishRestDetail extends React.Component {
  // Constructor
  constructor(props) {
    super(props);

    // console.log(this.props);
    this.state = {
      items: [],
      DataisLoaded: false,

      currentPage: 1,
      totalPages: 1,
      per_page: 10,
      cat_id: this.props.match.params.cat_id
    };

  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    var cat_id = this.state.cat_id;
    var page = this.state.currentPage;
    var per_page = this.state.per_page;
    axios
      .post(endpoints.getDish, {
        page,
        per_page,
        cat_id,
      })
      .then((response) => {
        // console.log("response", response);
        this.setState({
          items: getItems(response.data.data),
          totalPages: response.data.total_pages,
          local: localStorage.setItem(
            "data",
            JSON.stringify(response.data.data)
          ),
          DataisLoaded: true,
        });
        // console.log(response.data.data);
      });
  }

  onDragEnd(result) {
    let aa = localStorage.getItem("data");
    let bb = JSON.parse(aa);
    let to = result.destination.index;
    let from = result.source.index;
    let destination = bb[result.destination.index].id;
    let source = result.draggableId;
    let cat_id = result.destination.droppableId;

    let a = localStorage.getItem("user");
    let b = JSON.parse(a);

    axios
      .post(endpoints.dishcatupdateSorting, {
        source,
        destination,
        to,
        from,
        cat_id,
      })
      .then((response) => {
        window.location.reload(true);
        // console.log("response", response);
        this.setState({
          items: getItems(response.data.data),

          DataisLoaded: true,
        });
      });

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );
    // console.log(getItems(10))

    this.setState({
      items,
    });
  }

  render() {
    const { DataisLoaded, items } = this.state;
    const removeContactHandler = async (id) => {
      await axios.post(endpoints.deleteDish, { id }).then((response) => { });
      const newContactList = items.filter((contact) => {
        return contact.id !== id;
      });
      window.location.reload(true);
    };

    const sub = async (id, old_status) => {
      if (old_status === '1') {
        var status = 0
      }
      else {
        // eslint-disable-next-line no-redeclare
        var status = 1
      }



      axios
        .post(endpoints.updateDishStatus, {
          id,
          status
        })
        .then((response) => {
          window.location.reload(true);
          // console.log("response", response);
          this.setState({
            // items: getItems(response.data.data),
            DataisLoaded: true,
          });
        });



    }

    const submit = async (id) => {
      confirmAlert({
        title: "DELETE",
        message: "Are you sure you want to Delete it ?",
        buttons: [
          {
            label: "Yes",
            onClick: () => removeContactHandler(id),
          },
          {
            label: "No",
          },
        ],
      });
    };

    const onNameChange = e => {
      this.name = e.target.value
     
    }
 
  const bFilter = async ()=>{
      console.log(this.name);
      var name = this.name
      var cat_id = this.state.cat_id
      // var restro_id = this.state.restro_id;
      var page = this.state.currentPage;
      var per_page = this.state.per_page;
      // if(this.rest===undefined){
      //   var rest = ''
      // }
      // else{
      //   var rest = this.rest
      // }
      
      // console.log('working_rest',rest)
      // var type = this.type
  
  
      // let a  = localStorage.getItem('user')
      // let b = JSON.parse(a)
      // let user_id = b.id
      // let restro_id = b.restro_id
  
      axios
        .post(endpoints.dishFilter, {
          page,
          per_page,
         name , cat_id   
        })
        .then((response) => {
          // window.location.reload(true);
          this.setState({
              items: getItems(response.data.data),
              totalPages: response.data.total_pages,
              local: localStorage.setItem(
                  "data",
                  JSON.stringify(response.data.data)
              ),
              DataisLoaded: true,
          });
          console.log(response.data);
        });
  
    }

    const setCurrentPage = (id) => {
      this.state.currentPage = id;
      this.fetchData();
    }

    const onSelectChange = (per_page) => {
      this.state.per_page = per_page;
      this.fetchData();
    }

    return (
      <div className="App">
        <div className="col col-6">
          <label>Row Per Page </label>
          <select
            name="type"
            className="form-control"
            onChange={(e) => onSelectChange(e.target.value)}
            value={this.state.per_page}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <PageTitle
          title="Dish List"
          linkid={this.props.match.params.cat_id}
          linkurl="/dashboard/addrestdish"
          linkTitle="Add Dish"
        />
         <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                  <div className='row'>
                 
                  <div className='col-md-3'>

                    <Input id="name" label="Name" type="text" name="name" place="Dish Name"
                        requiredInput="required" className='col-md-6' onChange={(e) => onNameChange(e)}
                    />

                    
                    </div>
                  
                        <div className="col col-3">
                            <div className="mt-5 text-center">
                                <Button className='w-50' onClick={()=>bFilter()} variant="contained" size="medium">
                                    Filter
                                </Button>
                            </div></div></div>


                </form>
                 </Card>
        <Card className="m-3">
          {/* <PageTitle title="Restaurants List" linkid={cat_id} linkurl="/dashboard/addrestaurents" linkTitle="Add Restaurants" /> */}
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId={this.props.match.params.cat_id}>
              {(provided) => (
                <table
                  {...provided.droppableProps}
                  className="table "
                  ref={provided.innerRef}
                >
                  <tbody>
                    <tr className="text-center">
                      <th></th>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                    {this.state.items.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided) => (
                          <tr
                            className="text-center"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td><DragIndicatorIcon /></td>

                            <td>{item.name}</td>
                            <td>
                              {/* {item.price} */}
                              {item.price == null ? item.second_price : item.price}
                            </td>

                            <td>

                              <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id={item.id + 'check'}
                                  {...(item.status === '1' ? { checked: 'checked' } : {})}


                                  onClick={() => sub(item.id, item.status)} />
                                <label class="custom-control-label" for={item.id + 'check'} ></label>
                              </div>

                            </td>

                            <td className="text-center">
                              <Link to={`/dashboard/editrestdish/${item.id}`}>
                                {" "}
                                <EditIcon />{" "}
                              </Link>
                              <Link
                                className="trash alternate outline icon"
                                style={{
                                  color: "red",
                                  marginTop: "7px",
                                  marginLeft: "10px",
                                }}
                                onClick={() => submit(item.id)}
                              >
                                {" "}
                                <DeleteIcon />
                              </Link>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                </table>
              )}
            </Droppable>
          </DragDropContext>
        </Card>

        <Pagination
          {...bootstrap5PaginationPreset}
          current={this.state.currentPage}
          total={this.state.totalPages}
          onPageChange={(e) => setCurrentPage(e)}
        />
      </div>
    );
  }
}

export default withRouter(DishRestDetail);
