import React from "react";
import TextareaAutosize from '@mui/material/TextareaAutosize';


const TextAreaInput = props => {

    return <>
        {/* <div className="row"> */}
        <div className={props.class}>
            <label>{props.label}</label>
            <TextareaAutosize
                minRows={3}
                className="form-control"
                placeholder={props.place}
                value={props.value}
                name={props.name}
                onChange={props.onChange}
            />
        </div>
        {/* </div> */}
    </>


}

export default TextAreaInput;