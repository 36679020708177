import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Redirect, Switch, Route, Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";

import { endpoints } from "../../../services/apiurl";

import LinkButton from '../../UI/Input/LinkButton';
import { Card, Button } from '@mui/material';
import PageTitle from '../../UI/PageTitle';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const PointDetail = () => {
    let history = useHistory();
    let { restro_id } = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        fetchData(1, perPage);
    }, [perPage])



    const columns = [
        {
            name: 'NAME',
            selector: row => row.name,
        },
        {
            name: 'STATUS',
            selector: row => row.status == 1 ? 'Active' : 'Inactive',
            // width: '200px'
        }, {
            name: 'ACTION',
            cell: row => <div>  <Link to={`/dashboard/editpoint/${row.id}`}> <EditIcon /> </Link>
                <Link className="trash alternate outline icon" style={{ marginTop: "7px", marginLeft: "10px" }} onClick={() => removeContactHandler(row.id)}> <DeleteIcon /> </Link>
            </div>,
            selector: row => row.id,
            // width: '200px'
        },
    ];


    const removeContactHandler = async (id) => {
        await axios.post(endpoints.deletePoints, { id }).then((response) => {
        });
        const newContactList = items.filter((contact) => {
            return contact.id !== id;
        });
        setItems(newContactList);
    };


    const fetchData = async (page, per_page) => {
        await axios
            .post(endpoints.getPoints, {
                page,
                per_page,
            })
            .then((response) => {
                setIsLoaded(true);
                setItems(response.data.data);
                setTotalRows(response.data.total);
            },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }

            );
    }

    const handlePageChange = page => {
        fetchData(page, perPage);
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }


    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (

            <div className="App">
                <PageTitle title="Point Card List" linkid={restro_id} linkurl="/dashboard/addpoint" linkTitle="Add Card" />
                <Card className="px-3 py-3">
                    <DataTable
                        columns={columns}
                        data={items}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                    />
                </Card>
            </div>


        );
    }
}

export default PointDetail;