import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Redirect, Switch, Route, Link } from "react-router-dom";
import { endpoints } from "../../services/apiurl";


import LinkButton from '../UI/Input/LinkButton';
import { Card } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import PageTitle from '../UI/PageTitle';

const TagsDetail = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        fetchData(1, perPage);
    }, [perPage])


    const columns = [
        {
            name: 'NAME',
            selector: row => row.name,
            width: '200px'
        },
        {
            name: 'SHORT NAME',
            selector: row => row.short_name,
            width: '200px'
        },
        {
            name: 'COLOR CODE',
            selector: row => row.color_code,
            width: '200px'
        }, {
            name: 'TYPE',
            selector: row => row.type == 3 ? 'Health' : row.type == 4 ? 'Special' : 'Allergens',
            width: '200px'
        }, {
            name: 'ACTION',
            cell: row => <div>  <Link to={`/dashboard/edittags/${row.id}`}> <EditIcon /> </Link>
                {/* <Link className="trash alternate outline icon" style={{ color: "red", marginTop: "7px", marginLeft: "10px" }} onClick={() => removeContactHandler(row.id)}><DeleteIcon /></Link>  */}
            </div>,
            selector: row => row.id,
            width: '200px'
        },
    ];



    const removeContactHandler = async (id) => {
        await axios.post(endpoints.deleteTag, { id }).then((response) => {
        });
        const newContactList = items.filter((contact) => {
            return contact.id !== id;
        });
        setItems(newContactList);
    };


    const fetchData = async (page, per_page) => {
        await axios
            .post(endpoints.getTags, {
                page,
                per_page,
            })
            .then((response) => {
                setIsLoaded(true);
                setItems(response.data.data);
                setTotalRows(response.data.total);
            },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }

            );
    }

    const handlePageChange = page => {
        fetchData(page, perPage);
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }


    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (

            <div className="App">
                <PageTitle title="Menu Tag List" linkurl="/dashboard/addtags" linkTitle="Add Tag" />
                <Card className="px-3 py-3">
                    <DataTable
                        columns={columns}
                        data={items}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                    />
                </Card>
            </div>


        );
    }
}

export default TagsDetail;