import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Redirect, Switch, Route, Link } from "react-router-dom";
import { endpoints } from "../../../../services/apiurl";
import { useHistory, useParams } from "react-router-dom";
import { withRouter } from "react-router";

import LinkButton from "../../../UI/Input/LinkButton";
import { Card } from "@mui/material";
import PageTitle from "../../../UI/PageTitle";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';


// const SubCategoryDetail = () => {
//     let history = useHistory();
//     let { cat_id } = useParams();
//     const [error, setError] = useState(null);
//     const [isLoaded, setIsLoaded] = useState(false);
//     const [items, setItems] = useState([]);
//     const [totalRows, setTotalRows] = useState(0);
//     const [perPage, setPerPage] = useState(10);

//     useEffect(() => {
//         fetchData(1, perPage);
//     }, [perPage])

//     const columns = [
//         // {
//         //     name: 'ID',
//         //     selector: row => row.indexID,
//         //     width: '100px'
//         // },
//         // {
//         //     name: 'Image',
//         //     cell: row => <img src={row.icon} width={50} height={50} alt={row.name}></img>,
//         //     selector: row => row.icon,
//         //     width: '100px'
//         // },
//         {
//             name: 'NAME',
//             selector: row => row.name,
//         }, {
//             name: 'STATUS',
//             selector: row => row.status == 1 ? 'Active' : 'Inactive',
//         }, {
//             name: 'ACTION',
//             cell: row => <div>  <Link to={`/dashboard/editsubcategory/${row.id}`}> <EditIcon /> </Link>
//                 {/* <Link className="trash alternate outline icon" style={{ color: "red", marginTop: "7px", marginLeft: "10px" }} onClick={() => removeContactHandler(row.id)}> <DeleteIcon /> </Link>  */}
//             </div>,
//             selector: row => row.id,
//         },
//     ];

//     const removeContactHandler = async (id) => {
//         await axios.post(endpoints.deleteSubCategory, { id }).then((response) => {
//         });
//         // window.location.reload();
//         const newContactList = items.filter((contact) => {
//             return contact.id !== id;
//         });
//         setItems(newContactList);
//     };

//     const fetchData = async (page, per_page) => {
//         await axios
//             .post(endpoints.getSubCategory, {
//                 // .get(`https://www.mecallapi.com/api/attractions?page=${page}&per_page=${per_page}`, {
//                 cat_id,
//                 page,
//                 per_page,
//             })
//             .then((response) => {
//                 // console.log('response', response);
//                 setIsLoaded(true);
//                 setItems(response.data.data);
//                 setTotalRows(response.data.total);
//             }
//                 ,
//                 (error) => {
//                     setIsLoaded(true);
//                     setError(error);
//                 }

//             );
//     }

//     const handlePageChange = page => {
//         fetchData(page, perPage);
//     }

//     const handlePerRowsChange = async (newPerPage, page) => {
//         setPerPage(newPerPage);
//     }

//     if (error) {
//         return <div>Error: {error.message}</div>;
//     } else if (!isLoaded) {
//         return <div>Loading...</div>;
//     } else {
//         return (

//             <div className="App">
//                 <PageTitle title="Sub Category List" linkid={cat_id} linkurl="/dashboard/addsubcategory" linkTitle="Add Sub Category" />
//                 <Card className="px-3 py-3">
//                     <DataTable
//                         columns={columns}
//                         data={items}
//                         pagination
//                         paginationServer
//                         paginationTotalRows={totalRows}
//                         onChangePage={handlePageChange}
//                         onChangeRowsPerPage={handlePerRowsChange}
//                     />
//                 </Card>
//             </div>
//         );
//     }
// }


const getItems = (ii) =>
  Array.from({ length: ii.length }, (v, k) => k).map((k) => ({
    id: `${ii[k].id}`,
    name: ii[k].name,
    status: `${ii[k].status}`,
    // last: ii[k].id,
    content: `item ${k}`,
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// console.log(localStorage.getItem("user"));

class SubCategoryDetail extends React.Component {
  // Constructor
  constructor(props) {
    super(props);

    // console.log(this.props);
    this.state = {
      items: [],
      DataisLoaded: false,

      currentPage: 1,
      totalPages: 1,
      per_page: 10,
      cat_id: this.props.match.params.cat_id

    };
  }

  // ComponentDidMount is used to
  // execute the code
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    var cat_id = this.state.cat_id;
    var page = this.state.currentPage;
    var per_page = this.state.per_page;
    axios
      .post(endpoints.getSubCategory, {
        page,
        per_page,
        cat_id,
      })
      .then((response) => {
        this.setState({
          items: getItems(response.data.data),
          totalPages: response.data.total_pages,
          local: localStorage.setItem(
            "data",
            JSON.stringify(response.data.data)
          ),
          DataisLoaded: true,
        });
        // console.log(response.data.data);
      });
  }


  onDragEnd(result) {
    // console.log(localStorage.getItem('data'))
    let aa = localStorage.getItem("data");
    let bb = JSON.parse(aa);
    let destination = bb[result.destination.index].id;
    let source = result.draggableId;
    let cat_id = result.destination.droppableId;
    let to = result.destination.index;
    let from = result.source.index;

    let a = localStorage.getItem("user");
    let b = JSON.parse(a);

    axios
      .post(endpoints.subcatupdateSorting, {
        source,
        destination,
        to,
        from,
        cat_id,
      })
      .then((response) => {
        window.location.reload(true);
        // console.log("response", response);
        this.setState({
          items: getItems(response.data.data),
          DataisLoaded: true,
        });
      });

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });
  }

  render() {
    const { DataisLoaded, items } = this.state;
    const sub = async (id, old_status) => {
      if (old_status === '1') {
        var status = 0
      }
      else {
        var status = 1
      }

      axios.post(endpoints.updateSubCatStatus, {
        id,
        status
      })
        .then((response) => {
          window.location.reload(true);
          this.setState({
            DataisLoaded: true,
          });
        });
    }

    const setCurrentPage = (id) => {
      this.state.currentPage = id;
      this.fetchData();
    }

    const onSelectChange = (per_page) => {
      this.state.per_page = per_page;
      this.fetchData();
    }

    return (
      <div className="App">
        <div className="col col-6">
          <label>Row Per Page </label>
          <select
            name="type"
            className="form-control"
            onChange={(e) => onSelectChange(e.target.value)}
            value={this.state.per_page}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <PageTitle
          title="Sub Category List"
          linkid={this.props.match.params.cat_id}
          linkurl="/dashboard/addsubcategory"
          linkTitle="Add Sub Category"
        />
        <Card className="m-3">
          {/* <PageTitle title="Restaurants List" linkid={cat_id} linkurl="/dashboard/addrestaurents" linkTitle="Add Restaurants" /> */}
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId={this.props.match.params.cat_id}>
              {(provided) => (
                <table
                  {...provided.droppableProps}
                  className="table "
                  ref={provided.innerRef}
                >
                  <tbody>
                    <tr className="text-center">
                      <th></th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                    {this.state.items.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided) => (
                          <tr
                            className="text-center"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td><DragIndicatorIcon /></td>
                            <td>{item.name}</td>
                            <td>

                              <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id={item.id + 'check'}
                                  {...(item.status === '1' ? { checked: 'checked' } : {})}


                                  onClick={() => sub(item.id, item.status)} />
                                <label class="custom-control-label" for={item.id + 'check'} ></label>
                              </div>

                            </td>

                            <td className="text-center">

                              <Link
                                to={`/dashboard/editsubcategory/${item.id}`}
                              >
                                {" "}
                                <EditIcon />{" "}
                              </Link>

                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                </table>
              )}
            </Droppable>
          </DragDropContext>
        </Card>
        <Pagination
          {...bootstrap5PaginationPreset}
          current={this.state.currentPage}
          total={this.state.totalPages}
          onPageChange={(e) => setCurrentPage(e)}
        />
      </div>
    );
  }
}

export default withRouter(SubCategoryDetail);
