/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import '../../App.css';
import { MDBDataTable } from "mdbreact";
import { display } from '@mui/system';
import DataTable from "react-data-table-component";
import birthGif from '../../assets/gift.gif';
import anniversaryGif from '../../assets/wine.gif';
import Input from "../UI/Input/Input";
import axios from "axios";
import { endpoints } from "../../services/apiurl";

import { withRouter } from "react-router";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


import { Box, Button, Card, TextField } from '@mui/material';
import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';
import { MonthPicker } from '@mui/lab';
import moment from 'moment';

class EventLeads extends React.Component {
  // Constructor
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      items: [],
      DataisLoaded: false,
      restaurent: [],
      restaurent_outlet: [],
      restro_id: '',
      start_date: '',
      rest: '',
      end_date: '',
      type: '',
      currentPage: 1,
      totalPages: 1,
      total: '',
      per_page: 10,
    };
  }

  componentDidMount() {
    this.fetchData();
    this.fetchRestData();
    this.fetchOutletData();
  }


  fetchData() {
    let a = localStorage.getItem('user')
    let b = JSON.parse(a)
    let user_id = b.id
    let type = '1'

    let restro_id = b.restro_id
    var page = this.state.currentPage;
    var per_page = this.state.per_page;
    axios
      .post(endpoints.getBooking, {
        restro_id, type


      })
      .then((response) => {
        // window.location.reload(true);
        console.log("response", response);
        this.setState({
          items: response.data.data,
          total: response.data.total,
          totalPages: response.data.total_pages,
          DataisLoaded: true,
        });
        console.log(response.data.data);
      });
  }
  fetchRestData() {
    let a = localStorage.getItem('user')
    let b = JSON.parse(a)
    let user_id = b.id
    let restro_id = b.restro_id
    var page = this.state.currentPage;
    var per_page = this.state.per_page;
    axios
      .post(endpoints.getRestaurentsDropDown, {


      })
      .then((response) => {
        // window.location.reload(true);
        console.log("response", response);
        this.setState({
          restaurent: response.data.data,
          total: response.data.total,
          totalPages: response.data.total_pages,
          DataisLoaded: true,
        });
        // console.log(response.data);


      });
  }

  fetchOutletData() {
    let a = localStorage.getItem('user')
    let b = JSON.parse(a)
    let user_id = b.id
    let restro_id = b.restro_id
    console.log(restro_id)
    var page = this.state.currentPage;
    var per_page = this.state.per_page;
    axios
      .post(endpoints.getOutlet, {

        restro_id, user_id
      })
      .then((response) => {
        // window.location.reload(true);
        console.log("response", response);
        this.setState({
          restaurent_outlet: response.data.data,
          total: response.data.total,
          totalPages: response.data.total_pages,
          DataisLoaded: true,
        });
        console.log('outlet ', response.data);


      });
  }

  filterfetch(e) {

    console.log(e)

  }

  render() {


    const { DataisLoaded, items, total } = this.state;
    const a = this.state.items

    const b = this.state.total

    const links = (this.state.restaurent);
    const outlets = (this.state.restaurent_outlet);
    const user = localStorage.getItem('user')
    const user_data = JSON.parse(user)
    this.restro_id = user_data.restro_id
    // console.log(outlets)

    // const b = JSON.stringify(a)
    const dates = new Date();
    const month = JSON.stringify(dates.getMonth() + 1)
    const date = JSON.stringify(dates.getDate())
    const year = JSON.stringify(dates.getFullYear())
    const recent_date = month + '-' + date


    console.log(a, "items")
    const ExpandedComponent = (suppData) => ({ data }) => {
      // const a = JSON.parse(data, null, 2)

      const myArray = JSON.parse(data.event_detail);
      console.log(myArray, 'myArray')
      return (
        <>

          <div className='row px-5'>

            <div className="col-md-8">
              <mat-card >
                <table>
                  <tr>
                    <th className='w-50'>Name :</th>
                    <td>{data.full_name}</td>
                  </tr>
                  <tr>
                    <th>Email :</th>
                    <td>{data.email}</td>
                  </tr>
                  <tr>
                    <th>Mobile : </th>
                    <td>{data.mobile}</td>
                  </tr>
                  <tr>
                    <th>Event Date :</th>
                    <td>{moment(new Date(data.event_date)).format("DD/MM/YYYY")}</td>
                  </tr>

                  <tr>
                    <th>Address : </th>
                    <td>{data.address}</td>
                  </tr>
                  <tr>
                    <th> Transaction ID : </th>
                    <td>{data.transcation_id}</td>
                  </tr>


                </table>
              </mat-card>
            </div>

            <div className="col-md-4">
              <mat-card>
                <table>
                  <tr>
                    <th> Couple : </th>
                    <td>{myArray.couple}</td>
                  </tr>
                  <tr>
                    <th> Male : </th>
                    <td>{myArray.male_stag}</td>
                  </tr>
                  <tr>
                    <th> Female : </th>
                    <td>{myArray.male_stag}</td>
                  </tr>
                  <tr>
                    <th> Other : </th>
                    <td>{myArray.others_stag}</td>
                  </tr>
                  <tr>
                    <th> Price : </th>
                    <td>{data.price}</td>
                  </tr>

                  <tr>
                    <th> Order ID : </th>
                    <td>{data.razorpay_order_id}</td>
                  </tr>
                </table>
              </mat-card>
            </div>
          </div>
          {suppData}{" "}
        </>
      );
    };
    const columns = [
      {

        selector: (row) => row.full_name,
        cellExport: (row) => row.full_name,
        sortable: true,
      },
      {

        selector: row => row.event_name,
        cellExport: (row) => row.event_name,
        sortable: true,

      },
      {

        selector: (row) => row.phone_no,
        cellExport: (row) => row.phone_no,
        sortable: true,

      },
      {

        selector: row => row.event_date === 'Event Date' ? "EVENT DATE" : moment(new Date(row.event_date)).format("DD/MM/YYYY"),
        cellExport: (row) => row.event_date,
        sortable: true,

      },
      {

        selector: (row) => row.event_detail,
        cellExport: (row) => row.event_detail,
        sortable: true,
        omit: true
      },
      {

        selector: (row) => row.restro_name,
        cellExport: (row) => row.restro_name,
        sortable: true,

      },
      {
        selector: (row) => row.address,
        cellExport: (row) => row.address,
        sortable: true,
        omit: true
      },
      {
        selector: (row) => row.price,
        cellExport: (row) => row.price,
        sortable: true,
        omit: true
      },
      {
        selector: (row) => row.transcation_id,
        cellExport: (row) => row.transcation_id,
        sortable: true,
        omit: true
      },
      {
        selector: (row) => row.razorpay_order_id,
        cellExport: (row) => row.razorpay_order_id,
        sortable: true,
        omit: true
      }

    ];

    const onSubmit = async e => {
      this.filterfetch(e)
      // console.log(e)


    }
    const bFilter = async () => {
      // console.log(this.start_date);
      var end_date = this.end_date
      var start_date = this.start_date
      if (this.rest === undefined) {
        var rest = ''
      }
      else {
        var rest = this.rest
      }

      console.log('working_rest', rest)
      var type = this.type


      let a = localStorage.getItem('user')
      let b = JSON.parse(a)
      let user_id = b.id
      let restro_id = b.restro_id

      axios
        .post(endpoints.eventFilter, {
          start_date,
          end_date,
          rest,
          type,
          user_id,
          restro_id

        })
        .then((response) => {
          // window.location.reload(true);
          console.log("response", response);
          this.setState({
            items: response.data.data,
            // total: response.data.total,
            // totalPages:response.data.total_pages,
            DataisLoaded: true,
          });
          console.log(response.data);
        });

    }
    const onStartChange = e => {
      this.start_date = e.target.value

    }
    const onEndChange = e => {
      this.end_date = e.target.value

    }
    const onTypeChange = e => {
      this.type = e.target.value

    }

    const onRestChange = e => {
      this.rest = e.target.value

    }
    const c = [
      {
        event_date: "Event Date",
        event_name: "Event Name",
        event_detail: "Event Detail",
        full_name: "Name",
        phone_no: "Number",
        address: "Address",
        restro_name: 'Restaurent',
        price: "Price",
        transcation_id: "Transaction Id",
        razorpay_order_id: 'Order Id'
      }
    ];
    // const data = heading.concat(c);
    const data = c.concat(a);
    const tableData = {

      columns,
      data,
    };


    if (!DataisLoaded)
      return (
        <div>
          <h4> Pleses wait some time.... </h4>{" "}
        </div>
      );

    return (
      <>
        <h2>Event Leads</h2>
        <Card className="px-3 py-3">
          <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
            <div className='row'>
              <div className='col-md-3'>



                {/* <Input id="start_date" label="Start Date" type="date" name="start_date" place="Start Date" max="2999-12-31"  onChange={(e) => onStartChange(e)}
                        requiredInput="required"  onKeyDown={(e) => e.preventDefault()}
                    /> */}
                <label>Start Date</label>
                <br />
                <TextField
                  fullWidth
                  id="start_date"
                  label="Start Date"
                  type="date"
                  name="start_date"
                  place="Start Date"
                  onChange={(e) => onStartChange(e)}
                  InputLabelProps={{
                    shrink: true,

                  }}
                  onKeyDown={(e) => e.preventDefault()}
                // InputProps={{
                //   readOnly: true,
                //  }}
                />
              </div>

              <div className='col-md-3'>

                {/* <Input id="end_date" label="End Date" type="date" name="end_date" place="Start Date" onChange={(e) => onEndChange(e)}
                        requiredInput="required" className='col-md-6'
                    /> */}
                <label>End Date</label>
                <br />
                <TextField
                  fullWidth
                  id="end_date"
                  label="End Date"
                  type="date"
                  name="end_date"
                  place="End Date"
                  onChange={(e) => onEndChange(e)}
                  InputLabelProps={{
                    shrink: true,

                  }}
                  onKeyDown={(e) => e.preventDefault()}
                // InputProps={{
                //   readOnly: true,
                //  }}
                />

              </div>
             
              <div className='col-md-3'>
                <label>Filter By</label>
                <select name="status" required className="form-control" onChange={e => onTypeChange(e)} >
                  <option value="">Select</option>
                  <option value="1">By Event Date</option>
                  <option value="2">By Created Date</option>
                </select>
              </div>

              {this.restro_id === 0 ? <div className="col-md-3">
                <label>Restaurant</label>
                <select name="rest" required className="form-control" onChange={e => onRestChange(e)}>
                  <option value="">Select Restaurant</option>
                  {links.map((e, key) => {
                    return <option key={key} value={e.id}>{e.name}</option>;
                  })}
                </select>
              </div> : ''}
              <div className="col col-3">
                <div className="mt-5 text-center">
                  <Button className='w-50' onClick={() => bFilter()} variant="contained" size="medium">
                    Filter
                  </Button>
                </div>
              </div>
            </div>


          </form>
        </Card>
        <div className='row mt-5'>


          {/* <MDBDataTable striped bordered hover className='col-md-12' data={basicData} /> */}
          <DataTableExtensions
            {...tableData}
          >
            <DataTable
              defaultSortField="id"
              pagination
              paginationPerPage={7}
              expandableRows
              paginationRowsPerPageOptions={[7, 14, 21, 28, 35]}
              expandableRowsComponent={ExpandedComponent()}
            />

          </DataTableExtensions>

        </div>
      </>
    );
  }
}
export default withRouter(EventLeads);
