import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import { Button, Card } from '@mui/material';
import FileUploader from "../../../FileUploader";

import { endpoints } from "../../../../services/apiurl";
import Input from "../../../UI/Input/Input";
import PageTitle from "../../../UI/PageTitle";
import DeleteIcon from '@mui/icons-material/Delete';


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import TextAreaInput from "../../../UI/Input/TextAreaInput";

const EditRestSection = () => {
    let history = useHistory();
    let { id } = useParams();

    const [cat, setCat] = useState({
        name: '',
        status: '',
        icon: '',
        food_note: '',
        iconName: '',
        type: '',
        upload_type: '',
        video_link: '',
    });


    const [showapp, setShowapp] = useState(0);
    const [showweb, setShowweb] = useState(0);


    const [showEdit, setshowEdit] = useState(true);
    const [show_contact_form, setShow_contact_formb] = useState(0);

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    const updateTextDescription = async (state) => {
        setEditorState(state);
    };
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setloading] = useState(false);
    const [linkFile, setlinkFile] = useState(null);


    const { name, status, icon, iconName, food_note, type, upload_type, video_link } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    const setContact = async (e) => {
        if (e.target.checked) {
            var status = 1
        }
        else {
            var status = 0
        }
        setShow_contact_formb(status);
    }

    useEffect(() => {
        editcategory();
    }, []);

    const editcategory = async () => {
        await axios.post(endpoints.editSections, { id: id }).then((response) => {
            setCat({
                id: response.data.data.id,
                name: response.data.data.name,
                status: response.data.data.status,
                icon: response.data.data.icon,
                iconName: response.data.data.iconName,
                food_note: response.data.data.food_note,
                type: response.data.data.type,
                upload_type: response.data.data.upload_type,
                video_link: response.data.data.video_link,

            });

            const html = response.data.data.food_note;
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                // const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(html)));
                setEditorState(editorState);
            }
            setShowapp(response.data.data.show_app);
            setShowweb(response.data.data.show_web);
            setShow_contact_formb(response.data.data.show_contact_form);


        });
    }

    const removeImageHandler = async (id) => {
        await axios.post(endpoints.deleteSectionImage, { id }).then((response) => {
        });
        window.location.reload();
    };


    const setStatus = async (e, type) => {
        if (e.target.checked) {
            var status = 1;
        }
        else {
            var status = 0;
        }
        if (type == 'app') {
            setShowapp(status);
        } else {
            setShowweb(status);
        }
    }

    const showEditD = async () => {
        if (showEdit) {
            setshowEdit(false);
        } else {
            setshowEdit(true);
        }
    }

    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", name);
        formData.append("icon", selectedFile);
        formData.append("status", status);
        formData.append("id", id);
        formData.append("food_note", food_note);
        formData.append("type", type);
        formData.append("upload_type", upload_type);

        if (upload_type == 1) {
            formData.append("video_link", video_link);
        } else {
            formData.append("video_link", linkFile);
        }
        // formData.append("food_note", draftToHtml(convertToRaw(editorState.getCurrentContent())));
        formData.append("show_web", showweb);
        formData.append("show_app", showapp);
        formData.append("show_contact_form", show_contact_form);
        console.log('ca', formData);
        await axios.post(endpoints.updateSection, formData).then((response) => {
            setloading(false);
            history.goBack();
            // if (response.data.status == 200) {
            //     // toast.success(response.data.message);
            //     history.goBack();
            // } else {
            //     // toast.error(response.data.message);
            // }
        });
    }

    return (
        <div className="ui main">
            <PageTitle title="Edit Main Category" linkurl="" linkTitle="" />
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <div className="row">
                        <Input id="name" label="Name" type="text" name="name" place="Name" onChange={(e) => onInputChange(e)}
                            requiredInput="true" value={name} class="col col-6"
                        />

                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" className="form-control" onChange={e => onInputChange(e)} value={status} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-12">
                            <div className="mt-3">
                                <Button variant="outlined" color="error" onClick={() => showEditD()}>
                                    {showEdit ? 'Html Editor' : 'Source'}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {showEdit ?
                            <TextAreaInput id="food_note" label="Food Note & Desc" name="food_note" place="Food Note & Desc" onChange={(e) => onInputChange(e)}
                                requiredInput="required" value={food_note} class="col col-6" />
                            :
                            <div className="col col-6">

                                <label>Food Note & Desc</label>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={updateTextDescription}
                                />
                            </div>

                        }
                        <div className="col col-6">
                            <label>Icon</label>
                            <FileUploader
                                onFileSelectSuccess={(file) => setSelectedFile(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />

                            {iconName ? (
                                <div className="mt-3">
                                    <img src={icon} height="200px" width="200px" className="px-2" />
                                    <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id)}>
                                        Delete
                                    </Button>
                                </div>
                            ) : ''}
                        </div>

                    </div>



                    <div className="row">
                        <div className="col col-6">
                            <label>Type</label>
                            <select name="type" value={type} required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Type</option>
                                <option value="1">Text</option>
                                <option value="2">PDF</option>
                            </select>
                        </div>
                        {
                            type == 2 &&
                            <>
                                <div className="col col-6">
                                    <label>Upload Type</label>
                                    <select name="upload_type" value={upload_type} required className="form-control" onChange={e => onInputChange(e)} >
                                        <option value="">Select Type</option>
                                        <option value="1">Link</option>
                                        <option value="2">Upload</option>
                                    </select>
                                </div>
                                {
                                    upload_type == 2 ?
                                        <div className="col col-6">
                                            <label>Pdf Upload</label>
                                            <FileUploader
                                                onFileSelectSuccess={(file) => setlinkFile(file)}
                                                onFileSelectError={({ error }) => alert(error)}
                                            />
                                        </div>
                                        : upload_type != '' &&
                                        <TextAreaInput id="video_link" label="Video Link" name="video_link" place="Video Link" onChange={(e) => onInputChange(e)}
                                            requiredInput="required" value={video_link} class="col col-6" />

                                }

                                {video_link ? (
                                    <div className="mt-3">
                                        <img src={video_link} height="200px" width="200px" className="px-2" />
                                        {/* <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id)}>
                                        Delete
                                    </Button> */}
                                    </div>
                                ) : ''}
                            </>
                        }


                    </div>

                    <div className="row">
                        {/* <div className="col col-6">
                            <label>
                                <input type="checkbox"
                                    checked={showapp == 1 ? true : false}
                                    onChange={(e) => setStatus(e, 'app')}
                                />
                                Status Show (App)
                            </label>

                        </div> */}

                        <div className="col col-6">
                            <label>
                                <input type="checkbox"
                                    checked={showweb == 1 ? true : false}
                                    onChange={(e) => setStatus(e, 'web')}
                                />
                                Status Show
                            </label>

                        </div>

                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <label>
                                <input type="checkbox"
                                    checked={show_contact_form == 1 ? true : false}
                                    onChange={(e) => setContact(e)}
                                />
                                Contact Form Show
                            </label>

                        </div>

                    </div>


                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                {/* <Button type="submit" variant="contained" size="large">
                                    Save
                                </Button> */}
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div>

                    </div>

                </form>


            </Card>

        </div>
    );

}

export default EditRestSection;
