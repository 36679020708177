import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import { Button, Card } from '@mui/material';
import FileUploader from "../../FileUploader";

import { endpoints } from "../../../services/apiurl";
import Input from "../../UI/Input/Input";
import PageTitle from "../../UI/PageTitle";
import DeleteIcon from '@mui/icons-material/Delete';


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import TextAreaInput from "../../UI/Input/TextAreaInput";


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

const EditEvents = () => {
    let history = useHistory();
    let { id } = useParams();

    const [cat, setCat] = useState({
        name: '',
        address: '',
        couple_price: '',
        image: '',
        iconName: '',
        description: '',
        status: '',
        couple_price: '',
        restro_id: '',
        female_stag_price: '',
        male_stag_price: '',
        other_stag_price: '',
        event_date: '',
    });


    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setloading] = useState(false);
    const [rest, setRest] = useState([]);

    const [eventDate, seteventDate] = useState(new Date());
    const { name, image, iconName, address, status, couple_price, restro_id, female_stag_price, male_stag_price, other_stag_price, event_date, description } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    useEffect(() => {
        editcategory();
        getRestaurentsDropDown();
    }, []);

    const getRestaurentsDropDown = async () => {
        await axios.post(endpoints.getRestaurentsDropDown).then((response) => {
            setRest(response.data.data);
        });
    };

    const editcategory = async () => {
        await axios.post(endpoints.editEvents, { id: id }).then((response) => {
            setCat({
                id: response.data.data.id,
                name: response.data.data.name,
                image: response.data.data.image,
                address: response.data.data.address,
                status: response.data.data.status,
                couple_price: response.data.data.couple_price,
                description: response.data.data.description,
                iconName: response.data.data.iconName,
                female_stag_price: response.data.data.female_stag_price,
                male_stag_price: response.data.data.male_stag_price,
                other_stag_price: response.data.data.other_stag_price,
                restro_id: response.data.data.restro_id,
            });
            seteventDate(new Date(response.data.data.event_date));

        });
    }

    const removeImageHandler = async (id) => {
        await axios.post(endpoints.deleteImageEvent, { id }).then((response) => {
        });
        window.location.reload();
    };

    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append("description", description);
        formData.append("name", name);
        formData.append("address", address);
        formData.append("couple_price", couple_price);
        formData.append("restro_id", restro_id);
        formData.append("female_stag_price", female_stag_price);
        formData.append("male_stag_price", male_stag_price);
        formData.append("other_stag_price", other_stag_price);

        formData.append("event_date", moment(eventDate).format("YY-MM-DD HH:mm:ss"));
        formData.append("image", selectedFile);
        formData.append("status", status);
        formData.append("id", id);
        await axios.post(endpoints.updateEvents, formData).then((response) => {
            setloading(false);
            history.goBack();
        });
    }

    return (
        <div className="ui main">
            <PageTitle title="Edit Event" linkurl="" linkTitle="" />
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <div className="row">
                        <div className="col col-6">
                            <label>Restaurents </label>
                            <select name="restro_id" required className="form-control" onChange={e => onInputChange(e)} value={restro_id}>
                                <option value="">Select Restaurents</option>
                                {rest.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                        </div>

                        <Input id="name" label="Name" type="text" name="name" place="Name" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={name} class="col col-6"
                        />
                        {/* <Input id="price" label="Price" type="text" name="price" place="Price" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={price} class="col col-6"
                        /> */}
                    </div>

                    <div className="row">
                        <Input id="couple_price" label="Couple Price" type="text" name="couple_price" place="Couple Price" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={couple_price} class="col col-6"
                        />

                        <Input id="female_stag_price" label="Female Stag Price" type="text" name="female_stag_price" place="Female Stag Price" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={female_stag_price} class="col col-6"
                        />
                    </div>

                    <div className="row">
                        <Input id="male_stag_price" label="Male Stag Price" type="text" name="male_stag_price" place="Male Stag Price" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={male_stag_price} class="col col-6"
                        />

                        <Input id="other_stag_price" label="Other Stag Price" type="text" name="other_stag_price" place="Other Stag Price" onChange={(e) => onInputChange(e)}
                            requiredInput="" value={other_stag_price} class="col col-6"
                        />
                    </div>


                    <div className="row">
                        <div className="col col-6">
                            <label>Event Date & Time</label>
                            <DatePicker
                                selected={eventDate}
                                onChange={(date) => seteventDate(date)}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeInput
                                className="form-control"
                            />
                        </div>

                    </div>

                    <div className="row">
                        <TextAreaInput id="address" label="Address" name="address" place="Address" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={address} class="col col-6" />

                        <TextAreaInput id="description" label="Description" name="description" place="Description" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={description} class="col col-6" />

                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <label>Image</label>
                            <FileUploader
                                onFileSelectSuccess={(file) => setSelectedFile(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />
                            {iconName ? (
                                <div className="mt-3">
                                    <img src={image} height="200px" width="200px" className="px-2" />
                                    <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => removeImageHandler(id)}>
                                        Delete
                                    </Button>
                                </div>
                            ) : ''}
                        </div>

                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" required className="form-control" onChange={e => onInputChange(e)} value={status} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>

                    </div>




                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                {/* <Button type="submit" variant="contained" size="large">
                                    Save
                                </Button> */}
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div>

                    </div>

                </form>


            </Card>

        </div>
    );

}

export default EditEvents;
