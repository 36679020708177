import axios from "axios";
import { endpoints } from "../services/apiurl";

const register = (username, email, password) => {
    return axios.post(endpoints.login, {
        username,
        email,
        password,
    });
};

const login = async (username, password) => {
    return await axios
        .post(endpoints.login, {
            username,
            password,
        })
        .then((response) => {
            if (response.data.status) {
                if (response.data.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data.data));
                }
            }
            return response.data;
        }).catch((error) => {
            return error;
        });

};

const logout = () => {
    localStorage.removeItem("user");
};
export default {
    register,
    login,
    logout,
};