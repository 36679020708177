import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import { Button, Card } from '@mui/material';


import { endpoints } from "../../services/apiurl";
import Input from "../UI/Input/Input";
import PageTitle from "../UI/PageTitle";
import DeleteIcon from '@mui/icons-material/Delete';


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const EditOutlet = () => {
    let history = useHistory();
    let { id } = useParams();

    const [outlet, setOutlet] = useState({
        outlet_name: '',
        restro_id: '',

    });
    const [loading, setloading] = useState(false);
    const [rest, setRest] = useState([]);

    const { outlet_name, restro_id } = outlet;
    const onInputChange = e => {
        setOutlet({ ...outlet, [e.target.name]: e.target.value })
        console.log(outlet);
    }

    useEffect(() => {
        editoutlets();
        getRestaurentsDropDown();

    }, []);


    const getRestaurentsDropDown = async () => {
        await axios.post(endpoints.getRestaurentsDropDown).then((response) => {
            setRest(response.data.data);
        });
    };

    const editoutlets = async () => {
        let a  = localStorage.getItem('user')
        let b = JSON.parse(a)
        let user_id = b.id
        let restro_id = b.restro_id
        await axios.post(endpoints.editOutlet, { id: id ,user_id:user_id,restro_id:restro_id}).then((response) => {
            setOutlet({
                id: response.data.data.id,
                outlet_name: response.data.data.outlet_name,
                restro_id: response.data.data.restro_id,
               
            });
        });
    }


    const onSubmit = async e => {
        setloading(true);
        e.preventDefault();
        // if (media_type == 1) {
        //     if (!selectedFile) {
        //         alert('Please select image');
        //         return false;
        //     }
        // }

        const formData = new FormData();
       
        formData.append("restro_id", restro_id);
        formData.append("outlet_name", outlet_name);
        formData.append("id", id);
        await axios.post(endpoints.updateOutlet, formData).then((response) => {
            setloading(false);
            history.goBack();
        });
    }

    return (
        <div className="ui main">
            <PageTitle title="Edit Outlet" linkurl="" linkTitle="" />
            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                <Input id="outlet_name" label="Name" type="text" name="outlet_name" place="Name" onChange={(e) => onInputChange(e)}
                        requiredInput="true" value={outlet_name}
                    />
                    <div className="row">
                        <div className="col col-6">
                            <label>Restaurents </label>
                            <select name="restro_id" required className="form-control" onChange={e => onInputChange(e)} value={restro_id}>
                                <option value="">Select Restaurents</option>
                                {rest.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })}
                            </select>
                        </div>
                       

                    </div>




                  

                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                {/* <Button type="submit" variant="contained" size="large">
                                    Save
                                </Button> */}
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div>

                    </div>

                </form>


            </Card>

        </div>
    );

}

export default EditOutlet;
