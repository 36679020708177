import React, { useState, useEffect, Component } from "react";

import DataTable from "react-data-table-component";
import axios from "axios";
import { withRouter } from "react-router";

import { Redirect, Switch, Route, Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { endpoints } from "../../../../services/apiurl";

import LinkButton from "../../../UI/Input/LinkButton";
import { Card, Button } from "@mui/material";
// import Input from "../../UI/Input/Input";
import Input from "../../../UI/Input/Input";


import PageTitle from "../../../UI/PageTitle";

import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from "@mui/icons-material/Edit";

import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';


// const RestCategoryDetail = () => {
//     let history = useHistory();
//     let { restro_id } = useParams();
//     const [error, setError] = useState(null);
//     const [isLoaded, setIsLoaded] = useState(false);
//     const [items, setItems] = useState([]);
//     const [totalRows, setTotalRows] = useState(0);
//     const [perPage, setPerPage] = useState(10);

//     useEffect(() => {
//         fetchData(1, perPage);
//     }, [perPage])

//     const columns = [

//         {
//             name: 'NAME',
//             selector: row => row.name,
//             // width: '200px'
//         }, {
//             name: 'STATUS',
//             selector: row => row.status == 1 ? 'Active' : 'Inactive',
//             // width: '200px'
//         }, {
//             name: 'ACTION',
//             cell: row => <div>  <Link to={`/dashboard/editrestcategory/${row.id}`}> <EditIcon /> </Link>   <Link to={`/dashboard/subcategoryrest/${row.id}`}>  <Button variant="contained">
//                 Sub Category
//             </Button> </Link>   <Link to={`/dashboard/dishrestdetail/${row.id}`}>  <Button variant="contained">
//                 Dish
//             </Button> </Link>   </div>,
//             selector: row => row.id,
//             // width: '200px'
//         },
//     ];//<Link className="trash alternate outline icon" style={{ color: "red", marginTop: "7px", marginLeft: "10px" }} onClick={() => removeContactHandler(row.id)}> <DeleteIcon /> </Link>

//     const removeContactHandler = async (id) => {
//         await axios.post(endpoints.deleteCategory, { id }).then((response) => {
//         });
//         // window.location.reload();
//         const newContactList = items.filter((contact) => {
//             return contact.id !== id;
//         });
//         setItems(newContactList);
//     };

//     const fetchData = async (page, per_page) => {
//         // console.log(restro_id)
//         await axios
//             .post(endpoints.getCategory, {
//                 // .get(`https://www.mecallapi.com/api/attractions?page=${page}&per_page=${per_page}`, {
//                 restro_id,
//                 page,
//                 per_page,

//             })
//             .then((response) => {
//                 console.log('response', response);
//                 setIsLoaded(true);
//                 setItems(response.data.data);
//                 setTotalRows(response.data.total);
//             }
//                 ,
//                 (error) => {
//                     setIsLoaded(true);
//                     setError(error);
//                 }

//             );

//     }

//     const handlePageChange = page => {
//         fetchData(page, perPage);
//     }

//     const handlePerRowsChange = async (newPerPage, page) => {
//         setPerPage(newPerPage);
//     }

//     if (error) {
// //         return <div>Error: {error.message}</div>;
//     } else if (!isLoaded) {
//         return <div>Loading...</div>;
//     } else {
//         return (

//             <div className="App">
//                 <PageTitle title="Category List" linkid={restro_id} linkurl="/dashboard/addrestcategory" linkTitle="Add Category" />
//                 <Card className="px-3 py-3">
//                     <DataTable
//                         columns={columns}
//                         data={items}
//                         pagination
//                         paginationServer
//                         paginationTotalRows={totalRows}
//                         onChangePage={handlePageChange}
//                         onChangeRowsPerPage={handlePerRowsChange}
//                     />
//                 </Card>
//             </div>

//         );
//     }
// }

const getItems = (ii) =>
    Array.from({ length: ii.length }, (v, k) => k).map((k) => ({
        id: `${ii[k].id}`,
        name: ii[k].name,
        status: `${ii[k].status}`,
        // last: ii[k].id,
        content: `item ${k}`,
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

console.log(localStorage.getItem("user"));

class RestCategoryDetail extends Component {
    // Constructor
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            DataisLoaded: false,
            number: 1,
            name:'',
            currentPage: 1,
            totalPages: 1,
            per_page: 10,
            restro_id: this.props.match.params.restro_id
        };

        this.section_id = this.props.match.params.restro_id;

        console.log(this.section_id);
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        this.fetchData();
    }


    fetchData() {
        var restro_id = this.state.restro_id;
        var page = this.state.currentPage;
        var per_page = this.state.per_page;
        axios
            .post(endpoints.getCategory, {
                page,
                per_page,
                restro_id,
            })
            .then((response) => {
                this.setState({
                    items: getItems(response.data.data),
                    totalPages: response.data.total_pages,
                    local: localStorage.setItem(
                        "data",
                        JSON.stringify(response.data.data)
                    ),
                    DataisLoaded: true,
                });
                // console.log(response.data.data);
            });
    }
  

    onDragEnd(result) {
        let aa = localStorage.getItem("data");
        let bb = JSON.parse(aa);
        let destination = bb[result.destination.index].id;
        let source = result.draggableId;
        let to = result.destination.index;
        let from = result.source.index;
        let section_id = result.destination.droppableId;


        let a = localStorage.getItem("user");
        let b = JSON.parse(a);

        axios
            .post(endpoints.catupdateSorting, {
                source,
                destination,
                to,
                from,
                section_id,
            })
            .then((response) => {
                window.location.reload(true);

                // console.log("response", response);
                this.setState({
                    items: getItems(response.data.data),

                    DataisLoaded: true,
                });
            });

        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );
        // console.log(getItems(10))

        this.setState({
            items,
        });
    }

    render() {
        const sub = async (id, old_status) => {
            if (old_status === '1') {
                var status = 0
            }
            else {
                var status = 1
            }
            axios
                .post(endpoints.updateCatStatus, {
                    id,
                    status
                })
                .then((response) => {
                    window.location.reload(true);
                    this.setState({
                        DataisLoaded: true,
                    });
                });
        }
        const onSubmit = async e => {
            // this.filterfetch(e)
            console.log(e)
      
           
        }

        const onNameChange = e => {
            this.name = e.target.value
           
          }
       
        const bFilter = async ()=>{
            console.log(this.name);
            var name = this.name
            var section_id = this.section_id
            // var restro_id = this.state.restro_id;
            var page = this.state.currentPage;
            var per_page = this.state.per_page;
            // if(this.rest===undefined){
            //   var rest = ''
            // }
            // else{
            //   var rest = this.rest
            // }
            
            // console.log('working_rest',rest)
            // var type = this.type
        
        
            // let a  = localStorage.getItem('user')
            // let b = JSON.parse(a)
            // let user_id = b.id
            // let restro_id = b.restro_id
        
            axios
              .post(endpoints.categoryFilter, {
                page,
                per_page,
               name , section_id   
              })
              .then((response) => {
                // window.location.reload(true);
                this.setState({
                    items: getItems(response.data.data),
                    totalPages: response.data.total_pages,
                    local: localStorage.setItem(
                        "data",
                        JSON.stringify(response.data.data)
                    ),
                    DataisLoaded: true,
                });
                console.log(response.data);
              });
        
          }
        const setCurrentPage = (id) => {
            this.state.currentPage = id;
            this.fetchData();
        }

        const onSelectChange = (per_page) => {
            this.state.per_page = per_page;
            this.fetchData();
        }

        return (
            <div className="App">
                <div className="col col-6">
                    <label>Row Per Page </label>
                    <select
                        name="type"
                        className="form-control"
                        onChange={(e) => onSelectChange(e.target.value)}
                        value={this.state.per_page}
                    >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>

                <PageTitle
                    title="Category List"
                    linkid={this.props.match.params.restro_id}
                    linkurl="/dashboard/addrestcategory"
                    linkTitle="Add Category"
                />
                 <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                  <div className='row'>
                 
                  <div className='col-md-3'>

                    <Input id="name" label="Name" type="text" name="name" place="Category Name"
                        requiredInput="required" className='col-md-6' onChange={(e) => onNameChange(e)}
                    />

                    
                    </div>
                  
                        <div className="col col-3">
                            <div className="mt-5 text-center">
                                <Button className='w-50' onClick={()=>bFilter()} variant="contained" size="medium">
                                    Filter
                                </Button>
                            </div></div></div>


                </form>
                 </Card>
                <Card className="m-3">
                    {/* <PageTitle title="Restaurants List" linkid={restro_id} linkurl="/dashboard/addrestaurents" linkTitle="Add Restaurants" /> */}
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId={this.props.match.params.restro_id}>
                            {(provided) => (
                                <table
                                    {...provided.droppableProps}
                                    className="table "
                                    ref={provided.innerRef}
                                >
                                    <tbody>
                                        <tr className="text-center">
                                            <th></th>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                        {this.state.items.map((item, index) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <tr
                                                        className="text-center"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <td> <DragIndicatorIcon /> </td>
                                                        <td>{item.name}</td>
                                                        <td>

                                                            <div class="custom-control custom-switch">
                                                                <input type="checkbox" class="custom-control-input" id={item.id + 'check'}
                                                                    {...(item.status === '1' ? { checked: 'checked' } : {})}


                                                                    onClick={() => sub(item.id, item.status)} />
                                                                <label class="custom-control-label" for={item.id + 'check'} ></label>
                                                            </div>

                                                        </td>

                                                        <td className="text-center">
                                                            <Link
                                                                to={`/dashboard/editrestcategory/${item.id}`}
                                                            >
                                                                {" "}
                                                                <EditIcon />{" "}
                                                            </Link>
                                                            <Link
                                                                to={`/dashboard/subcategoryrest/${item.id}`}
                                                            >
                                                                <Button variant="contained">
                                                                    Sub Category{" "}
                                                                </Button>{" "}
                                                            </Link>
                                                            <Link to={`/dashboard/dishrestdetail/${item.id}`}>
                                                                <Button variant="contained">Dish</Button>{" "}
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </tbody>
                                </table>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Card>


                <Pagination
                    {...bootstrap5PaginationPreset}
                    current={this.state.currentPage}
                    total={this.state.totalPages}
                    onPageChange={(e) => setCurrentPage(e)}
                />

            </div>
        );
    }
}

export default withRouter(RestCategoryDetail);
