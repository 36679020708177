import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { Button, Card } from '@mui/material';
import FileUploader from "../../FileUploader";

import { endpoints } from "../../../services/apiurl";

import Input from "../../UI/Input/Input";
import PageTitle from "../../UI/PageTitle";


import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import TextAreaInput from "../../UI/Input/TextAreaInput";

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';

const AddPoint = () => {
    let history = useHistory();
    const [cat, setCat] = useState({
        name: '',
        status: '',
        description: '',
        // price_range_id: '',
        point: ''
    });

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [selectedFileImage, setSelectedFileImage] = useState(null);

    const updateTextDescription = async (state) => {
        setEditorState(state);
        //  const data = convertToRaw(state.getCurrentContent());
    };

    const [loading, setloading] = useState(false);
    const [rest, setRest] = useState([]);


    const { name, description, status, point } = cat;
    const onInputChange = e => {
        setCat({ ...cat, [e.target.name]: e.target.value })
        console.log(cat);
    }

    useEffect(() => {
        getPriceRangeDropDown();
        // const html = '<p>Hey this <strong>editor</strong> rocks 😀</p>';
        // const contentBlock = htmlToDraft(html);
        // if (contentBlock) {
        //     const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        //     const editorState = EditorState.createWithContent(contentState);
        //     setEditorState(editorState);
        // }
    }, []);


    const getPriceRangeDropDown = async () => {
        await axios.post(endpoints.getPriceRangeDropDown).then((response) => {
            setRest(response.data.data);
        });
    };

    const onSubmit = async e => {
        // e.preventDefault();
        // console.log('editorState', draftToHtml(convertToRaw(editorState.getCurrentContent())));
        if (!selectedFileImage) {
            alert('Please select image');
            return false;
        }
        setloading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", name);
        // formData.append("description", description);
        formData.append("description", draftToHtml(convertToRaw(editorState.getCurrentContent())));
        formData.append("point", point);
        formData.append("status", status);
        formData.append("image", selectedFileImage);

        await axios.post(endpoints.addPoint, formData).then((response) => {
            setloading(false);
            history.goBack();
        });
    }
    return (
        <div className="ui main">
            <PageTitle title="Add Card" linkurl="" linkTitle="" />

            <Card className="px-3 py-3">
                <form className="ui form" onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                    <div className="row">
                        {/* <div className="col col-6">
                            <label>Price Range </label>
                            <select name="price_range_id" required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Price Range</option>
                                {rest.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.start_price} - {e.end_price}</option>;
                                })}
                            </select>
                        </div> */}

                        <Input id="name" label="Name" type="text" name="name" place="Name" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={name} class="col col-6"
                        />

                        <Input id="point" label="Loyality Point" type="text" name="point" place="Loyality Point" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={point} class="col col-6"
                        />



                    </div>


                    <div className="row">
                    <div className="col col-6">
                            <label>Image </label>
                            <FileUploader
                                onFileSelectSuccess={(file) => setSelectedFileImage(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />
                        </div>
                        {/* <TextAreaInput id="description" label="Description" name="description" place="Description" onChange={(e) => onInputChange(e)}
                            requiredInput="required" value={description} class="col col-6" /> */}
                       
                        <div className="col col-6">
                            <label>Status</label>
                            <select name="status" required className="form-control" onChange={e => onInputChange(e)} >
                                <option value="">Select Status</option>
                                <option value="2">InActive</option>
                                <option value="1">Active</option>
                            </select>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <label>Description</label>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={updateTextDescription}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-6">
                            <div className="mt-3">
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    size="large"
                                >
                                    Save
                                </LoadingButton>
                            </div></div></div>


                </form>
            </Card>
        </div>
    );

}

export default AddPoint;


